import styled from "styled-components";

const Wrapper = styled.div``;
export const AdminWrapper = styled.div`
  background: ${(props) => props.theme.colors.theme};
  margin-right: 10px;
  margin-left: 11px;
  .card-title {
  }
  .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
    border-bottom-color: #929292 !important;
  }
  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
    background: #1668dc !important;
  }
  .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #ffffff !important;
  }
  border-radius: 0.65rem 0.65rem 0px 0px;
  .label-admin {
    background: ${(props) => props.theme.colors.theme};
    padding: 8px;
    border-radius: 0.65rem 0.65rem 0 0;
    color: #61bbed;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
    cursor: pointer;
    transition: 0.6s;
    display: flex;
    align-items: center;
    .setting-admin {
      margin-right: 7px;
      path {
        fill: #61bbed !important;
      }
    }
    &:hover {
      color: #61bbed8f;
      path {
        fill: #61bbed8f !important;
      }
    }

    .title {
      position: relative;
      top: 2px;
    }
  }
  @keyframes expandHeight {
    from {
      height: 0;
    }
    to {
      height: calc(100vh - 20px);
    }
  }
  @keyframes adminHide {
    from {
      display: block;
      height: calc(100vh - 20px);
    }
    to {
      display: none;
      height: 0;
    }
  }

  .admin-data-view {
    height: calc(100vh - 20px);
    animation: expandHeight 0.8s ease-in-out;
    background: ${(props) => props.theme.colors.theme};
    padding: 10px;
    color: #fff;
  }
  .admin-data-hide {
    height: 0;
    animation: adminHide 0.8s ease-in-out;
  }
  .profile-card-admin {
    padding: 10px;
    max-width: 490px;
    height: fit-content !important;
    .card-title {
      h3 {
        color: ${(props) => props.theme.colors.tooltipText};
      }
    }
    .col-form-label {
      color: ${(props) => props.theme.colors.tooltipText} !important;
    }
    .image-input.image-input-outline .image-input-wrapper {
      border: 3px solid ${(props) => props.theme.colors.tooltipText};
    }
  }
  .border-top {
    border-top: 1px solid #eff2f524 !important;
  }
  .border-bottom {
    border-bottom: 1px solid #eff2f524 !important;
  }
  .admin-menus {
  }
  .row {
    height: 100%;
  }
  .modules-admin {
    height: calc(100vh - 40px);
    overflow: auto;
    padding: 10px;
  }
`;

export default Wrapper;

import { Skeleton } from "antd";
import { SalesBreakdownWrapper } from "../style";
import SalesBreakdown from "./SalesBreakdown";

function ChartView(
  revenueFeesData,
  sign,

  loading_,

  salesBreakData,

  periodData
) {
  return (
    <SalesBreakdownWrapper
      className="card gridScroll mb-2"
      style={{ overflow: "auto", height: "926px" }}
    >
      {" "}
      <div className="card-header">
        <div className="w-100 pt-8">
          {loading_ ? (
            <Skeleton.Input active={true} />
          ) : (
            <div className="row">
              <div className="col-3">
                <h3 className="fw-bold text-start text-dark">
                  Sales Breakdown
                </h3>
              </div>
              <div className="col-9">
                <div className="row ">
                  <div className="col text-end">
                    <b>Date</b>
                  </div>

                  <div className="col text-end">
                    <b>Comparison Date</b>
                  </div>
                  <div className="col text-end">
                    <b>%</b>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {loading_ &&
        Array(11)
          .fill("1")
          .map((_, i) => (
            <>
              <div key={i} className="d-flex justify-content-around my-3">
                <Skeleton.Input active />
                <Skeleton.Button active />
              </div>
              {i + 1 !== 11 && (
                <div className="separator separator-dashed my-2" />
              )}
            </>
          ))}
      {!loading_ && SalesBreakdown(sign, salesBreakData, periodData)}
    </SalesBreakdownWrapper>
  );
}
export default ChartView;

import styled from "styled-components";

export const Wrapper = styled.div`
  .bg-info {
    background-color: #fff8dd !important;
    div {
      color: #f6c000 !important;
    }
    .svg-icon-light {
      path,
      rect {
        fill: #f6c000 !important;
      }
    }
  }
  .bg-warning {
    background-color: #e9f3ff !important;

    div {
      color: #1b84ff !important;
    }
    .svg-icon-light {
      path,
      rect {
        fill: #1b84ff !important;
      }
    }
  }
`;

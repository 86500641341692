import axiosCall from "../../configurations/network-services/axiosCall";

export const StripePlanListAction = () => {
  const path = `stripe-plan-list`;
  const responseType = "STRIPE_PLAN_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionMySubscriptions = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_MY_SUBSCRIPTION", state: data });
};

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "MAPPING_DATA_LIST_SUCCESS":
    case "MAPPING_DATA_LIST_ERROR":
      return {
        ...state,
        MappingDataListResponse: action.updatePayload,
      };
    case "TAGS_LIST_SUCCESS":
    case "TAGS_LIST_ERROR":
      return {
        ...state,
        TagsDataListResponse: action.updatePayload,
      };
    case "MAPPING_PHASES_UPDATE_SUCCESS":
    case "MAPPING_PHASES_UPDATE_ERROR":
      return {
        ...state,
        MappingPhasesUpdateResponse: action.updatePayload,
      };
    case "UPDATE_PRODUCT_MAPPING_SUCCESS":
    case "UPDATE_PRODUCT_MAPPING_ERROR":
      return {
        ...state,
        UpdateProductMappingResponse: action.updatePayload,
      };
    case "IMPORT_MAPPING_SUCCESS":
    case "IMPORT_MAPPING_ERROR":
      return {
        ...state,
        ImportMappingResponse: action.updatePayload,
      };
    case "ADD_TAGS_SUCCESS":
    case "ADD_TAGS_ERROR":
      return {
        ...state,
        AddTagsResponse: action.updatePayload,
      };
    case "MAPPING_TAGS_SUCCESS":
    case "MAPPING_TAGS_ERROR":
      return {
        ...state,
        MappingTagsResponse: action.updatePayload,
      };
    case "MAPPING_COGS_SUCCESS":
    case "MAPPING_COGS_ERROR":
      return {
        ...state,
        MappingCogsResponse: action.updatePayload,
      };
    case "TAGS_DELETE_SUCCESS":
    case "TAGS_DELETE_ERROR":
      return {
        ...state,
        TagsDeleteResponse: action.updatePayload,
      };
    case "ASIN_HISTORY_LIST_SUCCESS":
    case "ASIN_HISTORY_LIST_ERROR":
      return {
        ...state,
        AsinHistoryListResponse: action.updatePayload,
      };
    case "FAKE_ACTION_MAPPING_DATA":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};

export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "FBA_MANAGE_INVENTORY_LIST_SUCCESS":
      case "FBA_MANAGE_INVENTORY_LIST_ERROR":
        return {
          ...state,
          FBAManageInventoryListResponse: action.updatePayload,
        };
      case "FAKE_ACTION_FBA_MANAGE_INVENTORY":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  
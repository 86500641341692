import {
  CardElement,
  useElements,
  useStripe,
  Elements,
} from "@stripe/react-stripe-js";
import { Col, Form, Input, Modal, Row } from "antd";
import React from "react";

const SripeModal = ({ show, onHide, selectedCard, onSubmit }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [form] = Form.useForm();
  const handleOk = async () => {
    try {
      const { token, error } = await stripe.createToken(
        elements.getElement(CardElement)
      );

      if (error) {
        console.error(error);
      } else {
        form.validateFields().then((values) => {
          // form.resetFields();
          const obj = {
            stripe_token: token.id || null,
            card_id: token?.card?.id || null,
            product_id: selectedCard?.stripe_product_id || null,
            price_id: selectedCard?.stripe_price_id || null,
            type: selectedCard?.type || null,
            plan_name: selectedCard?.plan_name || null,
          };
          onSubmit(obj);
        });
      }
    } catch (error) {
      console.error("Error creating token:", error);
    }
  };

  const cardElementOptions = {
    // Customize the appearance of the Card Element
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#9e2146",
      },
    },
    hidePostalCode: true, // Hide the postal code field
  };

  return (
    <Modal
      title="Purchase Plan"
      width={600}
      open={show}
      onOk={handleOk}
      onCancel={onHide}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email!" },
            { type: "email", message: "Please enter a valid email address!" },
          ]}
        >
          <Input size="large" />
        </Form.Item>

        <Form.Item
          label="User Name"
          name="userName"
          rules={[{ required: true, message: "Please enter your user name!" }]}
        >
          <Input size="large" />
        </Form.Item>

        <Row gutter={8}>
          <Col span={24}>
            <Form.Item label="Card Details" name="cardDetails">
              <div
                style={{
                  border: "1px solid #d9d9d9",
                  padding: "10px",
                  borderRadius: "4px",
                }}
              >
                <CardElement options={cardElementOptions} />
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default SripeModal;

import axiosCall from "../../configurations/network-services/axiosCall";

export const GetCategoryGoalsListAction = (data) => {
  const path = `category-goals?filter_year=${
    data?.filter_year || ""
  }&sales_channel=${data?.sales_channel || ""}&region=${data?.region || ""}`;
  const responseType = "GET_CATEGORY_GOALS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateCategoryGoalsAction = (data) => {
  const path = `mapping-goals`;
  const responseType = "UPDATE_CATEGORY_GOALS";
  return axiosCall("patch", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionFinanceData = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_GOALS", state: data });
};

import { Button, Checkbox, Modal, Tag, Tooltip, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../../components/icons";
import { TableLoading } from "../../../../components/table-animation";
import SpModal from "./sp-modal";
import { Wrapper } from "./style";
import { useLocation } from "react-router-dom";
import { APPID, urlDecode } from "../../../../config";
import { GlobalContext } from "../../../../commonContext";

const SPAPI = (props) => {
  const {
    GetMarketplaceParticipationAction,
    GenerateRefreshSPTokenAction,
    GetSPAPIListAction,
    StoreSPOtherMarketplaceAction,
    fakeActionMarketPlace,
  } = props;
  const contextValue = useContext(GlobalContext);
  const location = useLocation();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [sPModal, setSPModal] = useState(false);

  const [editSPView, setEditSPView] = useState(false);

  const [participationsList, setParticipationsList] = useState([]);
  const [selected, setSelected] = useState({});
  const [selectedOtherMarketplace, setSelectedOtherMarketplace] = useState([]);

  const GetSPAPIListResponse = useSelector(
    (state) => state.Setting.GetSPAPIListResponse
  );
  const GenerateRefreshSPTokenResponse = useSelector(
    (state) => state.Setting.GenerateRefreshSPTokenResponse
  );
  const StoreSPOtherMarket = useSelector(
    (state) => state.Setting.StoreSPOtherMarket
  );
  const GetMarketplaceParticipationResponse = useSelector(
    (state) => state.Setting.GetMarketplaceParticipationResponse
  );

  useEffect(() => {
    if (StoreSPOtherMarket?.status === true) {
      setLoading(true);
      setList([]);
      GetSPAPIListAction();
      message.destroy();
      message.success(StoreSPOtherMarket?.message);
      fakeActionMarketPlace("StoreSPOtherMarket");
    } else if (StoreSPOtherMarket?.status === false) {
      message.destroy();
      message.error(StoreSPOtherMarket?.message);
      fakeActionMarketPlace("StoreSPOtherMarket");
    }
  }, [StoreSPOtherMarket]);

  useEffect(() => {
    if (GetMarketplaceParticipationResponse?.status === true) {
      message.destroy();
      setParticipationsList(
        Object?.values(GetMarketplaceParticipationResponse?.data?.[0])?.[0] ||
          []
      );
      setEditSPView(true);
      fakeActionMarketPlace("GetMarketplaceParticipationResponse");
    } else if (GetMarketplaceParticipationResponse?.status === false) {
      message.destroy();
      message.error(GetMarketplaceParticipationResponse?.data);
      fakeActionMarketPlace("GetMarketplaceParticipationResponse");
    }
  }, [GetMarketplaceParticipationResponse]);

  useEffect(() => {
    if (GetSPAPIListResponse?.status === true) {
      if (GetSPAPIListResponse?.data?.records?.length > 0) {
        const user = {
          ...JSON.parse(localStorage.getItem("user")),
          sp_credential: 1,
        };
        contextValue?.updateCommonGlobalVal({
          user_: user,
        });
        localStorage.setItem("user", JSON.stringify(user));
      }
      setList(GetSPAPIListResponse?.data?.records || []);
      setLoading(false);
      fakeActionMarketPlace("GetSPAPIListResponse");
    } else if (GetSPAPIListResponse?.status === false) {
      setList([]);
      setLoading(false);
      message.destroy();
      message.error(GetSPAPIListResponse?.message || "-");
      fakeActionMarketPlace("GetSPAPIListResponse");
    }
  }, [GetSPAPIListResponse]);

  useEffect(() => {
    if (GenerateRefreshSPTokenResponse?.status === true) {
      fakeActionMarketPlace("GenerateRefreshSPTokenResponse");
      localStorage.removeItem("other_marketplace");
      window.location.assign("/callback_sp");
    } else if (GenerateRefreshSPTokenResponse?.status === false) {
      message.destroy();
      message.error(GenerateRefreshSPTokenResponse?.message || "-");
      fakeActionMarketPlace("GenerateRefreshSPTokenResponse");
      setTimeout(() => {
        window.location.assign("/callback_sp");
      }, 5000);
    }
  }, [GenerateRefreshSPTokenResponse]);

  useEffect(() => {
    if (location.pathname.includes("/callback_sp")) {
      if (location.search) {
        const { spapi_oauth_code, selling_partner_id, state } =
          urlDecode(location);

        const obj = {
          spapi_oauth_code: spapi_oauth_code,
          selling_partner_id: selling_partner_id,
          app_id: APPID,
          seller_name: state?.split("!!")?.[1] || "",
          marketplace_id: state?.split("!!")?.[3] || "",
          other_marketplace: localStorage.getItem("other_marketplace")
            ? JSON.parse(localStorage.getItem("other_marketplace"))?.join(",")
            : "",
        };

        message.destroy();
        message.loading("Loading...", 0);
        GenerateRefreshSPTokenAction(obj);
      }
    }
  }, [location]);

  useEffect(() => {
    setLoading(true);
    setList([]);
    GetSPAPIListAction();
    return () => {};
  }, []);

  return (
    <>
      <Wrapper className="px-3 mt-5">
        {" "}
        <div className="row gy-5 g-xl-5">
          <div className="col-xxl-12">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-bottom-dashed">
                <h3 className="card-title fw-bold text-dark">
                  Amazon SP API Credentials List
                </h3>
                <div className="card-toolbar">
                  <div className="card-toolbar">
                    <Button onClick={() => setSPModal(true)} type="primary">
                      Add
                    </Button>
                    {/* <span
                    type="button"
                    onClick={() => {
                      window.open(
                        `https://sellercentral.amazon.it/apps/authorize/consent?application_id=${APPID}&state=!!marketforce!!eu-west-1!!APJ6JRA9NG5V4!!&version=beta`
                      );
                    }}
                    className=" px-7"
                  >
                    <img
                      border="0"
                      alt="Login with Amazon"
                      src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
                      width="156"
                      height="32"
                    />
                  </span> */}
                  </div>
                </div>
              </div>
              <div className="card-body py-2">
                {loading ? (
                  <TableLoading
                    id="test-table"
                    columns={[1, 2, 3, 4, 5]}
                    checkBoxVal={true}
                    actions={[1, 2]}
                  />
                ) : (
                  <div className="table-responsive">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                      <thead>
                        <tr className="fw-bolder text-dark">
                          <th className="w-75px">#</th>
                          <th className="min-w-175px">Seller Account Name</th>
                          <th className="min-w-75px">ARN</th>
                          <th className="min-w-150px">Region</th>
                          <th className="min-w-150px">Credential Type</th>
                          <th className="min-w-100px">Marketplace</th>
                          <th className="min-w-150px">AWS Access Key</th>
                          <th className="min-w-150px">AWS Secret Key</th>
                          <th className="min-w-175px">LWA Client ID</th>
                          <th className="min-w-175px">LWA Secret</th>
                          <th className="min-w-125px max-w-225px">
                            Refresh Token
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {list?.map((d, i) => {
                          const credential = JSON.parse(d?.credential_details);

                          return (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{credential?.seller_name}</td>
                              <td>{credential?.role_arn}</td>
                              <td>
                                <div className="d-flex align-items-center">
                                  {credential?.region}
                                  {parseInt(d?.default_marketplace) === 1 && (
                                    <Icons
                                      type="sp-api-setting-edit"
                                      className="ms-2"
                                      onClick={() => {
                                        message.destroy();
                                        message.loading("Loading...", 0);
                                        GetMarketplaceParticipationAction(
                                          d?.region
                                        );
                                        setSelected({
                                          id: d?.marketplace_id,
                                          name: d?.marketplace,
                                          region: credential?.region,
                                        });
                                        setSelectedOtherMarketplace([
                                          d?.marketplace_id,
                                        ]);
                                      }}
                                    />
                                  )}
                                </div>
                              </td>
                              <td>{d?.credential_type}</td>
                              <td>
                                {parseInt(d?.default_marketplace) === 1 ? (
                                  <Tag color="blue-inverse">
                                    {d?.marketplace}
                                  </Tag>
                                ) : (
                                  d?.marketplace
                                )}
                              </td>

                              <td>{credential?.access_key}</td>
                              <td>
                                <Tooltip maxTextWidth={150}>
                                  {credential?.secret_key}
                                </Tooltip>
                              </td>
                              <td>
                                <Tooltip maxTextWidth={175}>
                                  {credential?.client_id}
                                </Tooltip>
                              </td>
                              <td>{credential?.client_secret}</td>
                              <td>{credential?.refresh_token}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {sPModal && (
          <SpModal
            list={list?.filter((d) => parseInt(d?.default_marketplace) === 1)}
            view={sPModal}
            onClose={() => setSPModal(false)}
          />
        )}
      </Wrapper>
      <Modal
        width={600}
        title={
          <div className="d-flex align-items-center ">
            Edit SP API{" "}
            <div className="d-flex align-items-center ms-3">
              <img
                style={{
                  width: "18px",
                  objectFit: "contain",
                  marginRight: "5px",
                }}
                src={`/domainImage/${selected?.id}.png`}
                onError={(e) => {
                  e.target.src = "/domainImage/red-flag.png";
                }}
              />
              <Tag color="blue-inverse">{selected?.name}</Tag>
            </div>
          </div>
        }
        open={editSPView}
        onOk={() => {
          const obj = {
            region: selected?.region,
            other_marketplace: selectedOtherMarketplace?.join(","),
          };
          message.destroy();
          message.loading("Loading...", 0);
          StoreSPOtherMarketplaceAction(obj);
        }}
        onCancel={() => setEditSPView(false)}
      >
        <Checkbox.Group
          value={selectedOtherMarketplace}
          onChange={(e) => setSelectedOtherMarketplace(e)}
          className="row mt-5"
        >
          {participationsList?.map((d, i) => (
            <div className="col-4 mb-3" key={i}>
              <Checkbox disabled={d?.id === selected?.id} key={i} value={d?.id}>
                <img
                  style={{
                    width: "22px",
                    objectFit: "contain",
                    marginRight: "10px",
                  }}
                  src={`/domainImage/${d?.id}.png`}
                  onError={(e) => {
                    e.target.src = "/domainImage/red-flag.png";
                  }}
                />
                {d?.name}
              </Checkbox>
            </div>
          ))}
          {participationsList?.length === 0 && <div>No Data</div>}
        </Checkbox.Group>
      </Modal>
    </>
  );
};

export default SPAPI;

/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../commonContext";
import { adminMenus, testArr } from "../config";
import Footer from "../modules/layout/footer";
import Sidebar from "../modules/layout/sidebar";
import PageRouter from "../routes";
import Header_ from "./layout/header";
import Wrapper from "./style";

export default function (props) {
  const history = useHistory();
  const location = useLocation();
  const [hide, setHide] = useState(false);
  const [moveToLogin, setMoveToLogin] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [hideMenus, setHideMenus] = useState(false);

  const [userData, setUserData] = useState(
    JSON.parse(localStorage.getItem("user"))
  );

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem("user")));
  }, [location]);

  useEffect(() => {
    // window.addEventListener("storage", (e) => {
    //   const { adminData, user, userType } = e?.storageArea;

    //   if (user == "undefined" || !user) {
    //     localStorage.clear();
    //     localStorage.setItem("mode", "dark");
    //     return <Redirect to={"/login"} />;
    //   }

    //   if (parseInt(userType) !== 1 && adminData) {
    //     history.replace("/insights");
    //   }
    // });
    return () => {
      // window.removeEventListener("storage", (e) => {});
    };
  }, []);

  window.updateProfile = (e) => {
    setUserData(e);
  };

  const expandSidebar = () => {
    if (991 >= window.innerWidth) {
      const element = document.getElementById("kt_aside");
      const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10 drawer drawer-start";
      if (!element) return;
      element.classList = hide
        ? `${commonFix} drawer drawer-start drawer-on`
        : commonFix;
    }

    if (hide) {
      const node = document.createElement("div");

      node.onclick = () => setHide(false);
      node.style.zIndex = 109;
      document.getElementById("root").appendChild(node);
    } else {
      const node = document.getElementById("drawer-overlay");
      if (node !== null) {
        node.remove();
      }
    }
  };

  const checkWidth = () => {
    setHideMenus(690 > window.innerWidth);
    const element = document.getElementById("kt_aside");
    const commonFix = "aside pt-7 pb-4 pb-lg-7 pt-lg-10";
    if (!element) return;
    element.classList =
      991 >= window.innerWidth ? `${commonFix} drawer drawer-start` : commonFix;
    element.style.width = 991 >= window.innerWidth ? "250px" : "300px";
    const node = document.getElementById("drawer-overlay");
    if (node !== null) {
      node.remove();
    }
  };

  useEffect(() => {
    setHideMenus(690 > window.innerWidth);
    window.addEventListener("resize", (e) => {
      checkWidth();
    });

    return () => {
      window.removeEventListener("resize", () => {});
    };
  }, []);

  useEffect(() => {
    expandSidebar();
  }, [hide]);
  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < 992) {
        setCollapsed(true);
        setHide(true);
      } else {
        setCollapsed(false);
        setHide(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  const contextValue = useContext(GlobalContext);

  const backToAdmin = () => {
    let adminDataLocal = JSON.parse(localStorage.getItem("adminData"));
    if (adminDataLocal) {
      localStorage.setItem("user", adminDataLocal.data);
      contextValue?.updateCommonGlobalVal({
        user_: JSON.parse(adminDataLocal.data),
        subscriptions: null,
      });
      localStorage.setItem(
        "userType",
        JSON.parse(adminDataLocal?.data)?.user_type
      );
      localStorage.setItem(
        "menusList",
        JSON.stringify(
          // eslint-disable-next-line eqeqeq
          JSON.parse(adminDataLocal?.data)?.user_type == 1
            ? adminMenus
            : testArr
        )
      );
      localStorage.setItem("token", adminDataLocal.token);
      setUserData(JSON.parse(adminDataLocal.data));

      localStorage.removeItem("adminData");
      localStorage.removeItem("subscriptions");
      history.replace("/manage-seller-users");
    }
  };

  const GetModules = () =>
    localStorage.getItem("userType") == 2 ? false : true;
  const isAdmin = () => (localStorage.getItem("adminData") ? true : false);
  const contextVar = useContext(GlobalContext);
  if (!localStorage.getItem("token") || moveToLogin === true) {
    localStorage.clear();
    contextVar.updateCommonGlobalVal({ toggle: "dark" });
    localStorage.setItem("mode", "dark");
    return <Redirect to="/login" />;
  }

  return (
    <Wrapper className="fade-in d-flex flex-column flex-root">
      <React.StrictMode>
        <div
          className="page d-flex flex-row flex-column-fluid"
          style={{ height: "100%" }}
        >
          <div
            className="fadeInLeft"
            style={{
              zIndex: window.innerWidth >= 992 ? "999" : "1000",
              // margin: hideMenus ? 0 : '10px',
            }}
          >
            <Sidebar
              logout={() => setMoveToLogin(true)}
              hideMenus={hideMenus}
              user={userData}
              isAdmin={isAdmin}
              collapsed={collapsed}
              userType={GetModules()}
              setHideMenus={setHideMenus}
              backToAdmin={backToAdmin}
              setCollapsed={() => setCollapsed(!collapsed)}
              {...props}
            />
          </div>

          <div
            className=" d-flex flex-column flex-row-fluid wrapperPage"
            style={{
              paddingBottom: "10px",
              borderLeft: "1px solid rgb(97 187 237 / 13%)",
              padding: "15px 0px 0px 15px",
              position: "relative",

              // boxShadow: "inset 4px 0px 10px #61bbed45",
            }}
          >
            <Header_
              logout={() => setMoveToLogin(true)}
              backToUser={setUserData}
              userType={GetModules()}
              hideMenus={hideMenus}
              userData={userData}
              backToAdmin={backToAdmin}
              setHideMenus={setHideMenus}
              setCollapsed={() => setCollapsed(!collapsed)}
              collapsed={collapsed}
              {...props}
            />
            <div
              className=" d-flex flex-column flex-row-fluid"
              style={{ marginBottom: "10px" }}
              id="kt_wrapper"
            >
              <div
                className=" flex-column flex-column-fluid"
                style={{ position: "relative" }}
              >
                <PageRouter authStatus={GetModules()} {...props} />
              </div>
            </div>
            <Footer backToAdmin={backToAdmin} {...props} />
          </div>
        </div>
      </React.StrictMode>
    </Wrapper>
  );
}

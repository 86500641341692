import styled from "styled-components";
import { themeColor } from "../../config";

const Wrapper = styled.div`
  width: 100%;
  .ant-pagination-item {
    color: #5e6278 !important;
    border: 1px solid transparent !important;
    &:hover {
      color: #009ef7 !important;
    }
  }
  .ant-pagination-item-active {
    font-weight: 500;
    ${
      "" /* background: #7e78ef !important;
    border-color: transparent !important; */
    }
    color: #FFF !important;
    border-radius: 0.65rem !important;
    &:hover {
      color: #fff !important;
    }
  }
  .ant-pagination-item-link {
    color: ${() => themeColor()} !important;
    border-color: transparent !important;
    span {
      position: relative;
      top: -2px;
    }
    &:hover {
      color: #ef7fb1 !important;
    }
  }
  .ant-pagination-item-active {
    ${
      "" /* background-color: #7e78ef !important;
    border-color: #7e78ef !important; */
    }
    color: #FFF !important;
  }
  .ant-pagination-item-active {
    background-color: ${() => themeColor()} !important;
    border-color: ${() => themeColor()} !important;
  }
  .ant-pagination-item {
    &:hover {
      color: ${() => themeColor()} !important;
      background-color: rgb(239 127 177 / 6%) !important;
    }
  }

  .ant-select .ant-select-selector {
    // background-color: ${() => themeColor("0.59", true)} !important
    border-radius: 0.65rem;
    border: 1px solid ${() => themeColor("0.59", true)} !important;
    // color: #000 !important;
  }
  .ant-pagination-item-link span {
    top: 0px !important;
  }
  #paginationSize {
    .gap-3 {
      color: #000 !important;
    }
  }
`;

export default Wrapper;

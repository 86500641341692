import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import HighLabelGoals from "./lib/high-label-goals";
import ProductType from "./lib/product-type";
import Wrapper from "./style";
import { message } from "antd";

const Goals = (props) => {
  const {
    fakeActionDashboard,
    salesChannelListAction,
    GetCategoryGoalsListAction,
    UpdateCategoryGoalsAction,
    fakeActionFinanceData,
  } = props;
  const [salesChannelList, setSalesChannelList] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);

  const [list, setList] = useState([]);
  const [listLoading, setListLoading] = useState(true);

  const [productData, setProductData] = useState([]);

  const [filter, setFilter] = useState({
    marketplace_id: "",
    region: "",
    sales_channel: "",
    filter_year: new Date().getFullYear(),
  });

  const SalesChannelListResponse = useSelector(
    (state) => state.Dashboard.SalesChannelListResponse || {}
  );
  const GetCategoryGoalsListResponse = useSelector(
    (state) => state.Goals.GetCategoryGoalsListResponse || {}
  );
  const UpdateCategoryGoalsResponse = useSelector(
    (state) => state.Goals.UpdateCategoryGoalsResponse || {}
  );

  useEffect(() => {
    if (UpdateCategoryGoalsResponse?.status === true) {
      // message.destroy();
      GetCategoryGoalsListAction(filter);
      // message.success(UpdateCategoryGoalsResponse?.message);
      // message.destroy();

      fakeActionFinanceData("UpdateCategoryGoalsResponse");
    } else if (UpdateCategoryGoalsResponse?.status === false) {
      message.destroy();
      message.error(UpdateCategoryGoalsResponse?.message);
      fakeActionFinanceData("UpdateCategoryGoalsResponse");
    }
  }, [UpdateCategoryGoalsResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      const salesList = SalesChannelListResponse?.data?.records?.reduce(
        (acc, e) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region);

          if (e.region === "us-east-1" && e.sales_channel === "Amazon.com") {
            const filter_ = {
              ...filter,
              marketplace_id: e?.id,
              region: e?.region,
              sales_channel: e?.sales_channel,
            };
            setListLoading(true);
            GetCategoryGoalsListAction(filter_);
            setFilter(filter_);
          }
          if (regionIndex === -1) {
            acc.push({
              label: e.region,
              options: [
                { label: e.sales_channel, value: e.id, region: e.region },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: e.sales_channel,
              value: e.id,
              region: e.region,
            });
          }
          return acc;
        },
        []
      );

      const uniqueList = salesList.reduce((acc, r) => {
        if (!acc.find((e) => e.label === r.label)) {
          acc.push(r);
        }
        return acc;
      }, []);

      setSalesChannelList(uniqueList);
      setSalesChannelLoading(false);
      fakeActionDashboard("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setSalesChannelList([]);
      setSalesChannelLoading(false);
      fakeActionDashboard("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  useEffect(() => {
    if (GetCategoryGoalsListResponse?.status === true) {
      message.destroy();
      setList(
        GetCategoryGoalsListResponse?.data?.high_level_goals?.map((d) => {
          return {
            ...d,
            achieved:
              parseInt(d?.goal || 0) === 0
                ? 0
                : parseFloat(
                    (parseFloat(d?.year_to_date || 0) /
                      parseFloat(d?.goal || 0)) *
                      100 || 0
                  ).toFixed(2),
          };
        }) || []
      );
      setProductData(GetCategoryGoalsListResponse?.data?.product_type || []);
      setListLoading(false);
      fakeActionFinanceData("GetCategoryGoalsListResponse");
    } else if (GetCategoryGoalsListResponse?.status === false) {
      setListLoading(false);
      setList([]);
      fakeActionFinanceData("GetCategoryGoalsListResponse");
    }
  }, [GetCategoryGoalsListResponse]);

  useEffect(() => {
    setSalesChannelLoading(true);
    salesChannelListAction();
    return () => {};
  }, []);

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid " id="kt_content_container">
        <HighLabelGoals
          {...props}
          setFilter={setFilter}
          filter={filter}
          salesChannelLoading={salesChannelLoading}
          setListLoading={setListLoading}
          listLoading={listLoading}
          list={list}
          salesChannelList={salesChannelList}
        />
        <ProductType
          listLoading={listLoading}
          list={productData}
          setList={setProductData}
          {...props}
          filter={filter}
        />
      </div>
    </Wrapper>
  );
};

export default Goals;

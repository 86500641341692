import React, { useContext, useEffect } from "react";
import { Wrapper } from "./style";
import { Button, Modal, Spin, message } from "antd";
import { MakeApiCall } from "../../../apis";
import { useState } from "react";
import { GlobalContext } from "../../../commonContext";
import SripeModal from "./lib/sripe-modal";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { numberformat } from "../../../config";

const MySubscriptions = (props) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, contextHolder] = Modal.useModal();
  const [sripeModal, setSripeModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState({});

  const MySubscriptionList = async () => {
    setLoading(true);
    setList([]);
    const response = await MakeApiCall(`stripe-plan-list`, "get", null, true);

    if (response?.status) {
      setLoading(false);
      setList(response?.data?.data);
    } else {
      setLoading(false);
      setList([]);
    }
  };

  useEffect(() => {
    setLoading(true);
    MySubscriptionList();
    return () => {};
  }, []);

  const contextValue = useContext(GlobalContext);

  const subscriptions = contextValue?.data?.subscriptions;
  const user = contextValue.data?.user_;

  const buyPlan = async (value) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(`payment`, "post", value, true);

    if (response?.status === true) {
      const user = JSON.parse(localStorage.getItem("user"));
      const updateUser = {
        ...user,
        u_stripe_plan_id: response?.data?.subscription?.name,
        u_stripe_cust_id: response?.data?.user?.u_stripe_cust_id,
      };
      const subscription = {
        ...response?.data?.subscription,
        service: response?.data?.service,
      };
      localStorage.setItem("user", JSON.stringify(updateUser));
      localStorage.setItem(
        "subscriptions",
        JSON.stringify({
          ...response?.data?.subscription,
          service: response?.data?.service,
        })
      );
      contextValue.updateCommonGlobalVal({
        user_: updateUser,
        subscriptions: subscription,
      });

      message.destroy();
      message.success(response?.message);
      setSripeModal(false);
      window.location.assign(
        response?.data?.service?.[0]?.slug || "/dashboard"
      );
    } else if (response?.status === false) {
      message.destroy();
      message.error(response?.error?.[0]?.[0] || response?.message);
    }
  };

  const SwitchPlan = async (value) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(`switch-plan`, "post", value, true);

    if (response?.status === true) {
      message.destroy();
      message.success(response?.message);
      const user = JSON.parse(localStorage.getItem("user"));

      const updateUser = {
        ...user,
        u_stripe_plan_id: response?.data?.subscription?.name,
        u_stripe_cust_id: response?.data?.user?.u_stripe_cust_id,
      };
      const subscription = {
        ...response?.data?.subscription,
        service: response?.data?.service,
      };

      localStorage.setItem("user", JSON.stringify(updateUser));
      localStorage.setItem("subscriptions", JSON.stringify(subscription));
      contextValue.updateCommonGlobalVal({
        user_: updateUser,
        subscriptions: subscription,
      });
      setSripeModal(false);
      window.location.assign(
        response?.data?.service?.[0]?.slug || "/dashboard"
      );
      // setLoading(true);
      // MySubscriptionList();
    } else if (response?.status === false) {
      message.destroy();
      message.error(response?.error?.[0]?.[0] || response?.message);
    }
  };
  const CancelledPlan = async (value) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(`cancelled-plan`, "post", value, true);

    if (response?.status === true) {
      message.destroy();
      message.success(response?.message);
      const user = JSON.parse(localStorage.getItem("user"));
      const updateUser = {
        ...user,
        u_stripe_plan_id: null,
      };
      localStorage.setItem("user", JSON.stringify(updateUser));
      localStorage.setItem("subscriptions", JSON.stringify({}));
      contextValue.updateCommonGlobalVal({
        user_: updateUser,
        subscriptions: {},
      });

      // setLoading(true);
      // MySubscriptionList();
    } else if (response?.status === false) {
      message.destroy();
      message.error(response?.error?.[0]?.[0] || response?.message);
    }
  };

  const isUser = list?.filter(
    (d) => user?.u_stripe_plan_id === d?.stripe_product_id
  )?.[0]?.plan_name;

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      {isUser && (
        <div className="app-container d-flex flex-stack container-fluid mt-10">
          <div className="page-title d-flex flex-wrap me-3 flex-column justify-content-center">
            <span
              className="fs-3 fw-bold text-gray-600 me-2 lh-1 ls-n2"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              Current Active Plan:{" "}
              <div
                style={{
                  background: "#edc4b9",
                  padding: "10px",
                  borderRadius: "7px",
                  color: "#e6542a",
                }}
                className="ms-3"
              >
                {list?.filter(
                  (d) => user?.u_stripe_plan_id === d?.stripe_product_id
                )?.[0]?.plan_name || ""}
              </div>
            </span>
          </div>
          <div className="d-flex align-items-center gap-2 gap-lg-3"></div>
        </div>
      )}

      <div className="container-fluid" id="kt_content_container">
        <div className="row p-lg-17">
          <span className="fs-2x text-center mb-5 fw-bold text-gray-800 me-2 lh-1 ls-n2">
            Choose Your Plan
          </span>
          {loading && <Spin />}
          {list?.map((d, i) => (
            <div className="col-xl-4" key={i}>
              <div className="d-flex h-100 align-items-center">
                <div
                  className="w-100 d-flex flex-column flex-center rounded-3 bg-light   py-15 px-10"
                  style={{
                    borderTop: "2px solid #e6542a",
                    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                  }}
                >
                  <div className="mb-7 text-center">
                    {/*begin::Title*/}
                    <h1 className="text-gray-900 mb-5 fw-bolder">
                      {d?.plan_name}
                    </h1>
                    {/*end::Title*/}
                    {/*begin::Description*/}

                    {/*end::Description*/}
                    {/*begin::Price*/}
                    <div className="text-center">
                      <span className="mb-2 text-primary">$</span>
                      <span
                        className="fs-3x fw-bold text-primary"
                        data-kt-plan-price-month={39}
                        data-kt-plan-price-annual={399}
                      >
                        {d?.plan_subscription_cost || 0}{" "}
                      </span>
                      <span className="fs-7 fw-semibold opacity-50">
                        /<span data-kt-element="period">USD Per {d?.type}</span>
                      </span>
                    </div>
                    {/*end::Price*/}
                  </div>
                  <div
                    className="w-100 mb-5 mh-200px min-h-200px"
                    style={{ overflow: "auto" }}
                  >
                    {/*begin::Item*/}
                    {d?.services?.map((d, i) => (
                      <div key={i} className="d-flex align-items-center mb-5">
                        <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                          {d?.service_name}{" "}
                        </span>
                        <svg
                          width={24}
                          height={24}
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            opacity="0.3"
                            x={2}
                            y={2}
                            width={20}
                            height={20}
                            rx={10}
                            fill="currentColor"
                          />
                          <path
                            d="M10.4343 12.4343L8.75 10.75C8.33579 10.3358 7.66421 10.3358 7.25 10.75C6.83579 11.1642 6.83579 11.8358 7.25 12.25L10.2929 15.2929C10.6834 15.6834 11.3166 15.6834 11.7071 15.2929L17.25 9.75C17.6642 9.33579 17.6642 8.66421 17.25 8.25C16.8358 7.83579 16.1642 7.83579 15.75 8.25L11.5657 12.4343C11.2533 12.7467 10.7467 12.7467 10.4343 12.4343Z"
                            fill="currentColor"
                          />
                        </svg>
                      </div>
                    ))}
                    {/*end::Item*/}
                  </div>
                  <div
                    className="w-100 mb-10"
                    style={{ borderTop: "1px solid grey" }}
                  >
                    <div className="d-flex align-items-center mb-5 mt-5">
                      <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                        Plan include orders upto:
                      </span>
                      <span>{numberformat(d?.no_orders)}</span>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                        No. of user included:
                      </span>
                      <span>5 Users</span>
                    </div>
                    <div className="d-flex align-items-center mb-5">
                      <span className="fw-semibold fs-6 text-gray-800 flex-grow-1 pe-3">
                        No. of marketplace included:
                      </span>
                      <span>1 Pri, 5 Sec</span>
                    </div>
                    <div
                      data-test="_ * 30 days free trial. You will be charged after the trial period."
                      className=" line-clamp"
                      style={{
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        wordBreak: "break-all",
                        borderTop: "1px solid grey",
                        paddingTop: "10px",
                      }}
                    >
                      * 30 days free trial. You will be charged after the trial
                      period.
                    </div>
                  </div>

                  {!user?.u_stripe_cust_id ||
                  subscriptions?.status === "canceled" ||
                  !subscriptions?.plan_name ? (
                    <Button
                      onClick={() => {
                        setSripeModal(true);
                        setSelectedCard(d);
                      }}
                      style={{ background: "green" }}
                      type="primary"
                    >
                      Buy Plan
                    </Button>
                  ) : (
                    <Button
                      onClick={() => {
                        if (subscriptions?.name === d?.stripe_product_id) {
                          modal.confirm({
                            title: "Confirm",
                            icon: <ExclamationCircleOutlined />,
                            content: (
                              <>
                                <span>
                                  Are you sure, you want to Unsubscribe{" "}
                                </span>
                                <b>{d?.plan_name}?</b>
                              </>
                            ),
                            okText: "Yes",
                            cancelText: "No",
                            onOk: () => {
                              CancelledPlan({
                                subscription_id: subscriptions?.id,
                              });
                            },
                          });
                        } else {
                          modal.confirm({
                            title: "Confirm",
                            icon: <ExclamationCircleOutlined />,
                            content: (
                              <>
                                <span>
                                  Are you sure, you want to Switch Plan{" "}
                                </span>
                                <b>{d?.plan_name}?</b>
                              </>
                            ),
                            okText: "Yes",
                            cancelText: "No",
                            onOk: () => {
                              SwitchPlan({
                                product_id: d?.stripe_product_id,
                                price_id: d?.stripe_price_id,
                                plan_name: d?.plan_name,
                              });
                            },
                          });
                        }
                      }}
                      type="primary"
                      style={{
                        backgroundColor:
                          subscriptions?.status === "canceled"
                            ? ""
                            : subscriptions?.name !== d?.stripe_product_id
                            ? "green"
                            : "",
                      }}
                    >{`${
                      subscriptions?.status === "canceled"
                        ? "Buy Plan"
                        : subscriptions?.name === d?.stripe_product_id
                        ? "Unsubscribe Plan"
                        : !subscriptions?.plan_name
                        ? "Buy Plan"
                        : "Switch Plan"
                    }`}</Button>
                  )}
                  {console.log(subscriptions?.plan_name, " subscriptions")}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {contextHolder}
      {sripeModal && (
        <SripeModal
          onSubmit={buyPlan}
          show={sripeModal}
          onHide={() => {
            setSripeModal(false);
            setSelectedCard({});
          }}
          selectedCard={selectedCard}
        />
      )}
    </Wrapper>
  );
};

export default MySubscriptions;

import Auth from "../../modules/auth/index.reducer";
import User from "../../modules/admin/user/index.reducer";
import AmazonCredentials from "../../modules/amazon-credentials/index.reducer";
import Dashboard from "../../modules/dashboard/index.reducer";
import BySKU from "../../modules/sales-analytics/by-sku/index.reducer";
import ByASIN from "../../modules/sales-analytics/by-asin/index.reducer";
import byBrand from "../../modules/sales-analytics/by-brand/index.reducer";
import AdvertisingReport from "../../modules/advertising-report/index.reducer";
import FinanceData from "../../modules/finance-api/index.reducer";
import MappingData from "../../modules/mapping/index.reducer";
import MomYoyComparison from "../../modules/mom-&-yoy-comparison/index.reducer";
import FBAManageInventory from "../../modules/manage-inventory/index.reducer";
import FBAReservedInventory from "../../modules/reserved-inventory/index.reducer";
import CronLogs from "../../modules/application-logs/cron-log/index.reducer";
import SystemEventLog from "../../modules/application-logs/system-events-log/index.reducer";
import Currency from "../../modules/currency-conversion/index.reducer";
import Goals from "../../modules/goals/index.reducer";
import Setting from "../../modules/setting/index.reducer";
import MySubscriptions from "../../modules/my-subscriptions/index.reducer";

const reducers = {
  Auth,
  User,
  AmazonCredentials,
  Dashboard,
  BySKU,
  ByASIN,
  byBrand,
  AdvertisingReport,
  FinanceData,
  MappingData,
  MomYoyComparison,
  FBAManageInventory,
  FBAReservedInventory,
  CronLogs,
  SystemEventLog,
  Currency,
  Goals,
  Setting,
  MySubscriptions,
};

export default reducers;

import { DotChartOutlined } from "@ant-design/icons";
import { Popover, Select, Skeleton } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../commonContext";
import Icons from "../../../components/icons";
import Pagination from "../../../components/pagination";
import RangePickerASIN from "../../../components/range-picker";
import { TableLoading } from "../../../components/table-animation";
import { DateFormat, RangePresets } from "../../../config";
import { DashboardAdTableFilter } from "../../../core/helper/static";

import Wrapper from "./style";
import NoData from "../../../components/no-data";

const numberWithCommas = (x) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return x;
};
const disabledDate = (current) => {
  return current && current > moment().endOf("day");
};

function formatNumber(value) {
  if (value >= 1000000000) {
    return (value / 1000000000).toFixed(0) + "b";
  } else if (value >= 1000000) {
    return (value / 1000000).toFixed(0) + "m";
  } else if (value >= 1000) {
    return (value / 1000).toFixed(0) + "k";
  } else {
    return value.toFixed(0);
  }
}

const Summary = ({
  AdvertisingSummeryAction,
  fakeActionDashboard,
  salesChannelListAction,
}) => {
  const [sign, setSign] = useState("$");
  const [filter, setFilter] = useState({
    marketplace_id: "",
    region: "",
    sales_channel: "",
    daterange: [
      dayjs().add(-30, "d").format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ],
    sort_type: "unit",
    sort_type_: "units_sold",
    ad_sort_type: "ad_type",
  });
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const [salesChannelList, setSalesChannelList] = useState([]);
  const [salesChannelLoading, setSalesChannelLoading] = useState(true);

  const [sortStatus, setSortStatus] = useState(false);
  const [sortType, setSortType] = useState("acos");

  const [adSummeryLoading, setAdSummeryLoading] = useState(true);
  const [adSummeryData, setAdSummeryData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const SalesChannelListResponse = useSelector(
    (state) => state.Dashboard.SalesChannelListResponse || {}
  );
  const AdvertisingSummeryDataResponse = useSelector(
    (state) => state.Dashboard.AdvertisingSummeryDataResponse || {}
  );

  useEffect(() => {
    if (AdvertisingSummeryDataResponse?.status === true) {
      setAdSummeryLoading(false);
      setSign(
        typeof AdvertisingSummeryDataResponse?.data?.currency === "string"
          ? AdvertisingSummeryDataResponse?.data?.currency
          : "$"
      );

      setAdSummeryData(AdvertisingSummeryDataResponse?.data?.record || []);
      fakeActionDashboard("AdvertisingSummeryDataResponse");
    } else if (AdvertisingSummeryDataResponse?.status === false) {
      setAdSummeryData([]);
      setTableData([]);
      setAdSummeryLoading(false);
      fakeActionDashboard("AdvertisingSummeryDataResponse");
    }
  }, [AdvertisingSummeryDataResponse]);

  useEffect(() => {
    if (SalesChannelListResponse?.status === true) {
      const salesList = SalesChannelListResponse?.data?.records?.reduce(
        (acc, e) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region);

          if (e.region === "us-east-1" && e.sales_channel === "Amazon.com") {
            const filter_ = {
              ...filter,
              marketplace_id: e?.id,
              region: e?.region,
              sales_channel: e?.sales_channel,
            };
            setFilter(filter_);

            setAdSummeryLoading(true);
            AdvertisingSummeryAction({
              ...filter_,
              daterange: filter_?.daterange?.join("+-+"),
            });
          }
          if (regionIndex === -1) {
            acc.push({
              label: e.region,
              options: [
                { label: e.sales_channel, value: e.id, region: e.region },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: e.sales_channel,
              value: e.id,
              region: e.region,
            });
          }
          return acc;
        },
        []
      );

      const uniqueList = salesList.reduce((acc, r) => {
        if (!acc.find((e) => e.label === r.label)) {
          acc.push(r);
        }
        return acc;
      }, []);

      setSalesChannelList(uniqueList);
      setSalesChannelLoading(false);
      fakeActionDashboard("SalesChannelListResponse");
    } else if (SalesChannelListResponse?.status === false) {
      setSalesChannelList([]);
      setSalesChannelLoading(false);
      fakeActionDashboard("SalesChannelListResponse");
    }
  }, [SalesChannelListResponse]);

  const getSingleArray = (e) => {
    return (tableData?.map((d) => parseFloat(d?.[e]).toFixed(2)) || [])
      ?.sort((a, b) => parseFloat(b?.sales) - parseFloat(a.sales))
      ?.slice(0, 5);
  };
  const getSingleArray_ = (e) => {
    return adSummeryData?.map((d) => parseFloat(d?.[e]).toFixed(2)) || [];
  };

  const contextVar = useContext(GlobalContext);
  useEffect(() => {
    if (adSummeryData?.length !== 0) {
      setTableData(
        adSummeryData?.slice(
          pagination?.current * pagination?.pageSize - pagination?.pageSize,
          pagination?.pageSize * pagination?.current
        ) || []
      );
    }
  }, [adSummeryData, pagination, filter]);

  useEffect(() => {
    setSalesChannelLoading(true);
    salesChannelListAction();
    return () => {};
  }, []);

  const options = {
    chart: {
      type: "bar",
      background: "#121212", // Dark background color
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
      },
      dropShadow: {
        enabled: true,
        blur: 5, // Shadow blur radius
        opacity: 0.2, // Shadow opacity
      },
    },
    colors: ["#63a0cc", "#ffa658", "#FF5722", "#03A9F4", "#E91E63"],

    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    theme: {
      mode: contextVar?.toggle,
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (value) {
          return sign + numberWithCommas(value); // Call the custom formatting function
        },
      },
    },
    xaxis: {
      categories: (tableData?.map((d) => d?.type) || [])
        ?.sort((a, b) => parseFloat(b?.sales) - parseFloat(a.sales))
        ?.slice(0, 5),

      labels: {
        formatter: function (value) {
          return formatNumber(value); // Call the custom formatting function
        },
      },
    },

    markers: {
      size: 6,
      colors: ["#03A9F4"], // Marker color
      strokeWidth: 2,
      strokeColors: "#FFFFFF", // Marker border color
    },
    plotOptions: {
      bar: {
        borderRadius: 2, // Bar border radius
        horizontal: true,
        borderWidth: 0,
      },
    },
    grid: {
      borderColor: "#333", // Grid line color
    },
  };

  const series_ = [
    {
      name: "Spend",
      data: getSingleArray("spend"),
    },
    {
      name: "Sales",
      data: getSingleArray("sales"),
    },
  ];

  function removeUnderscoresAndCapitalize(inputString) {
    const words = inputString.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    );
    const result = capitalizedWords.join(" ");
    return result;
  }

  const callDateFilterAction = (filter_) => {
    setAdSummeryLoading(true);
    AdvertisingSummeryAction({
      ...filter_,
      daterange: filter_?.daterange?.join("+-+"),
    });
  };

  useEffect(() => {
    if (adSummeryData?.length !== 0) {
      const adSummeryData_ = [...adSummeryData];
      setAdSummeryData(
        adSummeryData_?.sort((a, b) =>
          sortStatus
            ? parseInt(a?.[sortType] || 0) - parseInt(b?.[sortType] || 0)
            : parseInt(b?.[sortType] || 0) - parseInt(a?.[sortType] || 0)
        ) || []
      );

      setTableData(
        adSummeryData_?.slice(
          pagination?.current * pagination?.pageSize - pagination?.pageSize,
          pagination?.pageSize * pagination?.current
        ) || []
      );
    }
  }, [sortStatus]);

  return (
    <Wrapper className="m-3">
      <>
        <div
          className="card-header bg-color"
          id="get-table-ad"
          style={{
            position: "sticky",
            top: "0px",
            zIndex: 9,
          }}
        >
          <div className="card-title title_hh">Advertising Summary</div>
          <div className={`card-toolbar gap-4`}>
            {filter?.ad_sort_type !== "marketplace" && (
              <div className="position-relative d-grid ">
                <Select
                  loading={salesChannelLoading}
                  options={salesChannelList}
                  className=" ant_common_input ml-2 "
                  style={{ width: "200px", marginRight: "15px" }}
                  size="large"
                  value={filter?.marketplace_id || null}
                  maxTagCount="responsive"
                  onClick={(e) => e.preventDefault()}
                  onChange={(e, d) => {
                    if (!e) return;
                    const filter_ = {
                      ...filter,
                      marketplace_id: e,
                      region: d?.region,
                      sales_channel: d?.label,
                    };

                    setAdSummeryLoading(true);
                    setAdSummeryData([]);
                    setTableData([]);
                    AdvertisingSummeryAction({
                      ...filter_,
                      sort_type: filter_?.sort_type_,
                      daterange: filter_?.daterange?.join("+-+"),
                    });
                    setFilter(filter_);
                  }}
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  placeholder="Select Marketplace"
                  id="asin_dashboard_filter_marketplace"
                />
              </div>
            )}
            <div className="position-relative d-grid ">
              <Select
                options={DashboardAdTableFilter}
                allowClear
                onChange={(e) => {
                  setPagination({
                    current: 1,
                    pageSize: 10,
                  });
                  const obj = {
                    ...filter,
                    ad_sort_type: e,
                  };

                  setAdSummeryLoading(true);
                  setAdSummeryData([]);
                  setTableData([]);
                  AdvertisingSummeryAction({
                    ...obj,
                    daterange: obj?.daterange?.join("+-+"),
                  });
                  setFilter(obj);
                }}
                value={filter?.ad_sort_type || null}
                size="large"
                className="w-200px"
                placeholder="Select"
              />
            </div>
            <div className="position-relative d-grid ms-4">
              <RangePickerASIN
                className="ant_common_input"
                presets={RangePresets}
                size="large"
                disabledDate={disabledDate}
                id="asin_dashboard_filter_range_picker"
                value={
                  filter?.daterange
                    ? [
                        dayjs(filter?.daterange?.[0]),
                        filter?.daterange?.[1]
                          ? dayjs(filter?.daterange?.[1])
                          : null,
                      ]
                    : null
                }
                style={{ width: "290px" }}
                onChange={(e) => {
                  const filter_ = {
                    ...filter,
                    daterange: [
                      dayjs(e[0]).format("YYYY-MM-DD"),
                      dayjs(e[1]).format("YYYY-MM-DD"),
                    ],
                  };

                  callDateFilterAction(filter_);

                  setFilter(filter_);
                }}
                onOpenChange={(e) => {
                  if (e) {
                    const filter_ = {
                      ...filter,
                      daterange: [filter?.daterange?.[0], null],
                    };
                    setFilter(filter_);
                  } else {
                    const filter_ = {
                      ...filter,
                      daterange: [
                        dayjs(filter?.daterange?.[0]).format("YYYY-MM-DD"),
                        dayjs(filter?.daterange?.[1] || new Date()).format(
                          "YYYY-MM-DD"
                        ),
                      ],
                    };
                    setFilter(filter_);
                  }
                }}
                format={DateFormat}
                allowClear={false}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </div>
          </div>
        </div>
        <div className="card-body" style={{ zoom: "110%" }}>
          {adSummeryLoading ? (
            <div
              style={{
                margin: "auto",
                height: "370px",
                paddingTop: "140px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Skeleton.Node active={true}>
                <DotChartOutlined
                  style={{
                    fontSize: 40,
                    color: "#bfbfbf",
                  }}
                />
              </Skeleton.Node>
            </div>
          ) : adSummeryData?.length === 0 ? (
            <NoData />
          ) : (
            <Chart options={options} series={series_} type="bar" height={355} />
          )}

          <div className="separator separator-dashed my-2" />

          {adSummeryLoading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
              listRow={5}
            />
          ) : adSummeryData?.length === 0 ? (
            <NoData />
          ) : (
            <>
              <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                  <thead>
                    <tr className="fw-bolder">
                      <th className="min-w-50px">#</th>
                      <th className="min-w-200px">
                        {removeUnderscoresAndCapitalize(
                          filter?.ad_sort_type || "-"
                        )}
                      </th>
                      {("variation" === filter?.ad_sort_type ||
                        filter?.ad_sort_type === "product") && (
                        <th className="min-w-200px">SKU</th>
                      )}

                      <th className="min-w-150px">
                        Spend{" "}
                        <Icons
                          onClick={() => {
                            setSortType("spend");
                            setSortStatus(!sortStatus);
                          }}
                          className="ms-3"
                          type="sort_"
                        />
                      </th>
                      <th className="min-w-150px">
                        Ad Spend %{" "}
                        <Popover
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          content="Calculated by dividing spend of category by Total Ad Spend."
                        >
                          <Icons className="ms-3" type="info" />
                        </Popover>
                        <Icons
                          onClick={() => {
                            setSortType("ad_spend_percentage");
                            setSortStatus(!sortStatus);
                          }}
                          className="ms-3"
                          type="sort_"
                        />
                      </th>
                      <th className="min-w-150px">
                        Sales{" "}
                        <Popover
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          content="Revenue generated directly from advertising."
                        >
                          <Icons className="ms-3" type="info" />
                        </Popover>
                        <Icons
                          onClick={() => {
                            setSortType("sales");
                            setSortStatus(!sortStatus);
                          }}
                          className="ms-3"
                          type="sort_"
                        />
                      </th>
                      <th className="min-w-150px">
                        Orders{" "}
                        <Popover
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          content="Orders generated directly from advertising."
                        >
                          <Icons className="ms-3" type="info" />
                        </Popover>
                        <Icons
                          onClick={() => {
                            setSortType("orders");
                            setSortStatus(!sortStatus);
                          }}
                          className="ms-3"
                          type="sort_"
                        />
                      </th>
                      <th className="min-w-150px">
                        ACoS{" "}
                        <Popover
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          content="Advertising Cost of Sales: Calculated by dividing ad spend by ad revenue"
                        >
                          <Icons className="ms-3" type="info" />
                        </Popover>
                        <Icons
                          onClick={() => {
                            setSortType("acos");
                            setSortStatus(!sortStatus);
                          }}
                          className="ms-3"
                          type="sort_"
                        />
                      </th>
                      <th className="min-w-150px">
                        TACoS{" "}
                        <Popover
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          content="Total Advertising Cost of Sales: Calculated by dividing ad spend by total revenue."
                        >
                          <Icons className="ms-3" type="info" />
                        </Popover>
                        <Icons
                          onClick={() => {
                            setSortType("tacos");
                            setSortStatus(!sortStatus);
                          }}
                          className="ms-3"
                          type="sort_"
                        />
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    {tableData?.map((text, i) => (
                      <tr>
                        <td>{i + 1}</td>
                        <td>
                          {"variation" === filter?.ad_sort_type ||
                          filter?.ad_sort_type === "product" ? (
                            <a className="title_hh">{text?.asin || "-"}</a>
                          ) : (
                            <span>{text?.type || "-"}</span>
                          )}
                        </td>
                        {("variation" === filter?.ad_sort_type ||
                          filter?.ad_sort_type === "product") && (
                          <td>
                            <div className="d-flex">
                              <span>{text?.type?.split(",")?.[0] || "-"}</span>
                              <Popover
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                content={
                                  <div>
                                    {text?.type?.split(",")?.map((d, i) => (
                                      <div key={i}>{d}</div>
                                    ))}
                                  </div>
                                }
                              >
                                <Icons className="ms-3" type="info" />
                              </Popover>
                            </div>
                          </td>
                        )}

                        <td>
                          <span>
                            {sign || ""}
                            {numberWithCommas(
                              parseFloat(text?.spend || 0).toFixed(2)
                            )}
                          </span>
                        </td>
                        <td>
                          <span>
                            {numberWithCommas(
                              parseFloat(
                                text?.ad_spend_percentage || 0
                              ).toFixed(2)
                            )}
                            %
                          </span>
                        </td>
                        <td>
                          <span>
                            {sign || ""}
                            {numberWithCommas(
                              parseFloat(text?.sales || 0).toFixed(2)
                            )}
                          </span>
                        </td>
                        <td>
                          <span>
                            {numberWithCommas(parseInt(text?.orders || 0))}
                          </span>
                        </td>
                        <td>
                          <span>
                            {numberWithCommas(
                              parseFloat(text?.acos || 0).toFixed(2)
                            )}
                            %
                          </span>
                        </td>
                        <td>
                          <span>
                            {numberWithCommas(
                              parseFloat(text?.tacos || 0).toFixed(2)
                            )}
                            %
                          </span>
                        </td>
                      </tr>
                    ))}
                    <tr className="dashboard_ad_footer">
                      <td
                        colSpan={
                          "variation" === filter?.ad_sort_type ||
                          filter?.ad_sort_type === "product"
                            ? 3
                            : 2
                        }
                      >
                        <div className="d-flex justify-content-around">
                          <h3
                            className="title_hh"
                            style={{ textAlign: "center" }}
                          >
                            Total
                          </h3>
                          <div></div>
                        </div>
                      </td>
                      <td>
                        <h4>
                          {" "}
                          <b>
                            {sign || ""}
                            {numberWithCommas(
                              parseFloat(
                                getSingleArray_("spend")
                                  ?.filter((d) => d !== "NaN")
                                  ?.reduce(
                                    (d, c) => parseFloat(d) + parseFloat(c),
                                    0
                                  ) || 0
                              ).toFixed(2)
                            )}
                          </b>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <b>
                            {numberWithCommas(
                              parseFloat(
                                getSingleArray_("ad_spend_percentage")
                                  ?.filter((d) => d !== "NaN")
                                  ?.reduce(
                                    (d, c) => parseFloat(d) + parseFloat(c),
                                    0
                                  ) || 0
                              ).toFixed(2)
                            )}
                            %
                          </b>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <b>
                            {sign || ""}
                            {numberWithCommas(
                              parseFloat(
                                getSingleArray_("sales")
                                  ?.filter((d) => d !== "NaN")
                                  ?.reduce(
                                    (d, c) => parseFloat(d) + parseFloat(c),
                                    0
                                  ) || 0
                              ).toFixed(2)
                            )}
                          </b>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <b>
                            {numberWithCommas(
                              parseInt(
                                getSingleArray_("orders")
                                  ?.filter((d) => d !== "NaN")
                                  ?.reduce(
                                    (d, c) => parseFloat(d) + parseFloat(c),
                                    0
                                  ) || 0
                              )
                            )}
                          </b>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <b>
                            {numberWithCommas(
                              parseFloat(
                                ((getSingleArray_("spend")
                                  ?.filter((d) => d !== "NaN")
                                  ?.map((d) => parseFloat(d))

                                  ?.reduce(
                                    (d, c) => parseFloat(d) + parseFloat(c),
                                    0
                                  ) || 0) /
                                  (getSingleArray_("sales")
                                    ?.filter((d) => d !== "NaN")
                                    ?.map((d) => parseFloat(d))

                                    ?.reduce(
                                      (d, c) => parseFloat(d) + parseFloat(c),
                                      0
                                    ) || 0)) *
                                  100
                              ).toFixed(2)
                            )}
                            %
                          </b>
                        </h4>
                      </td>
                      <td>
                        <h4>
                          <b>
                            {numberWithCommas(
                              parseFloat(
                                ((getSingleArray_("spend")
                                  ?.filter((d) => d !== "NaN")
                                  ?.reduce(
                                    (d, c) =>
                                      parseFloat(d || 0) + parseFloat(c || 0),
                                    0
                                  ) || 0) /
                                  ((getSingleArray_("revenue")
                                    ?.filter((d) => d !== "NaN")
                                    ?.reduce(
                                      (d, c) =>
                                        parseFloat(d || 0) + parseFloat(c || 0),
                                      0
                                    ) || 0) +
                                    (getSingleArray_("sales")
                                      ?.filter((d) => d !== "NaN")
                                      ?.reduce(
                                        (d, c) =>
                                          parseFloat(d || 0) +
                                          parseFloat(c || 0),
                                        0
                                      ) || 0))) *
                                  100
                              ).toFixed(2)
                            )}
                            %
                          </b>
                        </h4>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <Table
                columns={
                  "variation" === filter?.ad_sort_type ||
                  filter?.ad_sort_type === "product"
                    ? columns_
                    : columns_?.filter((d) => d?.title !== "SKU")
                }
                pagination={true}
                onChange={(e) => setPagination(e)}
                dataSource={adSummeryData?.record || []}
                loading={adSummeryLoading}
                scroll={{ x: "max-content" }}
                footer={() => (
                  <table>
                    <thead>
                      <tr>
                        <th
                          className="ant-table-cell"
                          style={{ textAlign: "left" }}
                        ></th>
                        <th
                          className="ant-table-cell"
                          style={{ textAlign: "left" }}
                        ></th>
                        <th
                          className="ant-table-cell"
                          style={{ textAlign: "left" }}
                        ></th>
                        <th
                          className="ant-table-cell"
                          style={{ textAlign: "left" }}
                        ></th>
                        <th
                          className="ant-table-cell"
                          style={{ textAlign: "left" }}
                        ></th>
                        <th
                          className="ant-table-cell"
                          style={{ textAlign: "left" }}
                        ></th>
                        <th
                          className="ant-table-cell"
                          style={{ textAlign: "left" }}
                        ></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                        <td>1</td>
                      </tr>
                    </tbody>
                  </table>
                )}
              /> */}
            </>
          )}
          {adSummeryData?.length > 9 && (
            <Pagination
              loading={adSummeryLoading || adSummeryData?.length === 0}
              pageSize={pagination.pageSize}
              pageSizeStatus={true}
              page={pagination.current}
              totalPage={adSummeryData?.length || 0}
              onPerPage={(e) => {
                setPagination({
                  ...pagination,
                  pageSize: e,
                });
              }}
              onPageNo={(e) => {
                setPagination({
                  ...pagination,
                  current: e,
                });
              }}
            />
          )}
        </div>
      </>
    </Wrapper>
  );
};

export default Summary;

import { Button, Checkbox, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { AppName } from "../../../../config";
import Wrapper from "../style";

export default function (props) {
  const { fakeActionAuth, signUpUserAction } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [checkBox, setCheckBox] = useState(false);

  const SignUpUserResponse = useSelector(
    (state) => state.Auth.SignUpUserResponse || {}
  );

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.setItem("mode", "dark");
    }
  });

  useEffect(() => {
    if (SignUpUserResponse?.status === true) {
      message?.destroy();
      setLoading(false);
      message.success(SignUpUserResponse?.message || "User Added");
      setRedirect("/login");
      fakeActionAuth("SignUpUserResponse");
    } else if (SignUpUserResponse?.status === false) {
      setLoading(false);
      message?.destroy();
      if (
        SignUpUserResponse?.error?.length !== 0 ||
        SignUpUserResponse?.error_data
      ) {
        message.warning(
          Object.values(
            SignUpUserResponse?.error || SignUpUserResponse?.error_data
          )?.[0]?.[0] ||
            SignUpUserResponse?.message ||
            "Something Went Wrong."
        );
      } else if (
        SignUpUserResponse?.data &&
        SignUpUserResponse?.data?.length !== 0
      ) {
        message.warning(
          SignUpUserResponse?.data?.[Object.keys(SignUpUserResponse?.data)?.[0]]
        );
      } else {
        message.warning("Something Went Wrong.");
      }
      fakeActionAuth("SignUpUserResponse");
    }
  }, [SignUpUserResponse]);

  const isLogin = (value) => {
    setLoading(true);
    signUpUserAction({
      ...value,
      rds_credential_id: 1,
      server_credential_id: 1,
    });
  };

  const eventLogin = (e) => {
    if (e.key === "Enter" && !loading) {
      const userId1 = document.getElementById("username");
      const password2 = document.getElementById("password");
      if (userId1 !== null && password2 !== null) {
        if (userId1.value !== "" && password2.value !== "") {
          const eventLoading = {
            username: userId1.value,
            password: password2.value,
          };
          isLogin(eventLoading);
        }
      }
    }
  };
  useEffect(() => {
    window.addEventListener("keyup", eventLogin);
    return () => window.removeEventListener("keyup", eventLogin);
  }, []);
  const phoneRegex = /^\d{10}$/;
  if (localStorage.getItem("token") || redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Wrapper id="login_" className="">
      <div className="container-login100">
        <div
          style={{
            background: "rgb(223 74 35 / 18%)",
            width: "100%",
            height: "100%",
            zIndex: 9,
          }}
        ></div>
        {/* <div
          style={{
            background: "rgb(0 0 0 / 81%)",
            width: "100%",
            height: "100%",
            zIndex: 9,
          }}
        ></div> */}
        <div
          className="login100-more"
          style={{
            position: "absolute",
            inset: 0,
            backgroundImage: `url("https://static.wixstatic.com/media/319eb0_b4f2c46928aa425aaa5aa514171690dc~mv2.jpg/v1/fill/w_1910,h_801,al_c,q_85,enc_auto/319eb0_b4f2c46928aa425aaa5aa514171690dc~mv2.jpg`,
          }}
        />
        <div className="authFadeInBottom wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
          <div
            className=" bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-20 pb-5 cardAuth"
            id="div2 "
            style={{ height: "auto", overflow: "auto" }}
          >
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-450px">
              <div className="d-flex flex-center flex-column-fluid pb-10 pb-lg-15">
                <form className="form w-100">
                  <div className="text-center mb-10">
                    <img
                      className="h-55px shimmer"
                      src={
                        "https://static.wixstatic.com/media/319eb0_2887bc999c644d4c9ed17099c2a9ba4d~mv2.png/v1/fill/w_594,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ZonKeepers-logo-white%20sub.png"
                      }
                      alt
                    />
                  </div>
                  <div className="text-center mb-10">
                    <h1 className="sign-label fw-bolder mb-3">
                      Sign Up to {AppName} Data API
                    </h1>
                  </div>
                  <Form
                    form={form}
                    name="signup"
                    scrollToFirstError
                    layout="vertical"
                    autoComplete="new-password"
                  >
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <Form.Item
                          name="u_name"
                          label="User Name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your User Name!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            size="large"
                            autoFocus
                            placeholder="User Name"
                            id="u_name"
                            autoComplete="new-password"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <Form.Item
                          name="u_email"
                          label="E-mail"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            size="large"
                            autoComplete="new-password"
                            placeholder="E-mail"
                            id="u_email"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <Form.Item
                          name="u_password"
                          label="Password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password
                            autoComplete="new-password"
                            size="large"
                            placeholder="Password"
                          />
                        </Form.Item>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-12">
                        <Form.Item
                          name="u_confirm_password"
                          label="Confirm Password"
                          dependencies={["u_password"]}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("u_password") === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input.Password
                            autoComplete="new-password"
                            placeholder="Confirm Password"
                            size="large"
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Item
                          name="u_contact_no"
                          label="Contact no."
                          rules={[
                            {
                              required: true,
                              message: "Please input your Contact no.!",
                            },
                            {
                              pattern: phoneRegex,
                              message: "Please enter a valid phone number!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input size="large" placeholder="Contact no." />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <Form.Item name="u_address" label="Address" hasFeedback>
                          <Input.TextArea size="large" placeholder="Address" />
                        </Form.Item>
                      </div>
                    </div>
                  </Form>
                  <Form.Item name="agreeToTerms">
                    <Checkbox
                      onChange={(e) => {
                        setCheckBox(e.target.checked);
                      }}
                    >
                      I agree to the terms and conditions
                    </Checkbox>
                  </Form.Item>

                  <div className="d-grid mb-10">
                    <Button
                      loading={loading}
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            if (!checkBox) {
                              message.destroy();
                              return message.warning(
                                "Please select terms and conditions"
                              );
                            }

                            isLogin(values);
                          })
                          .catch((info) => {});
                      }}
                      size="large"
                      type="primary"
                    >
                      Sign Up
                    </Button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Already have an account?
                    <Link className="link-primary" to="/login">
                      {" "}
                      Sign in
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );

  return (
    <Wrapper id="login_" className="">
      <div className="image"></div>
      <div
        className="d-flex justify-content-center justify-content-lg-end px-15 py-8"
        style={{
          position: "absolute",
          inset: 0,
          backdropFilter: "blur(3px) brightness(0.5)",
        }}
      >
        <div
          className="authFadeInBottom bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-10 cardAuth"
          style={{}}
        >
          <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-400px w-xl-500px">
            <div className="d-flex flex-center flex-column-fluid pb-10 pb-lg-15">
              <form className="form w-100">
                <div className="text-center mb-10">
                  <img
                    className="h-55px shimmer"
                    src="/lavley_react/logos/full-logo.png"
                    alt
                  />
                </div>
                <div className="text-center mb-10">
                  <h1 className="text-dark fw-bolder mb-3">
                    Sign Up to {AppName} Data API
                  </h1>
                </div>
                <Form
                  form={form}
                  name="signup"
                  scrollToFirstError
                  layout="vertical"
                  autoComplete="new-password"
                >
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Form.Item
                        name="u_name"
                        label="User Name"
                        rules={[
                          {
                            required: true,
                            message: "Please input your User Name!",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input
                          size="large"
                          autoFocus
                          placeholder="User Name"
                          id="u_name"
                          autoComplete="new-password"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Form.Item
                        name="u_email"
                        label="E-mail"
                        rules={[
                          {
                            type: "email",
                            message: "The input is not valid E-mail!",
                          },
                          {
                            required: true,
                            message: "Please input your E-mail!",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input
                          size="large"
                          autoComplete="new-password"
                          placeholder="E-mail"
                          id="u_email"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Form.Item
                        name="u_password"
                        label="Password"
                        rules={[
                          {
                            required: true,
                            message: "Please input your password!",
                          },
                        ]}
                        hasFeedback
                      >
                        <Input.Password
                          autoComplete="new-password"
                          size="large"
                          placeholder="Password"
                        />
                      </Form.Item>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12">
                      <Form.Item
                        name="u_confirm_password"
                        label="Confirm Password"
                        dependencies={["u_password"]}
                        hasFeedback
                        rules={[
                          {
                            required: true,
                            message: "Please confirm your password!",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (
                                !value ||
                                getFieldValue("u_password") === value
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The two passwords that you entered do not match!"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <Input.Password
                          autoComplete="new-password"
                          placeholder="Confirm Password"
                          size="large"
                        />
                      </Form.Item>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <Form.Item
                        name="u_contact_no"
                        label="Contact no."
                        rules={[
                          {
                            required: true,
                            message: "Contact no. is required",
                          },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.reject();
                              }
                              const validation = /^[0-9\.\-\/]+$/.test(value);
                              if (!validation) {
                                return Promise.reject("is not valid.");
                              }
                              return Promise.resolve();
                            },
                          }),
                        ]}
                        hasFeedback
                      >
                        <Input size="large" placeholder="Contact no." />
                      </Form.Item>
                    </div>
                  </div>
                </Form>
                <div className="d-grid mb-10">
                  <Button
                    loading={loading}
                    onClick={() => {
                      form
                        .validateFields()
                        .then((values) => {
                          isLogin(values);
                        })
                        .catch((info) => {});
                    }}
                    size="large"
                    type="primary"
                  >
                    Sign Up
                  </Button>
                </div>
                <div className="text-gray-500 text-center fw-semibold fs-6">
                  Already have an account?
                  <Link className="link-primary" to="/login">
                    {" "}
                    Sign in
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import axiosCall from "../../../configurations/network-services/axiosCall";

export const BySKUDataAction = (data) => {
  const path = `by-sku?region=${data?.region || ""}&phases=${
    data?.phases || ""
  }&tags=${data?.tag || ""}&sales_channel=${data?.sales_channel || ""}&page=${
    data?.page || 1
  }&pageSize=${data?.perPage || 10}&sort_type=${
    data?.sort_type || ""
  }&daterange=${data?.daterange || ""}&sku=${data?.sku || ""}&filter_value=${
    data?.filter_value || ""
  }&export=${data?.export || ""}`;
  const responseType = "BY_SKU_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SortColumnsAction = (data) => {
  const path = `sort-columns`;
  const responseType = "SORT_COLUMNS";
  return axiosCall("patch", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const SearchKeywordAction = (text) => {
  const path = `search-keyword?search=${text}`;
  const responseType = "SEARCH_KEYWORD";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const WeekComparisionAction = (data) => {
  const path = `week-comparision?region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&&compare_year=${data?.compare_year || ""}&compare_week=${
    data?.compare_week || ""
  }&sort_type=${data?.sort_type || ""}`;
  const responseType = "WEEK_COMPARISION_LIST";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const PieChartDataAction = (data) => {
  const path = `pie-chart?region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&type=${data?.type || ""}&sort_type=${data?.sort_type || ""}&daterange=${
    data?.daterange || ""
  }`;
  const responseType = "PIE_CHART_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const QuarterComparisonAction = (data) => {
  const path = `quarter-comparision?region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}&&compare_year=${
    data?.compare_year || ""
  }&sort_type=${data?.sort_type || ""}`;
  const responseType = "QUARTER_COMPARISON_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const ByGraphAction = (data) => {
  const path = `by-graph?region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&sort_type=${data?.sort_type || ""}&daterange=${
    data?.daterange || ""
  }&asin=${data?.asin || ""}&filter_value=${data?.filter_value || ""}&export=${
    data?.export || ""
  }`;
  const responseType = "BY_GRAPH";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionBySku = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_BY_SKU", state: data });
};

import React from "react";

import { Table } from "antd";
import NoData from "../no-data";
const ASINTable = (props) => {
  const {
    columns,
    fixed,
    dataSource,
    rowKey,
    loading,
    pagination,
    scrollX,
    scrollY,
    ellipsis = false,
    ...rest
  } = props;

  // if (dataSource?.length === 0) {
  //   return <NoData />;
  // }

  return (
    <Table
      columns={columns?.map((d) => {
        return {
          ...d,
          ellipsis:
            d?.ellipsis !== undefined
              ? d?.ellipsis === false
                ? false
                : true
              : ellipsis,
        };
      })}
      fixed={fixed}
      dataSource={dataSource?.map((d, i) => {
        return { ...d, key: i };
      })}
      rowKey={rowKey}
      loading={loading}
      pagination={pagination}
      {...rest}
    />
  );
};

export default ASINTable;

import React, { useState } from "react";
import { Modal, Form, Input, message } from "antd";

const ChangePasswordModal = ({ visible, onCancel, onSubmit }) => {
  const [form] = Form.useForm();

  const validatePassword = (rule, value, callback) => {
    if (value && value.length < 8) {
      callback("Password must be at least 8 characters");
    } else {
      callback();
    }
  };

  const handleOk = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      // You can now submit the form values to your backend or perform the desired action
      onSubmit(values);
      //   message.success("Password changed successfully!");
      //   form.resetFields();
      //   onCancel();
    } catch (error) {
      console.error("Validation error:", error);
    }
  };

  return (
    <Modal
      title="Change Password"
      open={visible}
      onCancel={onCancel}
      onOk={handleOk}
      destroyOnClose
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="u_current_pass"
          label="Current Password"
          rules={[
            { required: true, message: "Please enter your current password" },
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item
          name="u_password"
          label="New Password"
          rules={[
            { required: true, message: "Please enter your new password" },
            { validator: validatePassword },
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>
        <Form.Item
          name="u_confirm_password"
          label="Confirm Password"
          dependencies={["u_password"]}
          rules={[
            { required: true, message: "Please confirm your new password" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("u_password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject("Passwords do not match");
              },
            }),
          ]}
        >
          <Input.Password type="password" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import {
  Button,
  DatePicker,
  Input,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { MakeApiCall } from "../../../apis";
import ASINTooltip from "../../../components/tooltip";
import { DateFormat, numberformat } from "../../../config";
import { ExpandChart } from "./icons";
import ChartView from "./lib/chart";
import ZoomChart from "./lib/zoom-chart";
import Wrapper, { TooltipWrapper } from "./style";
import Icons from "../../../components/icons";

const { TabPane } = Tabs;
const { Option } = Select;
export default function (props) {
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({
    marketplace_id: "",
    region: "",
    sales_channel: "",
    daterange: [
      dayjs().add(-30, "d").format("YYYY-MM-DD"),
      dayjs().format("YYYY-MM-DD"),
    ],
    sort_type: "unit",
    sort_type_: "units_sold",
    ad_sort_type: "ad_type",
  });

  const [revenueFeesData, setRevenueFeesData] = useState({});
  const [sign, setSign] = useState("");

  const [selectedSign, setSelectedSign] = useState("USD");

  const [currencyList, setCurrencyList] = useState([]);
  const [currencyLoading, setCurrencyLoading] = useState(true);

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [tilesLoading, setTilesLoading] = useState(true);
  const [tilesData, setTilesData] = useState({});

  const [salesBreakData, setSalesBreakData] = useState({});
  const [periodData, setPeriodData] = useState({});
  const [salesBreakLoading, setSalesBreakLoading] = useState(true);

  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(true);

  const [selectedASIN, setSelectedASIN] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [graphData, setGraphData] = useState({});

  const [zoomGraph, setZoomGraph] = useState({});

  const [isOpenDropdown, setIsOpenDropdown] = useState(false);

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "Last Week",
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },

    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    {
      label: "Custom Range",
      value: [0, 0],
    },
  ];

  const [filters, setFilters] = useState({
    start_date: dayjs()
      .subtract(2, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    end_date: dayjs().subtract(2, "month").endOf("month").format("YYYY-MM-DD"),
    prev_start_date: dayjs()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD"),
    prev_end_date: dayjs()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD"),
  });

  const getAllListCurrency = async (data) => {
    setCurrencyLoading(true);
    const response = await MakeApiCall(`list-currency-data`, "get", null, true);

    if (response?.status) {
      setCurrencyList(
        response?.data?.map((d) => ({ label: d, value: d })) || []
      );
      setCurrencyLoading(false);
    } else {
      setCurrencyLoading(false);
      setCurrencyList([]);
    }
  };

  const handleDownload = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl.split("/").pop();
    document.body.appendChild(anchor); // Append to the document
    anchor.click();
    document.body.removeChild(anchor); // Clean up
    message.destroy();
  };

  const GetSalesBreak = async (data) => {
    setSalesBreakLoading(true);
    const response = await MakeApiCall(
      `sales-break-data?previous_daterange=${
        data?.start_date || filters?.start_date || ""
      }+-+${data?.end_date || filters?.end_date || ""}&region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&filter_value=${data?.filter_value || searchText || ""}&currency_code=${
        data?.currency_code || selectedSign || "USD"
      }${data?.export ? "&export=all" : ""}&asin=${
        data?.asin || selectedASIN || ""
      }&daterange=${
        data?.prev_start_date || filters?.prev_start_date || ""
      }+-+${data?.prev_end_date || filters?.prev_end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (data?.export) {
        message.destroy();
        handleDownload(response?.data?.file);

        return;
      }

      setSalesBreakData(response?.data?.data || {});
      setPeriodData(response?.data?.data || {});

      setSalesBreakLoading(false);
    } else {
      setSalesBreakData({});
      setSalesBreakLoading(false);
    }
  };
  const TilesData = async (data) => {
    setTilesLoading(true);
    const response = await MakeApiCall(
      `tiles-data?previous_daterange=${
        data?.start_date || filters?.start_date || ""
      }+-+${data?.end_date || filters?.end_date || ""}&region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&filter_value=${data?.filter_value || searchText || ""}&currency_code=${
        data?.currency_code || "USD"
      }&asin=${data?.asin || selectedASIN || ""}&daterange=${
        data?.prev_start_date || filters?.prev_start_date || ""
      }+-+${data?.prev_end_date || filters?.prev_end_date || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTilesData(response?.data?.data || {});
      setGraphData(response?.data?.graph_data || {});
      setSign(response?.data?.currency || "");
      setTilesLoading(false);
    } else {
      setTilesLoading(false);
    }
  };

  const GetProduct = async (data) => {
    setTableLoading(true);

    const response = await MakeApiCall(
      `get-products?region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setTableLoading(false);
      setTableData(response?.data?.data || []);
    } else {
      setTableData([]);
      setTableLoading(false);
    }
  };

  const hotCall = async (e) => {
    await GetProduct({
      region: e?.region_name,
      sales_channel: e?.sales_channel,
    });
    await TilesData({
      region: e?.region_name,
      sales_channel: e?.sales_channel,
      currency_code: selectedSign,
      asin: selectedASIN,
    });
    await GetSalesBreak({
      region: e?.region_name,
      sales_channel: e?.sales_channel,
      currency_code: selectedSign,
      asin: selectedASIN,
    });

    // await TopProductData({
    //   region: e?.region_name,
    //   currency_code: selectedSign,
    //   sales_channel: e?.sales_channel,
    // });
    // await TopProfitLossData({
    //   region: e?.region_name,
    //   sales_channel: e?.sales_channel,
    //   currency_code: selectedSign,
    // });
  };

  const MarketplaceList = async () => {
    const response = await MakeApiCall(
      `get-user-marketplace-list`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        const salesList = response?.data?.reduce((acc, e) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region_name);

          if (
            e.region_name === "us-east-1" &&
            e.sales_channel === "Amazon.com"
          ) {
            setMarketplaceSelected({
              marketplace: e?.name,
              marketplace_id: e?.id,
              region: e?.region_name,
              sales_channel: e?.sales_channel,
            });
            hotCall(e);
          }

          if (regionIndex === -1) {
            acc.push({
              label: e.region_name,
              options: [
                {
                  label: e.sales_channel,
                  value: e.id,
                  region: e?.region_name,
                  marketplace_id: e?.id,
                  marketplace: e?.name,
                  sales_channel: e?.sales_channel,
                },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: e.sales_channel,
              value: e.id,
              marketplace_id: e?.id,
              region: e.region_name,
              marketplace: e?.name,
              sales_channel: e?.sales_channel,
            });
          }
          return acc;
        }, []);

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList(uniqueList);
        setMarketplaceLoading(false);
      } else {
        setMarketplaceLoading(false);
      }
    } else {
    }
  };

  useEffect(() => {
    MarketplaceList();
    getAllListCurrency();
    return () => {};
  }, []);

  const titleFind = {
    sales: "Order Sales",
    margin: "Net Margin",
    profit: "Net Profit",
    roi: "ROI",
  };
  const series = (d) => {
    if (Object.keys(d || {})?.length === 0) return [];
    return [
      {
        data: graphData?.[d?.chartValue],
        type: "line",
        name: "Date",
      },
      {
        data: graphData?.[`period_${d?.chartValue}`],

        type: "bar",
        name: "Comparison Date",
      },
    ];
  };

  const chartOptions__ = {
    options: {
      chart: {
        type: "line",
        height: 150,
        stacked: false,
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: false,
        },

        toolbar: {
          show: false, // Hides the toolbar
        },
      },
      tooltip: {
        enabled: false,
      },
      fill: {
        type: ["gradient", "solid"],
        gradient: {
          shade: "dark",
          gradientFromColors: ["green", "#ffeff0"],
          gradientToColors: ["#ffeff0", "green"],
          shadeIntensity: 1,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 33.33, 66.67, 100],
        },
      },
      grid: {
        show: false, // Hides the grid lines
      },
      stroke: {
        curve: "smooth",
        width: [6, 0],
        shadow: {
          enabled: true,
          color: "#000",
          top: 0,
          left: 0,
          blur: 5, // Increase the blur radius for a heavier shadow
          opacity: 1, // Maximum opacity for a heavy shadow effect
        },
      },
      // colors: ["#008FFB"],
      tooltip: {
        enabled: false,
      },
      xaxis: {
        type: "numeric",
        labels: {
          show: false, // Hides the x-axis labels
        },
        axisBorder: {
          show: false, // Hides the x-axis border
        },
        axisTicks: {
          show: false, // Hides the x-axis ticks
        },
      },
      yaxis: {
        show: false, // Hides the y-axis entirely
      },
    },
  };

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <div className="app-container header-fix d-flex flex-stack  mt-5 ">
          <div className="page-title d-flex flex-wrap me-3 flex-column mt-5 justify-content-center"></div>
          <div
            className="d-flex align-items-center mt-5 flex-wrap  gap-2 gap-lg-3 fix-over"
            id="fix-over"
          >
            <Button
              type="primary"
              size="large"
              prefix={<Icons type="export" />}
              onClick={() => {
                GetSalesBreak({
                  export: true,
                });
              }}
            >
              Export
            </Button>
            <TooltipWrapper>
              <Tooltip
                placement="bottomRight"
                className="title-tooltip"
                getPopupContainer={(triggerNode) =>
                  document.getElementById("title-select").parentNode
                }
                title={
                  isOpenDropdown ? (
                    ""
                  ) : (
                    <div style={{ width: "480px" }}>
                      {tableData?.filter((r) => selectedASIN?.includes(r?.asin))
                        ?.length === 0
                        ? "No Products Selected"
                        : tableData
                            ?.filter((r) => selectedASIN?.includes(r?.asin))
                            ?.map((d, _, row) => (
                              <div
                                style={{
                                  borderBottom:
                                    row?.length === _ + 1
                                      ? "none"
                                      : "1px solid grey",
                                  marginBottom:
                                    row?.length === _ + 1 ? "0px" : "10px",
                                  marginTop: "10px",
                                  paddingBottom: "10px",
                                }}
                                className="d-flex"
                              >
                                <img
                                  style={{
                                    width: "33px",
                                    marginRight: "10px",
                                    borderRadius: "7px",
                                  }}
                                  src={d?.product_image}
                                />
                                {/* <div className="ellipsis">{d?.title}</div> */}
                                <pre
                                  className="text-title"
                                  style={{ fontSize: "14px" }}
                                >
                                  {d?.title}
                                </pre>
                              </div>
                            ))}
                    </div>
                  )
                }
              >
                <Select
                  placeholder="Select Product"
                  className="w-250px"
                  mode="multiple"
                  getPopupContainer={(triggerNode) => triggerNode.parentNode}
                  maxTagCount={"responsive"}
                  id="title-select"
                  onDropdownVisibleChange={(e) => setIsOpenDropdown(e)}
                  allowClear
                  dropdownStyle={{
                    minWidth: "500px",
                  }}
                  size="large"
                  onChange={(e) => {
                    setSelectedASIN(e);
                    TilesData({
                      ...marketplaceSelected,
                      ...filters,
                      currency_code: selectedSign,
                      asin: e,
                    });

                    GetSalesBreak({
                      ...marketplaceSelected,
                      ...filters,
                      currency_code: selectedSign,
                      asin: e,
                    });
                  }}
                  value={selectedASIN}
                >
                  {tableData?.map((d, i) => (
                    <Option key={i} value={d?.asin}>
                      <div className="d-flex align-items-center">
                        <img
                          style={{
                            width: "33px",
                            marginRight: "10px",
                            borderRadius: "7px",
                          }}
                          src={d?.product_image}
                        />

                        <pre className="text-title">{d?.title}</pre>
                      </div>
                    </Option>
                  ))}
                </Select>
              </Tooltip>
            </TooltipWrapper>
            <div className="position-relative">
              <div style={{ position: "absolute", top: "-20px", left: "0px" }}>
                Date
              </div>
              <DatePicker.RangePicker
                allowClear={false}
                presets={rangePresets}
                getPopupContainer={(target) => target.parentNode}
                size="large"
                format={DateFormat}
                value={
                  filters?.prev_start_date && filters?.prev_end_date
                    ? [
                        dayjs(filters?.prev_start_date, "YYYY-MM-DD"),
                        dayjs(filters?.prev_end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                onChange={(e) => {
                  const obj = {
                    ...filters,
                    prev_start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    prev_end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };
                  TilesData({ ...obj, currency_code: selectedSign });
                  // TopProductData({ ...obj, currency_code: selectedSign });
                  // TopProfitLossData({ ...obj, currency_code: selectedSign });
                  GetSalesBreak({
                    ...obj,
                    currency_code: selectedSign,
                    asin: selectedASIN,
                  });
                  setFilters(obj);
                }}
                className="w-250px"
              />
            </div>
            <div className="position-relative">
              <div style={{ position: "absolute", top: "-20px", left: "0px" }}>
                Comparison Date
              </div>
              <DatePicker.RangePicker
                presets={rangePresets}
                getPopupContainer={(target) => target.parentNode}
                value={
                  filters?.start_date && filters?.end_date
                    ? [
                        dayjs(filters?.start_date, "YYYY-MM-DD"),
                        dayjs(filters?.end_date, "YYYY-MM-DD"),
                      ]
                    : []
                }
                onChange={(e) => {
                  const obj = {
                    ...filters,
                    start_date: e?.[0]
                      ? dayjs(e?.[0]).format("YYYY-MM-DD")
                      : null,
                    end_date: e?.[1]
                      ? dayjs(e?.[1]).format("YYYY-MM-DD")
                      : null,
                  };
                  TilesData({ ...obj, currency_code: selectedSign });
                  // TopProductData({ ...obj, currency_code: selectedSign });
                  // TopProfitLossData({ ...obj, currency_code: selectedSign });
                  GetSalesBreak({
                    ...obj,
                    currency_code: selectedSign,
                    asin: selectedASIN,
                  });
                  setFilters(obj);
                }}
                size="large"
                format={DateFormat}
                className="w-250px"
                allowClear={false}
              />
            </div>

            <Select
              placeholder="Select Currency"
              className="w-100px"
              size="large"
              getPopupContainer={(target) => target.parentNode}
              options={currencyList}
              loading={currencyLoading}
              value={selectedSign}
              onChange={(e) => {
                GetSalesBreak({
                  ...marketplaceSelected,
                  ...filters,
                  asin: selectedASIN,
                  currency_code: e,
                });
                TilesData({
                  ...marketplaceSelected,
                  ...filters,
                  currency_code: e,
                });
                setSelectedSign(e);
              }}
            />

            <Select
              placeholder="Select Marketplace"
              style={{ width: "200px" }}
              size="large"
              loading={marketplaceLoading}
              value={marketplaceSelected?.marketplace_id}
              onChange={(e, _) => {
                const obj = {
                  marketplace: _?.marketplace,
                  marketplace_id: _?.marketplace_id,
                  region: _?.region,
                  sales_channel: _?.sales_channel,
                };
                TilesData({ ...obj, currency_code: selectedSign });

                GetSalesBreak({
                  ...obj,
                  currency_code: selectedSign,
                  asin: selectedASIN,
                });
                setMarketplaceSelected(obj);
              }}
              options={marketplaceList}
              getPopupContainer={(target) => target.parentNode}
            />
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-xxl-4 col-xl-12 col-12">
            <div
              className="row g-5 mb-xl-5 "
              style={{
                filter: tilesLoading ? "blur(5px)" : "blur(0px)",
              }}
            >
              {[
                {
                  label: "Revenue",
                  value: "sales",
                  chartLabels: "sales_label",
                  chartValue: "sales",
                  sign: sign,
                  color: "#fc626b",
                },
                {
                  label: "Net Profit",
                  value: "profit",
                  chartLabels: "profit_label",
                  chartValue: "profit",
                  sign: sign,
                  color: "#27b0d1",
                },
                {
                  label: "Net Margin",
                  value: "margin",
                  chartLabels: "margin_label",
                  chartValue: "margin",
                  sign: "%",
                  color: "#fdb903",
                },
                {
                  label: "ROI",
                  value: "roi",
                  chartLabels: "roi_label",
                  chartValue: "roi",
                  sign: "%",
                  color: "#a878f4",
                },
              ].map((d, i) => (
                <div
                  key={i}
                  className="col-xxl-12 col-xl-6 col-lg-6 col-md-12 "
                >
                  <div
                    className="card overflow-hidden"
                    style={{
                      boxShadow:
                        "0 2px 6px 0 rgb(218 218 253 / 65%), 0 2px 6px 0 rgb(206 206 238 / 54%)",
                      borderRadius: "10px",
                    }}
                  >
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <p
                            style={{ fontSize: "18px", color: "#212529" }}
                            className="mb-0"
                          >
                            {d?.label}
                          </p>
                          <h5
                            style={{
                              color: "#212529",
                              fontSize: "24px",
                              fontWeight: 500,
                            }}
                            className="mb-0"
                          >
                            {d?.label === "Net Margin" || d?.label === "ROI"
                              ? ""
                              : sign}
                            {numberformat(tilesData?.[d?.value] || 0)}
                            {d?.label === "Net Margin" || d?.label === "ROI"
                              ? "%"
                              : ""}
                          </h5>
                        </div>

                        <div>
                          <Tag
                            style={{
                              fontSize: "16px",
                              border: `1px solid #ffb206`,
                              color: "#E9613B",
                              background: "#FFF",
                              padding: "3px 7px",
                              textShadow: "0 0 black",
                              borderRadius: "8px",
                            }}
                          >
                            {d?.label === "Net Margin" || d?.label === "ROI"
                              ? ""
                              : sign}
                            {numberformat(
                              tilesData?.[`period_${d?.value}`] || 0
                            )}
                            {d?.label === "Net Margin" || d?.label === "ROI"
                              ? "%"
                              : ""}{" "}
                            - Comparison
                          </Tag>
                        </div>
                        <span
                          onClick={() => setZoomGraph(d)}
                          style={{
                            position: "absolute",
                            right: 4,
                            width: "fit-content",
                            top: "4px",
                            cursor: "pointer",
                          }}
                        >
                          {ExpandChart}
                        </span>
                      </div>
                      <div style={{ minHeight: "111px" }}>
                        <ReactApexChart
                          options={{
                            ...chartOptions__?.options,
                            colors: [d?.color, "#eae5e5"],
                          }}
                          series={series(d)}
                          type="line"
                          height={100} // Adjust the height as needed
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="col-xxl-8 col-xl-12 col-12">
            {console.log(salesBreakLoading, "loading")}
            {ChartView(
              revenueFeesData,
              sign,
              salesBreakLoading,
              salesBreakData,
              periodData
            )}
          </div>
        </div>
      </div>

      <ZoomChart
        show={Object.keys(zoomGraph || {}).length !== 0}
        zoomGraph={zoomGraph}
        onClose={() => setZoomGraph({})}
        graphData={graphData}
        filters={filters}
        sign={sign}
        series={(e) => series(e)}
        labels={zoomGraph}
      />
    </Wrapper>
  );
}

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "DASHBOARD_LIST_SUCCESS":
    case "DASHBOARD_LIST_ERROR":
      return {
        ...state,
        DashboardListResponse: action.updatePayload,
      };
    case "PRODUCT_IMAGE_LIST_SUCCESS":
    case "PRODUCT_IMAGE_LIST_ERROR":
      return {
        ...state,
        ProductImageListResponse: action.updatePayload,
      };
    case "ADVERTISING_SUMMERY_DATA_SUCCESS":
    case "ADVERTISING_SUMMERY_DATA_ERROR":
      return {
        ...state,
        AdvertisingSummeryDataResponse: action.updatePayload,
      };
    case "LIST_CURRENCY_DATA_SUCCESS":
    case "LIST_CURRENCY_DATA_ERROR":
      return {
        ...state,
        ListCurrencyDataResponse: action.updatePayload,
      };
    case "TILES_DATA_SUCCESS":
    case "TILES_DATA_ERROR":
      return {
        ...state,
        TilesDataResponse: action.updatePayload,
      };
    case "SALES_CHANNEL_LIST_SUCCESS":
    case "SALES_CHANNEL_LIST_ERROR":
      return {
        ...state,
        SalesChannelListResponse: action.updatePayload,
      };
    case "FAKE_ACTION_DASHBOARD":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};

import { createGlobalStyle } from "styled-components";
import { themeColor } from "./config";

const AppStyleWrapper = createGlobalStyle`
body {
    background-color: ${(props) => props.theme.colors.body};
    font-family: Roboto;
}
.bg-color {
    background-color: ${(props) => props.theme.colors.body} !important;
    
}
.card {
  background-color: #FFF !important;
  border-radius: 0.5rem !important;
  background-clip: padding-box !important;
  box-shadow: 0 2px 6px 0 rgba(67, 89, 113, .12) !important;
}
.ant-collapse {
  background-color: ${(props) => props.theme.colors.collapse.bg} !important;
}
.ant-table-wrapper tr.ant-table-expanded-row >td {
  background-color: ${(props) =>
    props.theme.colors.sidebar.selectedMenuBG} !important;
  padding-top: 40px;
}
#webpack-dev-server-client-overlay {
  display: none;
}
.text-title {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: sans-serif;

  font-size: 14px !important;
  margin-bottom: 0 !important;
}
.apexcharts-canvas {
  svg {
    background: #ffffff00 !important;
  }
}
.rc-virtual-list-scrollbar {
  div {
    background: rgb(0 0 0 / 7%) !important;
  }
}
::-webkit-scrollbar {
    width: 10px !important;
    height: 8px !important;
    background-color: transparent;
  }

  ::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgb(255 255 255 / 40%);
    border: 2px solid transparent;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid ${() => themeColor()};
    background-clip: content-box;
    border-radius: 10px;
    background-color: ${() => themeColor("0.6", true)} !important;
  }

  ::-webkit-scrollbar-thumb:hover {
    border: 3px solid transparent;
    background-color: ${() => themeColor()} !important;
  }
  .ant-table-content,
  #login_,
  .ant-table-body,
  .gridScroll,
  .notificationList,
  #add_user,
  .grid-list,
  #add_rules,
  #kt_aside_menu_wrapper,
  .sidebar_asinwiser,
  #kt_wrapper {
    &::-webkit-scrollbar {
      width: 10px !important;
    height: 8px !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: transparent !important;
      border: 2px solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      border: 3px solid transparent !important;
      background-clip: content-box;
      background-color: transparent !important;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: ${() => themeColor("", "hover")} !important;
    }
    &:hover {
    &::-webkit-scrollbar {
    width: 10px !important;
    height: 8px !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: rgb(255 255 255 / 40%);
    border: 2px solid transparent;
  }
  &::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(255,0,0,0.4); 
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 3px solid ${() => themeColor()};
    background-clip: content-box;
    border-radius: 10px;
    background-color: ${() => themeColor("0.6", true)} !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    border: 3px solid transparent;
    background-color: ${() => themeColor()} !important;
  }
    }
  }

.btn-success, .nav-link.active {
  background-color: ${() => themeColor()} !important;
  &:hover {
  background-color: ${() => themeColor("", "hover")} !important;
  }
}
  .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(
      to right bottom,
      rgb(255 255 255),
      #ffffff
    ) !important;
  }
  .line-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .ant-tooltip-inner {
    background-color: rgb(255 255 255) !important;
    font-weight: 500;
    color: #000 !important;
  }
  .ant-tooltip-arrow:before {
    background: white !important;
  }
  .ant-tooltip-inner {
    color: #000 !important;
    a {
      color: #000 !important;
    }
  }

    .shimmer {
        -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
          100%;
        background-repeat: no-repeat;
        animation: shimmer 2.5s infinite;
    }
    @keyframes shimmer {
        100% {
          -webkit-mask-position: left;
        }
    }
  ::selection {
    color: #FFF;
    background: ${(props) => props.theme.colors.sidebar.logoutButton};
  }
  body > #root {
    height: 100%;
  }
  body > #root > .globalHeight {
    height: 100%;
  }
  .antd-success {
    color: #fff !important;
    background-color: ${() => themeColor()} !important;
  }
  .authFadeInBottom {
    animation-duration: 1s;
    animation-fill-mode: both;
    animation-name: authFadeInBottom;
  }

  @keyframes authFadeInBottom {
    from {
      opacity: 0;
      transform: translateY(-25%);
    }
    to {
      opacity: 1;
    }
  }



  .fade-in {
    opacity: 0;
    animation: fadeIn ease-in 1;
    animation-fill-mode: forwards;
    animation-duration: 0.4s;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .fadeInBottom {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInBottom;
  }

  @keyframes fadeInBottom {
    from {
      opacity: 0;
      width: 0px !important;
      ${"" /* transform: translateY(-100%); */}
    }
    to {
      opacity: 1;
    }
  }
  .fadeInLeft {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInLeft;
  }

  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
    }
  }
  .fadeInRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInRight;
    transition: 0.7s all ease-in-out;
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(0, 100px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  .fadeInHideRight {
    animation-duration: 0.7s;
    animation-fill-mode: both;
    animation-name: fadeInHideRight;
  }

  @keyframes fadeInHideRight {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
      height: 0px;
    }
  }

  @media only screen and (min-width: 550px) {
    .smallScreenPageHeader {
      display: none !important;
    }
  }
  .ml-auto {
    margin-left: auto;
  }
  #kt_wrapper {
    overflow: auto;
    height: 100%;
  }


  .Edit-Icon-Antd {
    cursor: pointer;
    margin: 0px 10px;
    svg {
      transition: 0.6s;
      font-size: 20px;
      path:first-child {
        fill: white;
      }
      path {
        fill: grey;
      }
    }
    &:hover {
      svg {
        transform: scale(1.2);
        path {
          fill: grey;
        }
      }
    }
  }
  .info-product-research {
    padding: 0 !important;
    font-size: 11px !important;
  }
.Eye-Icon-Antd, .Copy-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  width: 23px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path::nth-child(3), path::nth-child(4) {
      fill: #0062ff;
    }
    path::nth-child(2) {
      fill: #91caff;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path {
        fill: #0062ff;
      }
      path::nth-child(3), path::nth-child(4) {
        fill: #FFF !important;
      }
    }
  }
}
.Download-Icon-Antd {
  cursor: pointer;
  svg {
    transition: 0.6s;
    font-size: 20px;
    color: #00a1ff;
  }
  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}
.File-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: #7472ee;
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: #7472ee56;
      }
      path {
        fill: #7472ee;
      }
    }
  }
}
.Delete-Icon-Antd {
  cursor: pointer;
  margin: 0px 10px;
  svg {
    transition: 0.6s;
    font-size: 20px;
    path:first-child {
      fill: white;
    }
    path {
      fill: rgb(255 114 114);
    }
  }
  &:hover {
    svg {
      transform: scale(1.2);
      path:first-child {
        fill: rgb(255 114 114);
      }
      path {
        fill: rgb(255 114 114);
      }
    }
  }
}
.cursor-pointer {
  cursor: pointer;
}
.align-item-center {
  align-items: center;
}
.ant-table-layout-fixed table {
    table-layout: auto;
}
.ant-upload-list-item-error {
  border-color: ${() => themeColor("", "light")} !important;
}
.ant-btn-lg {
  font-size: 1.1rem !important;
  font-weight: 500 !important;
}
input:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 50px white inset;
}

#nprogress .bar {
    background: ${() => themeColor()};
    position: fixed;
    z-index: 1031999999999999999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
}


/* Fancy blur effect */

#nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px ${() => themeColor()}, 0 0 5px ${() => themeColor()};
    opacity: 1;
    -webkit-transform: rotate(3deg) translate(0px, -4px);
    -ms-transform: rotate(3deg) translate(0px, -4px);
    transform: rotate(3deg) translate(0px, -4px);
}
#nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    border: solid 2px transparent;
    border-top-color: ${() => themeColor()};
    border-left-color: ${() => themeColor()};
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
}
.svg-icon.svg-icon-success svg [fill]:not(.permanent):not(g) {
    transition: fill 0.3s ease;
    fill: ${() => themeColor()};
}
.antd_selected_presets_date {
  color: #fff;
  background: ${() => themeColor()};
  &:hover {
    color: ${() => themeColor()};
  }
}
.actionMenu {
    // background: ${(props) => props.theme.colors.sidebar.actionMenu};
    cursor: pointer;
    color: ${() => themeColor()};
    z-index: 9999;
    // position: absolute;
    // top: 40px;
    // left: -27px;
    // top: 3px;
    // left: 3px;
    border-radius: 8px;
    z-index: 9999;
    border-right: none;
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
    ${"" /* box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; */}
    transition: 0.6s;
    
    span {
      // position: absolute;
      // margin: auto;
      height: fit-content;
      width: fit-content;
      background: #e6542a;
      width: 40px;
      height: 40px;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
      background: #0091e2;
      color: white; 

      }
      svg {
        color: #FFF;
      }
    }
  }









  .table-skeleton {
  border-collapse: collapse;
  width: 100%;
}

.table-skeleton th,
.table-skeleton td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table-skeleton th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.table-skeleton tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
.textOver3 {
  width: 250px;

  overflow: hidden;
  display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
}
.ant-table-wrapper .ant-table-thead >tr>th {
  // background: #61bbed40;
  // border-bottom: 1px solid ${() => themeColor()};
}
.border-bottom {
  border-color: ${() => themeColor()} !important;
}
.ant-table-wrapper .ant-table-thead {

.ant-table-row-expand-icon-cell {
  // background: #61bbed40 !important;
    // border-bottom: 1px solid ${() => themeColor()};
}
}
.ant-collapse .ant-collapse-content {
    border-top: 1px solid ${() => themeColor()} !important;
}
.dashboardTitle {
  color: ${(props) => props.theme.colors.dashboard.title} !important;
}
.ant-list-item {
  color: ${(props) => props.theme.colors.tooltipColor} !important;
}
.card-header {
  border-bottom: 1px solid
    ${(props) => props.theme.colors.sidebar.borderBottom} !important;
}
.separator.separator-dashed {
  border-bottom-color: ${(props) =>
    props.theme.colors.sidebar.borderBottom} !important;
}
.card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.dashboard_half_ring {
  .apexcharts-legend {
    display: grid !important;
    top: 220px !important;
    justify-items: center !important;
    justify-content: center !important;
    min-height: 250px !important;
  }
}
#MappingDownload {
  cursor: pointer;
  svg {
    path {
      fill: ${(props) => props.theme.colors.sidebar.logoutButton} !important;
    }
  }
}
.ant-drawer {
  .ant-tabs-nav {
    margin-bottom: 0;
    border-bottom: 1px solid #61bbed;
  }
  .ant-tabs-tab-btn {
    display: flex;
    align-items: center;
  }
  .dot {
    width: 10px;
    height: 10px;
    margin-right: 8px;
    background: #4096ff;
    border-radius: 20px;
  }
  .ant-tabs-tab {
    padding: 0 !important;
  }
  .tab-label-ant {
    background: rgb(97, 187, 237);
    padding: 8px 36px;
    color: white;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    align-items: center;
    font-weight: 500;
    letter-spacing: 1px;
    span {
      margin-right: 5px;
      rect, path {
        fill: #FFF !important;
      }
    }
  }
  

  #grid-action-menu {
    position: absolute;
    right: 3px;
    top: 5px;
    .grid-delete {

    }
    .grid-close {
      svg {
        path {
          fill: #ff0000a1 !important;
        }
      }
    }
    .grid-right {
      svg {
        path {
          fill: #008000a1 !important;
        }
      }
    }
  }
  .grid-list {
    .ant-radio-group {
      width: 100%;
    }
    overflow: auto;
    height: calc(100% - 50px);
    padding: 0px 10px;
    .selectedCard {
      border: 1px dashed rgb(97, 187, 237) !important;
      // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
    .grid {
      .grid-title {
        color: ${(props) => props.theme.colors.tableGroupColumns} !important; 
      }
      .avtar-grid {
        width: 40px;
        text-transform: uppercase;
        height: 40px;
        
        background: rgb(97, 187, 237);
        border-radius: 7px;
        margin-right: 10px;
        padding: 11px 15px;
        font-weight: 700;
        color: #FFF;
      }
      .title_update_grid {

      }
      position: relative;
      display: flex;
      border: 1px dashed #00000000;
      margin-bottom: 15px;
      align-items: center;
      background: ${(props) => props.theme.colors.gridBack};
      padding: 15px;
      border-radius: 10px;
      box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px;
      
    }
  }
}
.gridSelect {
  display: flex;
    align-items: center;
    justify-content: space-between;
}

.dashboard-collapse {
  border: 1px solid transparent;
  background-color: rgb(39 51 61 / 27%) !important;
}
.card-footer {
 
  border-top: 1px solid ${(props) =>
    props.theme.colors.sidebar.borderBottom} !important;
}


@media only screen (max-width: 1912px) {
  .responsiveFix {
    background-color: red !important;
  }
}
.fix-over {
  position: relative;
  z-index: 1;
}
.badge-light-info, .text-info {
  color: #e6542a !important; 
}
.border-bottom {
  border-color: rgb(172 172 172) !important;
}
.header-fix {
  position: sticky;
  top: 0px;
  z-index: 9;
  background: rgb(245 245 249);
  backdrop-filter: blur(31px);
  padding: 10px 10px !important;
  border-radius: 9px;
}
.cogs-modal {
  .ant-input-affix-wrapper-lg {
    padding: 7.5px 11px !important; 
  }
  .ant-modal-close {
    top: 32px  !important;
  }
}
.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

export default AppStyleWrapper;

import React, { useEffect, useState } from "react";
import { Modal, Button, Table, message, Input } from "antd";
import { MakeApiCall } from "../../../../apis";
import { DateFormat, DefaultPerPage } from "../../../../config";
import { TableLoading } from "../../../../components/table-animation";
import ASINTable from "../../../../components/table";
import NoData from "../../../../components/no-data";
import Pagination from "../../../../components/pagination";
import moment from "moment";
const { Search } = Input;
const COGSModal = ({
  visible,
  onClose,
  marketplaceSelected,
  selectedRow,
  selectedSign,
  updateList,
  UpdateCOGS,
}) => {
  const columns = [
    {
      title: "SKU",
      dataIndex: "sku",
      key: "sku",
    },
    {
      title: "Inventory",
      dataIndex: "inventory",
      key: "inventory",
    },
    {
      title: "Units Sold",
      dataIndex: "unit_sold",
      key: "unit_sold",
    },
    {
      title: "COGS",
      dataIndex: "cogs",
      key: "cogs",
      render: (e) => {
        return (
          <span>
            {sign}
            {e}
          </span>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (e) => {
        return moment(e, "YYYY-MM-DD").format(DateFormat);
      },
    },
  ];

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [sign, setSign] = useState("");
  const [cogsNew, setCogsNew] = useState("");

  const getHistory = async (data) => {
    setLoading(true);
    setList([]);
    const response = await MakeApiCall(
      `asin-history?pageSize=${data?.pageSize || pageSize || 10}&page=${
        data?.page || page || 1
      }&asin=${data?.asin || ""}&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&currency_code=${selectedSign || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSign(response?.data?.currency || "");
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.error(response?.message || "");
      setTotalPage(0);
      setList([]);
      setLoading(false);
    }
  };

  useEffect(() => {
    getHistory({
      asin: selectedRow?.asin1,
      sales_channel: marketplaceSelected?.sales_channel,
    });
    return () => {};
  }, [visible, updateList]);

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);
    getHistory({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    getHistory({
      page: 1,
      perPage: e,
    });
  };
  const extraElement = (
    <div>
      {/* Your extra element content goes here */}
      <Search
        placeholder="Add new Landed Cost"
        allowClear={false}
        enterButton="Add"
        className="w-275px"
        value={cogsNew}
        size="large"
        prefix={sign}
        onChange={(e) => {
          const value = e.target.value.replace(/[^0-9.]/g, "");
          setCogsNew(value.replace(/^0+([1-9]+)/, "$1")); // Remove leading zeros
        }}
        onSearch={(e) => {
          if (!isNaN(cogsNew) && cogsNew !== "") {
            UpdateCOGS({
              asin: selectedRow?.asin1,
              cogs: cogsNew,
              sales_channel: marketplaceSelected?.sales_channel,
            });
            setCogsNew("");
          } else {
            message.error("Please enter a valid number");
          }
        }}
      />
    </div>
  );
  return (
    <Modal
      // title="COGS History"
      open={visible}
      onCancel={onClose}
      className="cogs-modal"
      width={700}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      title={
        <div
          className="d-flex align-items-center justify-content-between "
          style={{ paddingRight: "25px" }}
        >
          <span>Landed Cost History</span>
          {extraElement}
        </div>
      }
    >
      <div className="card-body">
        <div className="table-responsive">
          {loading ? (
            <TableLoading
              id="test-table"
              columns={[1, 2, 3, 4, 5]}
              checkBoxVal={true}
              actions={[1, 2]}
            />
          ) : list?.length !== 0 ? (
            <ASINTable
              dataSource={list}
              columns={columns}
              loading={loading}
              scroll={{ x: "max-content" }}
              pagination={false}
            />
          ) : (
            <NoData />
          )}
        </div>
        <Pagination
          loading={loading || list?.length === 0}
          pageSize={pageSize}
          page={page}
          totalPage={totalPage}
          onPerPage={onPerPage}
          onPageNo={onPageNo}
        />
      </div>
    </Modal>
  );
};

export default COGSModal;

import { InputNumber, Table, Tag, message } from "antd";
import React from "react";
const type = ["Units Sold", "Gross Revenue", "Net Profit", "Net Margin"];
const data = [
  {
    key: "1",
    productType: "Socks",
    goal: 100000,
    yearToDate: 75000,
  },
  {
    key: "2",
    productType: "Sleep Masks",
    goal: 150000,
    yearToDate: 120000,
  },
  {
    key: "3",
    productType: "Dish Towels",
    goal: 80000,
    yearToDate: 60000,
  },
  {
    key: "3",
    productType: "Aprons",
    goal: 80000,
    yearToDate: 60000,
  },
];

const ProductType = (props) => {
  const { listLoading, list, setList, filter, UpdateCategoryGoalsAction } =
    props;

  const onGoalsChange = (type, value, rowType) => {
    const { category_name } = type;

    setList((prev) =>
      prev?.map((d) => {
        if (d?.category_name === category_name) {
          return { ...d, goals_data: { ...d?.goals_data, [rowType]: value } };
        }
        return { ...d };
      })
    );
  };

  const fireUpdateGoal = (row, type, value) => {
    const { filter_year, sales_channel, region } = filter;
    const obj = {
      year: filter_year,
      sales_channel: sales_channel,
      region: region,
      category_name: row?.category_name,
      type: type,
      goal_value: row?.goals_data?.[type],
    };
    message.destroy();
    message.loading("Table updating", 0);
    UpdateCategoryGoalsAction(obj);
  };
  const columns = [
    {
      title: "Product Type",
      dataIndex: "category_name",
      key: "category_name",
      align: "center",
      render: (text) => {
        return (
          <Tag
            color="#6771dc"
            bordered={false}
            className="px-2 py-1"
            style={{ fontSize: "15px", fontWeight: 700 }}
          >
            {text || "-"}
          </Tag>
        );
      },
    },
    {
      title: "",
      align: "center",
      render: (text) => (
        <div
          style={{
            display: "grid",
            justifyItems: "start",
          }}
        >
          {type?.map((d, i) => {
            return (
              <span className="table_g mb-0 mt-2">
                <b key={i}>{d}</b>
              </span>
            );
          })}
        </div>
      ),
    },
    {
      title: "Goal",
      align: "center",
      render: (text) => {
        return (
          <div
            style={{
              display: "grid",
              justifyItems: "start",
            }}
          >
            <span className="table_g mb-0 mt-2">
              <InputNumber
                style={{ width: "180px" }}
                className="mb-2"
                onPressEnter={(e) => {
                  fireUpdateGoal(text, "units_sold", e);
                }}
                value={text?.goals_data?.units_sold || 0}
                onChange={(e) => onGoalsChange(text, e, "units_sold")}
              />
            </span>
            <span className="table_g mb-0 mt-2">
              <InputNumber
                style={{ width: "180px" }}
                className="mb-2"
                onPressEnter={(e) => {
                  fireUpdateGoal(text, "gross_revenue", e);
                }}
                onChange={(e) => onGoalsChange(text, e, "gross_revenue")}
                value={parseFloat(text?.goals_data?.gross_revenue || 0).toFixed(
                  2
                )}
                addonBefore="$"
              />
            </span>
            <span className="table_g mb-0 mt-2">
              <InputNumber
                style={{ width: "180px" }}
                className="mb-2"
                onPressEnter={(e) => {
                  fireUpdateGoal(text, "net_profit", e);
                }}
                onChange={(e) => onGoalsChange(text, e, "net_profit")}
                value={parseFloat(text?.goals_data?.net_profit || 0).toFixed(2)}
                addonBefore="$"
              />
            </span>
            <span className="table_g mb-0 mt-2">
              <InputNumber
                style={{ width: "180px" }}
                className="mb-2"
                onPressEnter={(e) => {
                  fireUpdateGoal(text, "net_margin", e);
                }}
                onChange={(e) => onGoalsChange(text, e, "net_margin")}
                value={parseFloat(text?.goals_data?.net_margin || 0).toFixed(2)}
                addonAfter="%"
              />
            </span>
          </div>
        );
      },
    },
    {
      title: "Year-To-Date",

      align: "center",
      render: (text) => {
        return (
          <div
            style={{
              display: "grid",
              justifyItems: "start",
            }}
          >
            <span className="table_g mb-0 mt-2">{text?.units_sold || "0"}</span>
            <span className="table_g mb-0 mt-2">
              ${parseFloat(text?.gross_revenue || "0").toFixed(2)}
            </span>
            <span className="table_g mb-0 mt-2">
              ${parseFloat(text?.net_profit || "0").toFixed(2)}
            </span>
            <span className="table_g mb-0 mt-2">
              {parseFloat(text?.net_margin || 0).toFixed(1)}%
            </span>
          </div>
        );
      },
    },
    {
      title: "% Achieved",
      align: "center",
      render: (text, record) => {
        return (
          <div
            style={{
              display: "grid",
              justifyItems: "start",
            }}
          >
            <span className="table_g mb-0 mt-2">
              {parseInt(text?.goals_data?.units_sold || 0) === 0
                ? 0
                : parseFloat(
                    (parseFloat(text?.units_sold || 0) /
                      parseFloat(text?.goals_data?.units_sold || 0)) *
                      100 || 0
                  ).toFixed(2)}
              %
            </span>
            <span className="table_g mb-0 mt-2">
              {parseInt(text?.goals_data?.gross_revenue || 0) === 0
                ? 0
                : parseFloat(
                    (parseFloat(text?.gross_revenue || 0) /
                      parseFloat(text?.goals_data?.gross_revenue || 0)) *
                      100 || "0"
                  ).toFixed(2)}
              %
            </span>
            <span className="table_g mb-0 mt-2">
              {parseInt(text?.goals_data?.net_profit || 0) === 0
                ? 0
                : parseFloat(
                    (parseFloat(text?.net_profit || 0) /
                      parseFloat(text?.goals_data?.net_profit || 0)) *
                      100 || "0"
                  ).toFixed(2)}
              %
            </span>
            <span className="table_g mb-0 mt-2">
              {parseInt(text?.goals_data?.net_margin || 0) === 0
                ? 0
                : parseFloat(
                    (parseFloat(text?.net_margin || 0) /
                      parseFloat(text?.goals_data?.net_margin || 0)) *
                      100 || 0
                  ).toFixed(1)}
              %
            </span>
          </div>
        );
      },
    },
  ];
  return (
    <div className="card mt-5">
      <div className="card-header">
        <div className="card-title title_h">Product Type</div>
        <div className="card-toolbar"></div>
      </div>
      <div className="card-body">
        <Table
          dataSource={list || []}
          loading={listLoading}
          columns={columns}
          pagination
          scroll={{ x: "max-content" }}
        />
      </div>
    </div>
  );
};

export default ProductType;

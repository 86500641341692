import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { Wrapper } from "./style";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Select,
  Table,
  Tabs,
  Tag,
  Tooltip,
  message,
} from "antd";
import { DateFormat, numberformat } from "../../../config";
import Icons from "../../../components/icons";
import { MakeApiCall } from "../../../apis";
import { status } from "nprogress";

const { TabPane } = Tabs;
const { Option } = Select;
export const TableDrawer = ({
  columns,
  setColumns,
  visible,
  setVisible,
  configSubmitLoading,
  UpdateConfigList,
  GetConfigList,
}) => {
  // Function to handle drawer close
  const handleClose = () => {
    GetConfigList();
    setVisible(false);
  };

  const applyChanges = () => {
    UpdateConfigList(columns);
  };

  const handleColumnSelection = (key) => {
    const updatedColumns = columns.map((column) => {
      if (column.title === key) {
        return { ...column, status: !column.status };
      }
      return column;
    });
    setColumns(updatedColumns);
  };

  return (
    <Drawer
      title="Configure"
      placement="right"
      closable={false}
      onClose={handleClose}
      visible={visible}
      footer={[
        <Button
          loading={configSubmitLoading}
          type="primary"
          onClick={applyChanges}
        >
          Apply Changes
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          transform: "scale(1.1)",
          rowGap: "10px",
          width: "fit-content",
          padding: "20px 0px",
          marginTop: "20px",
        }}
      >
        {console.log(
          columns?.filter((d) => d?.status)?.length,
          columns?.length,
          "columns"
        )}
        <Checkbox
          indeterminate={false}
          checked={
            columns?.filter((d) => d?.status)?.length === columns?.length
          }
          onChange={(e) => {
            setColumns((prev) =>
              prev?.map((d) => ({ ...d, status: e.target.checked }))
            );
          }}
        >
          <b>
            {columns?.filter((d) => d?.status)?.length === columns?.length
              ? "Deselect All"
              : "Select All"}
          </b>
        </Checkbox>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          transform: "scale(1.1)",
          rowGap: "10px",
          width: "fit-content",
          marginLeft: "8px",
          padding: "0px 0px 20px",
          marginTop: "20px",
        }}
      >
        {columns.map((column, i) => (
          <Checkbox
            key={i}
            checked={column.status}
            style={{ marginInlineStart: 0 }}
            onChange={() => handleColumnSelection(column.title)}
          >
            {column.title}
          </Checkbox>
        ))}
      </div>
    </Drawer>
  );
};
const PLBreakdown = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const [profitLossList, setProfitLossList] = useState([]);
  const [profitLossLoading, setProfitLossLoading] = useState(true);
  const [sign, setSign] = useState("");
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });
  const [productFilter, setProductFilter] = useState([]);
  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  const [selectedSign, setSelectedSign] = useState("USD");
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyLoading, setCurrencyLoading] = useState(true);

  const [columnsConfig, setColumnsConfig] = useState([]);
  const [configSubmitLoading, setConfigSubmitLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const columns__ = [
    {
      title: "Product",
      render: (e) => {
        return (
          <a
            className="text-dark font-weight-bolder text-hover-primary fs12 sku-click"
            href="javascript:void(0)"
            title="Id Rather Be Golfing - Funny Golf Socks Novelty Christmas Gift Stocking Stuffer For Men, Women and Teens"
            data-pjax={0}
            data-sku="golfsock"
          >
            <div className="d-flex align-items-center">
              <div className="min-w-70px " style={{ marginRight: 10 }}>
                <div className="symbol symbol-50px ">
                  <img src={e?.product_image || "-"} loading="lazy" />
                </div>
              </div>
              <div className="title_asin_dis">
                <div className="d-flex justify-content-start flex-column me-4">
                  <span
                    className="dashboardTitle font-weight-bolder text-hover-primary fs12 "
                    style={{ width: 300, fontWeight: 700, letterSpacing: 1 }}
                  >
                    <Tooltip rule row={2} placement="right">
                      {e?.title || "-"}
                    </Tooltip>
                  </span>
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="text-gray-700 fw-400 d-block fs12 mt-0">
                      <span className="text-gray-800 fw-boldest">SKU:</span>{" "}
                      <Tag color="blue">{e?.sku || "-"}</Tag>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
      },
    },

    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      sorter: (a, b) => a.asin.localeCompare(b.asin),
      render: (e) => {
        return <Tag color="magenta">{e}</Tag>;
      },
    },
    {
      title: "Revenue",
      dataIndex: "order_gross_revenue",
      key: "order_gross_revenue",
      sorter: (a, b) =>
        parseFloat(a.order_gross_revenue) - parseFloat(b.order_gross_revenue),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "Units Sold",
      dataIndex: "order_quantity",
      key: "order_quantity",
      sorter: (a, b) =>
        parseFloat(a.order_quantity) - parseFloat(b.order_quantity),
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      title: "Unit COGS",
      dataIndex: "cogs_per_unit",
      key: "cogs_per_unit",
      sorter: (a, b) =>
        parseFloat(a.cogs_per_unit) - parseFloat(b.cogs_per_unit),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "COGS",
      dataIndex: "order_cogs",
      key: "order_cogs",
      sorter: (a, b) => parseFloat(a.order_cogs) - parseFloat(b.order_cogs),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "Refund Sales",
      dataIndex: "refund_sales",
      hidden: false,
      key: "refund_sales",
      sorter: (a, b) => parseFloat(a.refund_sales) - parseFloat(b.refund_sales),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "Fulfilment Fees",
      dataIndex: "order_amazon_fee",
      key: "order_amazon_fee",
      sorter: (a, b) =>
        parseFloat(a.order_amazon_fee) - parseFloat(b.order_amazon_fee),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },

    {
      title: "Advertising Cost",
      dataIndex: "ad_cost",
      hidden: false,
      key: "ad_cost",
      sorter: (a, b) => parseFloat(a.ad_cost) - parseFloat(b.ad_cost),
      render: (e) => {
        return (
          <span>
            {sign}

            {numberformat(e)}
          </span>
        );
      },
    },

    {
      title: "Expenses",
      dataIndex: "expenses",
      hidden: false,
      key: "expenses",
      sorter: (a, b) => parseFloat(a.expenses) - parseFloat(b.expenses),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },

    {
      title: "Net Profit",
      dataIndex: "net_profit",
      key: "net_profit",
      sorter: (a, b) => parseFloat(a.net_profit) - parseFloat(b.net_profit),
      render: (e) => {
        if (parseFloat(e) === 0) {
          return (
            <Tag bordered={false} color="blue">
              {sign}0
            </Tag>
          );
        }
        if (parseFloat(e) > 0) {
          return (
            <Tag bordered={false} color="green">
              {sign}
              {numberformat(e)}
            </Tag>
          );
        }
        if (parseFloat(e) < 0) {
          return (
            <Tag bordered={false} color="red">
              {`${sign}${numberformat(e)}`?.replace(`${sign}-`, `-${sign}`)}
            </Tag>
          );
        }
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "Net Margin",
      dataIndex: "net_margin",
      key: "net_margin",

      render: (e) => {
        return <span>{parseFloat(e || 0).toLocaleString()}%</span>;
      },
    },
    {
      title: "ROI",
      dataIndex: "roi",

      key: "roi",
      render: (e) => {
        return <span>{parseFloat(e || 0).toLocaleString()}%</span>;
      },
    },
  ];

  const columns___ = [
    {
      title: "Product",
      key: "title",
      dataIndex: "title",
      hidden: false,
      render: (_, e) => {
        return (
          <a
            className="text-dark font-weight-bolder text-hover-primary fs12 sku-click"
            href="javascript:void(0)"
            title="Id Rather Be Golfing - Funny Golf Socks Novelty Christmas Gift Stocking Stuffer For Men, Women and Teens"
            data-pjax={0}
            data-sku="golfsock"
          >
            <div className="d-flex align-items-center">
              <div className="min-w-70px " style={{ marginRight: 10 }}>
                <div className="symbol symbol-50px ">
                  <img src={e?.product_image || "-"} loading="lazy" />
                </div>
              </div>
              <div className="title_asin_dis">
                <div className="d-flex justify-content-start flex-column me-4">
                  <span
                    className="dashboardTitle font-weight-bolder text-hover-primary fs12 "
                    style={{ width: 300, fontWeight: 700, letterSpacing: 1 }}
                  >
                    <Tooltip rule row={2} placement="right">
                      {e?.title || "-"}
                    </Tooltip>
                  </span>
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="text-gray-700 fw-400 d-block fs12 mt-0">
                      <span className="text-gray-800 fw-boldest">SKU:</span>{" "}
                      <Tag color="blue">{e?.sku || "-"}</Tag>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      hidden: false,
      sorter: (a, b) => a.asin.localeCompare(b.asin),
      render: (e) => {
        return <Tag color="magenta">{e}</Tag>;
      },
    },
    {
      hidden: false,
      title: "Revenue",
      dataIndex: "order_gross_revenue",
      key: "order_gross_revenue",
      sorter: (a, b) =>
        parseFloat(a.order_gross_revenue) - parseFloat(b.order_gross_revenue),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "Units Sold",
      hidden: false,
      dataIndex: "order_quantity",
      key: "order_quantity",
      sorter: (a, b) =>
        parseFloat(a.order_quantity) - parseFloat(b.order_quantity),
      render: (e) => {
        return <span>{numberformat(e)}</span>;
      },
    },
    {
      hidden: false,
      title: "Unit COGS",
      dataIndex: "cogs_per_unit",
      key: "cogs_per_unit",
      sorter: (a, b) =>
        parseFloat(a.cogs_per_unit) - parseFloat(b.cogs_per_unit),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "COGS",
      dataIndex: "order_cogs",
      hidden: false,
      key: "order_cogs",
      sorter: (a, b) => parseFloat(a.order_cogs) - parseFloat(b.order_cogs),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "Refund Sales",
      dataIndex: "refund_sales",
      hidden: false,
      key: "refund_sales",
      sorter: (a, b) => parseFloat(a.refund_sales) - parseFloat(b.refund_sales),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      hidden: false,
      title: "Fulfilment Fees",
      dataIndex: "order_amazon_fee",
      key: "order_amazon_fee",
      sorter: (a, b) =>
        parseFloat(a.order_amazon_fee) - parseFloat(b.order_amazon_fee),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    // {
    //   title: "Fulfilment Fees %",
    //   dataIndex: "order_amazon_fee_percentage",
    //   key: "order_amazon_fee_percentage",
    //   sorter: (a, b) =>
    //     parseFloat(a.order_amazon_fee_percentage) -
    //     parseFloat(b.order_amazon_fee_percentage),
    //   render: (e) => {
    //     return <span>{numberformat(e)}%</span>;
    //   },
    // },
    {
      title: "Advertising Cost",
      dataIndex: "ad_cost",
      hidden: false,
      key: "ad_cost",
      sorter: (a, b) => parseFloat(a.ad_cost) - parseFloat(b.ad_cost),
      render: (e) => {
        return (
          <span>
            {sign}

            {numberformat(e)}
          </span>
        );
      },
    },

    {
      title: "Expenses",
      dataIndex: "expenses",
      hidden: false,
      key: "expenses",
      sorter: (a, b) => parseFloat(a.expenses) - parseFloat(b.expenses),
      render: (e) => {
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    // {
    //   title: "Advertising %",
    //   dataIndex: "ad_sales_percentage",
    //   sorter: (a, b) =>
    //     parseFloat(a.ad_sales_percentage) - parseFloat(b.ad_sales_percentage),
    //   key: "ad_sales_percentage",
    //   render: (e) => {
    //     return <span>{numberformat(e)}%</span>;
    //   },
    // },
    // {
    //   title: "Refunds",
    //   dataIndex: "refund_sales",
    //   key: "refund_sales",
    //   sorter: (a, b) => parseFloat(a.refund_sales) - parseFloat(b.refund_sales),
    //   render: (e) => {
    //     return (
    //       <span>
    //         {sign}
    //         {numberformat(e)}
    //       </span>
    //     );
    //   },
    // },
    // {
    //   title: "Refunds %",
    //   dataIndex: "refund_percentage",
    //   key: "refund_percentage",
    //   sorter: (a, b) =>
    //     parseFloat(a.refund_percentage) - parseFloat(b.refund_percentage),
    //   render: (e) => {
    //     return <span>{numberformat(e)}%</span>;
    //   },
    // },

    {
      title: "Net Profit",
      hidden: false,
      dataIndex: "net_profit",
      sorter: (a, b) => parseFloat(a.net_profit) - parseFloat(b.net_profit),
      key: "net_profit",
      render: (e) => {
        if (parseFloat(e) === 0) {
          return (
            <Tag bordered={false} color="blue">
              {sign}0
            </Tag>
          );
        }
        if (parseFloat(e) > 0) {
          return (
            <Tag bordered={false} color="green">
              {sign}
              {numberformat(e)}
            </Tag>
          );
        }
        if (parseFloat(e) < 0) {
          return (
            <Tag bordered={false} color="red">
              {`${sign}${numberformat(e)}`?.replace(`${sign}-`, `-${sign}`)}
            </Tag>
          );
        }
        return (
          <span>
            {sign}
            {numberformat(e)}
          </span>
        );
      },
    },
    {
      title: "Net Margin",
      dataIndex: "net_margin",
      key: "net_margin",

      render: (e) => {
        return <span>{parseFloat(e || 0).toLocaleString()}%</span>;
      },
    },
    {
      title: "ROI",
      dataIndex: "roi",

      key: "roi",
      render: (e) => {
        return <span>{parseFloat(e || 0).toLocaleString()}%</span>;
      },
    },
    // {
    //   title: "Net Profit %",
    //   sorter: (a, b) =>
    //     parseFloat(a.net_profit_percentage) -
    //     parseFloat(b.net_profit_percentage),
    //   dataIndex: "net_profit_percentage",
    //   key: "net_profit_percentage",
    //   render: (e) => {
    //     return <span>{numberformat(e)}%</span>;
    //   },
    // },
  ];

  const handleDownload = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl.split("/").pop();
    document.body.appendChild(anchor); // Append to the document
    anchor.click();
    document.body.removeChild(anchor); // Clean up
    message.destroy();
  };

  const GetConfigList = async (data) => {
    const response = await MakeApiCall(
      `get-module-config?module=profit_loss_making`,
      "get",
      null,
      true
    );

    if (response?.status) {
      try {
        setColumnsConfig(JSON.parse(response?.data?.records?.config || "[]"));
      } catch (error) {
        setColumnsConfig([]);
      }
    } else {
      setColumnsConfig([]);
    }
  };

  const UpdateConfigList = async (data) => {
    setConfigSubmitLoading(true);
    const response = await MakeApiCall(
      `update-module-config`,
      "post",
      {
        module: "profit_loss_making",
        config: data,
      },

      true
    );

    if (response?.status) {
      setConfigSubmitLoading(false);
      try {
        setColumnsConfig(JSON.parse(response?.data?.config || "[]"));
      } catch (error) {}
      setDrawerVisible(false);
    } else {
      setConfigSubmitLoading(false);

      GetConfigList();
    }
  };

  const TopProfitLossData = async (data) => {
    if (data?.export) {
      message.destroy();
      message.loading("Loading...", 0);
    } else {
      setProfitLossLoading(true);
    }

    const response = await MakeApiCall(
      `top-profit-loss-products?daterange=${
        data?.start_date || filters?.start_date || ""
      }+-+${data?.end_date || filters?.end_date || ""}&region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&export=${data?.export || ""}&currency_code=${
        data?.currency_code || "USD"
      }`,

      "get",
      null,
      true
    );

    if (response?.status) {
      if (data?.export) {
        message?.destroy();
        return handleDownload(response?.data?.file);
      }
      setSign(response?.data?.currency || "");
      setProfitLossLoading(false);
      setProfitLossList(response?.data || []);
    } else {
      setProfitLossList([]);
      setProfitLossLoading(false);
    }
  };

  const hotCall = async (e) => {
    await TopProfitLossData({
      region: e?.region_name,
      sales_channel: e?.sales_channel,
      currency_code: selectedSign,
    });
  };

  const getAllListCurrency = async (data) => {
    setCurrencyLoading(true);
    const response = await MakeApiCall(`list-currency-data`, "get", null, true);

    if (response?.status) {
      setCurrencyList(
        response?.data?.map((d) => ({ label: d, value: d })) || []
      );
      setCurrencyLoading(false);
    } else {
      setCurrencyLoading(false);
      setCurrencyList([]);
    }
  };
  const MarketplaceList = async () => {
    const response = await MakeApiCall(
      `get-user-marketplace-list`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        const salesList = response?.data?.reduce((acc, e) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region_name);

          if (
            e.region_name === "us-east-1" &&
            e.sales_channel === "Amazon.com"
          ) {
            setMarketplaceSelected({
              marketplace: e?.name,
              marketplace_id: e?.id,
              region: e?.region_name,
              sales_channel: e?.sales_channel,
            });
            hotCall(e);
          }

          if (regionIndex === -1) {
            acc.push({
              label: e.region_name,
              options: [
                {
                  label: e.sales_channel,
                  value: e.id,
                  region: e.region_name,
                  marketplace_id: e?.id,
                  marketplace: e?.name,
                  sales_channel: e?.sales_channel,
                },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: e.sales_channel,
              value: e.id,
              marketplace_id: e?.id,
              region: e.region_name,
              marketplace: e?.name,
              sales_channel: e?.sales_channel,
            });
          }
          return acc;
        }, []);

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList(uniqueList);
        setMarketplaceLoading(false);
      } else {
        setMarketplaceLoading(false);
      }
    } else {
    }
  };

  useEffect(() => {
    MarketplaceList();
    getAllListCurrency();
    GetConfigList();
    return () => {};
  }, []);

  const convertedObject = columnsConfig.reduce((acc, obj) => {
    acc[obj.title] = obj.status;
    return acc;
  }, {});

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "Last Week",
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },

    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    {
      label: "Custom Range",
      value: [0, 0],
    },
  ];

  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <div className="app-container header-fix d-flex flex-stack  mt-5">
          <div className="page-title d-flex flex-wrap me-3 flex-column justify-content-center">
            <Tooltip
              title={
                isOpenDropdown ? (
                  "Working on"
                ) : (
                  <div>
                    {profitLossList?.[
                      selectedTab === "1" ? "top_profit" : "top_loss"
                    ]?.filter((r) => productFilter?.includes(r?.asin))
                      ?.length === 0
                      ? "No Products Selected"
                      : profitLossList?.[
                          selectedTab === "1" ? "top_profit" : "top_loss"
                        ]
                          ?.filter((r) => productFilter?.includes(r?.asin))
                          ?.map((d, _, row) => (
                            <div
                              style={{
                                borderBottom:
                                  row?.length === _ + 1
                                    ? "none"
                                    : "1px solid grey",
                                marginBottom:
                                  row?.length === _ + 1 ? "0px" : "10px",
                                marginTop: "10px",
                                paddingBottom: "10px",
                              }}
                              className="d-flex"
                            >
                              <img
                                style={{
                                  width: "33px",
                                  marginRight: "10px",
                                  borderRadius: "7px",
                                }}
                                src={d?.product_image}
                              />
                              <div className="ellipsis">{d?.title}</div>
                            </div>
                          ))}
                  </div>
                )
              }
              placement="bottom"
            >
              <Select
                placeholder="Select Product"
                className="w-250px"
                mode="multiple"
                onDropdownVisibleChange={(e) => setIsOpenDropdown(e)}
                maxTagCount={"responsive"}
                getPopupContainer={(target) => target.parentNode}
                size="large"
                dropdownStyle={{
                  minWidth: "500px",
                }}
                onChange={(e) => {
                  setProductFilter(e);
                }}
                value={productFilter}
              >
                {profitLossList?.[
                  selectedTab === "1" ? "top_profit" : "top_loss"
                ]?.map((d, i) => (
                  <Option key={i} value={d?.asin}>
                    <img
                      style={{
                        width: "33px",
                        marginRight: "10px",
                        borderRadius: "7px",
                      }}
                      src={d?.product_image}
                    />
                    <b>{d?.title}</b>
                  </Option>
                ))}
              </Select>
            </Tooltip>
          </div>
          <div className="d-flex flex-wrap align-items-center gap-2 gap-lg-3 fix-over">
            <Button
              type="primary"
              size="large"
              prefix={<Icons type="export" />}
              onClick={() => {
                TopProfitLossData({
                  ...marketplaceSelected,
                  ...filters,
                  currency_code: selectedSign,
                  export: selectedTab === "1" ? "top_profit" : "top_loss",
                });
                // GetSalesBreak({
                //   export: true,
                // });
              }}
            >
              Export
            </Button>

            <DatePicker.RangePicker
              getPopupContainer={(target) => target.parentNode}
              className="w-250px"
              size="large"
              format={DateFormat}
              allowClear={false}
              presets={rangePresets}
              value={
                filters?.start_date && filters?.end_date
                  ? [
                      dayjs(filters?.start_date, "YYYY-MM-DD"),
                      dayjs(filters?.end_date, "YYYY-MM-DD"),
                    ]
                  : []
              }
              onChange={(e) => {
                const obj = {
                  start_date: e?.[0]
                    ? dayjs(e?.[0]).format("YYYY-MM-DD")
                    : null,
                  end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
                };
                TopProfitLossData({ ...obj, currency_code: selectedSign });
                setFilters(obj);
              }}
            />
            <Select
              placeholder="Select Currency"
              className="w-100px"
              size="large"
              getPopupContainer={(target) => target.parentNode}
              options={currencyList}
              loading={currencyLoading}
              value={selectedSign}
              onChange={(e) => {
                TopProfitLossData({
                  ...marketplaceSelected,
                  ...filters,
                  currency_code: e,
                });
                setSelectedSign(e);
              }}
            />
            <Select
              placeholder="Select Marketplace"
              style={{ width: "200px" }}
              size="large"
              getPopupContainer={(target) => target.parentNode}
              loading={marketplaceLoading}
              value={marketplaceSelected?.marketplace_id}
              onChange={(e, _) => {
                const obj = {
                  marketplace: _?.marketplace,
                  marketplace_id: _?.marketplace_id,
                  region: _?.region,
                  sales_channel: _?.sales_channel,
                };
                TopProfitLossData({ ...obj, currency_code: selectedSign });
                setMarketplaceSelected(obj);
              }}
              options={marketplaceList}
            />
          </div>
        </div>
        <div className="row px-3 mt-3">
          <div className="card">
            <div className="card-body pt-2 pb-2">
              <Tabs
                tabBarExtraContent={{
                  right: (
                    <span onClick={() => setDrawerVisible(true)}>
                      <Icons type="setting" />
                    </span>
                  ),
                }}
                onChange={(e) => {
                  setSelectedTab(e);
                  setProductFilter([]);
                }}
                value={selectedTab}
              >
                <TabPane tab="Products Making Profit" key="1">
                  <Table
                    scroll={{ x: "max-content" }}
                    dataSource={
                      productFilter?.length === 0
                        ? profitLossList?.top_profit
                        : profitLossList?.top_profit?.filter((d) =>
                            productFilter?.includes(d?.asin)
                          )
                    }
                    loading={profitLossLoading}
                    columns={columns__?.filter(
                      (d) => convertedObject?.[d?.title]
                    )}
                    pagination={false}
                  />
                </TabPane>

                <TabPane tab="Products Making Loss" key="2">
                  <Table
                    scroll={{ x: "max-content" }}
                    dataSource={
                      productFilter?.length === 0
                        ? profitLossList?.top_loss
                        : profitLossList?.top_loss?.filter((d) =>
                            productFilter?.includes(d?.asin)
                          )
                    }
                    loading={profitLossLoading}
                    columns={columns___?.filter(
                      (d) => convertedObject?.[d?.title]
                    )}
                    pagination={false}
                  />
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
      <TableDrawer
        columns={columnsConfig}
        setColumns={setColumnsConfig}
        UpdateConfigList={UpdateConfigList}
        configSubmitLoading={configSubmitLoading}
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        GetConfigList={GetConfigList}
      />
    </Wrapper>
  );
};

export default PLBreakdown;

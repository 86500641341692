import React from "react";
import AdSummary_ from "../../containers/ad-summary";
import Goals_ from "../../containers/goals";
import COGSMapping from "../../modules/pages/cogs-mapping";
import Dashboard from "../../modules/pages/new-dashboard";
import profit from "../../modules/pages/profit";
import SPAPI_ from "../../containers/setting/spapi";
import MySubscriptions from "../../modules/pages/my-subscriptions";
import Inventory from "../../modules/pages/inventory";
import MySubscriptions_ from "../../containers/my-subscriptions";
import ManageSubscriptions_ from "../../containers/admin/manage-subscriptions";
import AppConfig from "../../modules/pages/admin/setting/app-configurations";
import PLBreakdown from "../../modules/pages/p&l-breakdown";
const SalesAnalyticsAsin_ = React.lazy(() =>
  import("../../containers/sales-analytics")
);
const Dashboard_ = React.lazy(() => import("../../modules/pages/dashboard"));
const User_ = React.lazy(() => import("../../containers/admin/user"));
// const BySku_ = React.lazy(() =>
//   import("../../containers/sales-analytics/by-sku")
// );
// const ByBrand_ = React.lazy(() =>
//   import("../../containers/sales-analytics/by-brand")
// );
// const ByVariationAsin_ = React.lazy(() =>
//   import("../../containers/sales-analytics/by-variation-asin")
// );
const ProductAsReport_ = React.lazy(() =>
  import("../../containers/advertising-reports/product-ads-report")
);
const FinanceApi_ = React.lazy(() => import("../../containers/finance-api"));
const MomYoyComparison_ = React.lazy(() =>
  import("../../containers/mom-&-yoy-comparison")
);
const Mapping_ = React.lazy(() => import("../../containers/mapping"));
const ManageInventory_ = React.lazy(() =>
  import("../../containers/manage-inventory")
);
const ReservedInventory_ = React.lazy(() =>
  import("../../containers/reserved-inventory")
);
const Profile_ = React.lazy(() => import("../../containers/setting/profile"));
const CurrencyConversion_ = React.lazy(() =>
  import("../../containers/setting/currency-conversion")
);
const AmazonCredentials_ = React.lazy(() =>
  import("../../containers/setting/amazon-credentials")
);
const CronLog_ = React.lazy(() =>
  import("../../containers/application-logs/cron-log")
);
const SystemEventsLog_ = React.lazy(() =>
  import("../../containers/application-logs/system-events-log")
);

export const userRoutersList = [
  {
    path: "/",
    key: "dashboard",
    name: Dashboard,
  },
  {
    path: "/dashboard",
    key: "dashboard",
    name: Dashboard,
  },
  {
    path: "/profit-&-loss-analytics",
    key: "profit-analytics",
    name: profit,
  },
  {
    path: "/p&l-breakdown",
    key: "profit-analytics",
    name: PLBreakdown,
  },
  // {
  //   path: "/sales-analytics/by-sku",
  //   key: "sales-analytics",
  //   name: BySku_,
  // },
  {
    path: "/sales-analytics",
    key: "sales-analytics",
    name: SalesAnalyticsAsin_,
  },
  {
    path: "/ad-summary",
    key: "ad-summary",
    name: AdSummary_,
  },
  {
    path: "/landed-costs",
    key: "landed-costs",
    name: COGSMapping,
  },
  {
    path: "/my-subscriptions",
    key: "my-subscriptions",
    name: MySubscriptions_,
  },
  // {
  //   path: "/sales-analytics/by-brand",
  //   key: "sales-analytics",
  //   name: ByBrand_,
  // },
  // {
  //   path: "/sales-analytics/by-variation-asin",
  //   key: "sales-analytics",
  //   name: ByVariationAsin_,
  // },
  {
    path: "/advertising-reports/product-ads-report",
    key: "advertising-reports",
    name: ProductAsReport_,
  },
  {
    path: "/finance-api",
    key: "finance-api",
    name: FinanceApi_,
  },
  {
    path: "/mom-&-yoy-comparison",
    key: "mom-&-yoy-comparison",
    name: MomYoyComparison_,
  },
  {
    path: "/mapping",
    key: "mapping",
    name: Mapping_,
  },
  {
    path: "/manage-inventory",
    key: "manage-inventory",
    name: ManageInventory_,
  },
  {
    path: "/reserved-inventory",
    key: "reserved-inventory",
    name: ReservedInventory_,
  },
  {
    path: "/setting/profile",
    key: "setting",
    name: Profile_,
  },
  {
    path: "/setting/currency-conversion",
    key: "setting",
    name: CurrencyConversion_,
  },
  {
    path: "/setting/amazon-credentials",
    key: "setting",
    name: AmazonCredentials_,
  },
  {
    path: "/callback_ads",
    key: "setting",
    name: AmazonCredentials_,
  },
  {
    path: "/callback_ads",
    key: "setting",
    name: AmazonCredentials_,
  },

  {
    path: "/setting/sp-api",
    key: "setting",
    name: SPAPI_,
  },
  {
    path: "/callback_sp",
    key: "setting",
    name: SPAPI_,
  },
  {
    path: "/application-logs/central-log",
    key: "application-logs",
    name: CronLog_,
  },
  {
    path: "/application-logs/system-events-log",
    key: "application-logs",
    name: SystemEventsLog_,
  },
  {
    path: "/inventory",
    key: "inventory",
    name: Inventory,
  },
  {
    path: "/goals",
    key: "goals",
    name: Goals_,
  },
];

export const adminRoutersList = [
  // {
  //   path: "/",
  //   key: "dashboard",
  //   name: Dashboard_,
  // },
  // {
  //   path: "/dashboard",
  //   key: "dashboard",
  //   name: Dashboard_,
  // },
  {
    path: "/manage-seller-users",
    key: "manage-seller-users",
    name: User_,
  },
  {
    path: "/manage-subscriptions",
    key: "manage-seller-users",
    name: ManageSubscriptions_,
  },
  {
    path: "/setting/app-configurations",
    key: "manage-seller-users",
    name: AppConfig,
  },
];

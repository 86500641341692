import styled from "styled-components";

const Wrapper = styled.div`
  .title_h {
    font-weight: bold !important;

    color: ${(props) => props.theme.colors.dashboard.titleH} !important;
  }
  .table_g {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
    border-bottom: 1px dashed #3b4146b0;
    width: 100%;
    padding: 7px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-table-row {
    .ant-table-cell {
      padding: 16px 0px !important;
    }
    .ant-table-cell:first-child {
      padding: 16px 16px !important;
    }
  }
  .ant-collapse-header {
    display: flex !important;
    align-items: center !important;
    .ant-collapse-expand-icon {
      position: relative;
      left: -10px;
    }
  }

  .ant-collapse-header {
    border-bottom: 1px solid
      ${(props) => props.theme.colors.sidebar.borderBottom} !important;
    font-size: 1.275rem !important;
    background-color: ${(props) => props.theme.colors.body} !important;
  }
  .ant-collapse-content {
    border-top: none !important;
  }
`;

export default Wrapper;

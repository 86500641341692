import axiosCall from "../../../configurations/network-services/axiosCall";

export const getUserAction = (data) => {
  const path = `users?page=${data?.page || 1}&per-page=${data?.perPage || 10}`;
  const responseType = "USER_GET";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const switchUserAction = (id) => {
  const path = `switch-user/${id}`;
  const responseType = "SWITCH_USER";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const addUserAction = (data) => {
  const path = `user`;
  const responseType = "ADD_USER";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const updateUserAction = (id, data) => {
  let FormData_ = new FormData();
  FormData_.append("u_name", data?.u_name || "");
  FormData_.append("u_email", data?.u_email || "");
  FormData_.append("u_address", data?.u_address || "");
  FormData_.append("u_contact_no", data?.u_contact_no || "");
  if(data?.u_photo || data?.u_photo === 'remove') {
    FormData_.append("u_photo", data?.u_photo === 'remove' ? '' : data?.u_photo || "");
  }
  const path = `user-update/${id}`;
  const responseType = "UPDATE_USER";
  return axiosCall("post", path, responseType, FormData_, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const deleteUserAction = (id) => {
  const path = `user-delete/${id}`;
  const responseType = "DELETE_USER";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionUser = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_ADMIN_USER", state: data });
};

import axiosCall from "../../configurations/network-services/axiosCall";

export const dashboardAction = (data) => {
  const path = `user-dashboard?region=${data?.region || ""}&sales_channel=${
    data?.sales_channel || ""
  }&daterange=${data?.daterange || ""}&sort_type=${data?.sort_type || ""}`;
  const responseType = "DASHBOARD_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const AdvertisingSummeryAction = (data) => {
  const path = `advertising-summery?region=${
    data?.ad_sort_type === "marketplace" ? "" : data?.region || ""
  }&sales_channel=${
    data?.ad_sort_type === "marketplace" ? "" : data?.sales_channel || ""
  }&daterange=${data?.daterange || ""}&ad_sort_type=${
    data?.ad_sort_type || ""
  }`;
  const responseType = "ADVERTISING_SUMMERY_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const TilesDataAction = (data) => {
  const path = `tiles-data?daterange=${data?.daterange || ""}&region=${
    data?.region || ""
  }&sales_channel=${data?.sales_channel || ""}`;
  const responseType = "TILES_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const listCurrencyDataAction = () => {
  const path = `list-currency-data`;
  const responseType = "LIST_CURRENCY_DATA";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const productImageAction = () => {
  const path = `product-image`;
  const responseType = "PRODUCT_IMAGE_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const salesChannelListAction = () => {
  const path = `sales-channel-list`;
  const responseType = "SALES_CHANNEL_LIST";

  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const fakeActionDashboard = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_DASHBOARD", state: data });
};

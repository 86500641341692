import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ManageSubscriptions from "../../../modules/pages/admin/manage-subscriptions";
import {} from "../../../redux/modules/my-subscriptions/index.action";

const mapStateToProps = (state) => ({
  StripePlanListResponse: state.MySubscriptions.StripePlanListResponse,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const ManageSubscriptions_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSubscriptions);

export default ManageSubscriptions_;

import styled from "styled-components";
import { themeColor } from "../../../config";

const Wrapper = styled.div`
  border: none;

  background: rgba(255, 255, 255, 0.95) !important;
  border-radius: 0.375rem;
  box-shadow: 0 0 0.375rem 0.25rem rgba(161, 172, 184, 0.15);
  backdrop-filter: saturate(200%) blur(6px);
  min-height: 70px;
  max-height: 70px;
  margin-right: 20px;
  position: sticky;
  top: 0;
  z-index: 999;
  ${"" /* box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px; */}
  ${
    "" /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  }
  margin: 0px 10px 0px;

  .ant-breadcrumb-separator {
    position: relative;
    top: -1px;
    font-size: 1.5rem !important;
    margin-inline: 5px !important;
    font-family: Roboto !important;
  }
  .page-title {
    .ant-breadcrumb {
      margin-top: 5px;
    }
  }
  .container-fluid {
    padding: 0 30px !important;
  }
  .ant-breadcrumb-separator {
    position: relative;
    top: -1px;
    font-size: 1.5rem !important;
    margin-inline: 5px !important;
  }
  container-fluid-sm {
    padding: 0 0px !important;
  }
  .arrow {
    background: white;
    width: 35px;
    height: 35px;
    border-radius: 10px;
    color: ${() => themeColor()};
    padding-left: 6px;
    box-shadow: 0px 0px 10px #d2d2d2;
    position: absolute;
    left: -16px;
    padding-top: 6px;
    cursor: pointer;
    z-index: 999;
    svg,
    span {
      cursor: pointer;
    }
  }
  .header-sm {
    display: flex-root !important;
  }
  #btnBackToAdmin {
    background-color: ${() => themeColor()};
    transition-duration: 300ms;
  }
  #btnBackToAdmin:hover {
    background-color: ${() => themeColor("", "hover")} !important;
  }
  .ant-breadcrumb,
  .anticon {
    font-size: 1.3rem;
  }
  .ant-breadcrumb > ol > li {
    display: flex;
  }
  .ant-breadcrumb a:hover {
    color: rgba(0, 0, 0, 0.88) !important;
    background-color: #fff !important;
  }
  // .ant-breadcrumb > ol > li > span > a {
  //   color: #898989bf !important;
  // }

  @media only screen and (max-width: 550px) {
    .ant-breadcrumb {
      display: none;
    }
  }

  @media only screen and (max-width: 550px) {
    .ant-breadcrumb {
      display: none;
    }
  }
`;
export const BacktoAdminWrapper = styled.button`
  width: 130px;
  height: 40px;
  font-size: 1.1em;
  cursor: pointer;
  background-color: #171717;
  color: #fff;
  border: none;
  border-radius: 5px;
  transition: all 0.4s;

  &:hover {
    border-radius: 5px;
    transform: translateY(-10px);
    box-shadow: 0 7px 0 -2px #f85959, 0 15px 0 -4px #39a2db,
      0 16px 10px -3px #39a2db;
  }

  &:active {
    transition: all 0.2s;
    transform: translateY(-5px);
    box-shadow: 0 2px 0 -2px #f85959, 0 8px 0 -4px #39a2db,
      0 12px 10px -3px #39a2db;
  }
`;
export default Wrapper;

export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "BY_BRAND_LIST_SUCCESS":
      case "BY_BRAND_LIST_ERROR":
        return {
          ...state,
          ByBrandResponse: action.updatePayload,
        };
      case "BY_PRODUCT_TYPE_SUCCESS":
      case "BY_PRODUCT_TYPE_ERROR":
        return {
          ...state,
          ByProductDataResponse: action.updatePayload,
        };
      case "FAKE_ACTION_BY_BRAND":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  
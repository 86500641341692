import styled from "styled-components";

const TableWrap = styled.div`
  .tweet {
    background: ${(props) => props.theme.colors.Loading_BG.background};
    padding: 0.8rem 0.75rem;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    border: none;
    border-radius: 0.5rem;
    overflow: hidden;
    .tweet-content {
      margin-left: 0px;
    }
    .tweet-header {
      animation: skeleton 1s ease-in-out forwards infinite;
      animation-direction: alternate;
      transform-origin: bottom;
    }
    .tweet-footer {
      position: relative;
      top: 4px;
      display: flex;
      margin: 11px;
      animation: skeleton 1s ease-in-out forwards infinite;
      animation-direction: alternate;
      transform-origin: bottom;
      animation-delay: 400ms;
    }
  }

  .skeleton {
    ${"" /* background: rgb(255 255 255); */}
    .skeleton-avatar {
      width: 48px;
      height: 48px;
      background-color: #474f5642;
      border-radius: 100%;
      float: left;
      animation: skeleton 1s ease-in-out forwards infinite;
      animation-direction: alternate;
    }
    .skeleton-line {
      width: 100%;
      height: 2.7rem;
      border-radius: 3px;
      margin-bottom: 0.3rem;
      .heading {
        height: 1rem;
        margin-bottom: 0.5rem;
      }
      .heading-checkbox {
        display: flex;
        .skeleton-checkbox {
          display: block;
          width: 20px;
          height: 20px;
          position: relative;
          left: 10px;
          top: 13px;
          background-color: #474f5642;
          flex-shrink: 0;
        }
        .skeleton-column {
          display: block;
          width: 15%;
          height: 20px;
          position: relative;
          background-color: #474f5642;
          flex-shrink: 0;
          top: 13px;
          margin-left: 50px;
        }
      }
    }
    .text-column {
      display: block;
      width: 100%;
      height: 7px;
      position: relative;
      background-color: #474f5642;
      flex-shrink: 0;
      top: 7px;
      margin-left: 17px;
    }
    .skeleton-img {
      height: 50px;
      border-radius: 10px;
      background-color: ${(props) => props.theme.colors.loading.skeletonImg};
      border: 1px dashed
        ${(props) => props.theme.colors.loading.skeletonImgBorder};
      margin-top: 1.5rem;
      animation: skeleton 1s ease-in-out forwards infinite;
      animation-direction: alternate;
      animation-delay: 300ms;
    }
    .skeleton-button {
      display: block;
      width: 20px;
      height: 20px;
      background-color: #474f5642;
      flex-shrink: 0;
      &:not(:last-child) {
        margin-right: 2rem;
      }
      .rounded {
        border-radius: 100%;
      }
    }
  }

  @keyframes skeleton {
    0% {
      opacity: 0.2;
      transform: translateX(6px) scale(0.98);
    }
    85%,
    100% {
      opacity: 1;
      transform: translateX(0px) scale(1);
    }
  }
`;

export { TableWrap };

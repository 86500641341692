import ASINLink from "../components/router-link";

const LinksMenu = (label, key, children) => {
  return (
    <ASINLink
      id={Math.random()}
      title=""
      to={{
        pathname: `${children ? "/" + children : ""}/${key}`,
        state: {},
      }}
    >
      {label}
    </ASINLink>
  );
};
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children: Array.isArray(children) ? children : null,
    label:
      children && Array.isArray(children)
        ? label
        : LinksMenu(label, key, children),
    type,
  };
}
export const convertSidebarLinks = (list, mode) => {
  return list
    ?.sort(function (a, b) {
      return a?.index?.[0]?.[mode] - b?.index?.[0]?.[mode];
    })
    ?.map((d) => {
      if (d?.children && d?.children?.length !== 0) {
        return getItem(
          d?.title,
          d?.name,
          d?.icon,
          d?.children?.map((c) => {
            return getItem(c.title, c.name, c.icon, c.parent);
          })
        );
      }
      return getItem(d.title, d.name, d.icon);
    });
};

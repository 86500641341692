export const light = {
  colors: {
    primary: "#007bff",
    secondary: "#6c757d",
    body: "rgb(255, 255, 255)",
    theme: "#f3f1ef",
    card: "#313133",
    bodyWrapper: "#dfdfdf",
    tab_Bottom: "rgba(255, 255, 255, 0.16)",
    tooltipBG: "#fff",
    tableGBG: "#003c382b",
    tooltipText: "#000000d9",
    collapse: {
      bg: "rgb(255, 255, 255)",
    },
    sidebar_new: {
      background: "#FFF",
    },
    header: {
      background: "#FFF",
    },
    Loading_BG: {
      background: "#FFF",
    },
    Sales_Analytics: {
      filter_background: "#ffffff96",
      table_fixed: "rgb(255 255 255)",
      table_header: "rgb(255 255 255)",
      chart_tiles_color: "#000",
      ant_collapse_header: "#f3f1ef",
    },
    Dashboard_New: {
      filter_title: "#000000a1",
      Sales_breakdown: "#e0e0e0",
      top_product_color: "#000",
      top_product_key: "#000",
      top_product_value: "#000",
      highLight: "#000000",
      panelText: "#000000a3",
      DiselectTabView: "#3e434814",
      adFooter: "#efefef",
    },
    tooltipColor: "#000",
    tableGroupColumns: "#000",
    gridBack: "rgb(255, 255, 255)",
    darkcolumnGroup: "rgb(200 231 249 / 100%)",
    sidebar: {
      userName: "#181c32",
      userMail: "#00000091",
      borderBottom: "#80808069",
      sidebarIcon: "#090909",
      selectedIcon: "#005d91",
      selectedMenu: "#3E8AC6",
      selectedMenuText: "#005d91",
      selectedMenuBG: "#ECF4FA",
      selectedSubMenu: "rgb(225 225 225 / 35%)",
      selectedBorder: "transparent",
      logoutButton: "#61bbed",
      actionMenu: "rgba(255, 255, 255, 0.55)",
      profileBackShadow:
        "0 15px 25px -4px rgb(198,198,198), inset 0 -3px 4px -1px rgba(0,0,0,0.2), 0 -10px 15px -1px rgba(255,255,255,0.6), inset 0 3px 4px -1px rgba(255,255,255,0.2), inset 0 0 5px 1px rgba(255,255,255,0.8), inset 0 20px 30px 0 rgba(255,255,255,0.2)",
    },
    topBar: {
      back: "rgb(255 255 255)",
    },
    wrapperBG: {
      back: "#f4f7fe",
    },
    loading: {
      back: "rgb(255, 255, 255)",
      skeletonImg: "rgb(245, 245, 245)",
      skeletonImgBorder: "grey",
    },
    dashboard: {
      title: "#181c32",
      cardTitle: "#374151",
      cardSubTitle: "#1f2937",
      value: "#3a3636",
      title: "#3a3636",
      titleH: "#61bbed",
      backCardSelectTitle: "cornflowerblue",
      table: {
        parentASINBG: "rgb(234, 238, 245)",
        parentBoxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        subTitle: "#5e6278",
      },
      grid: {
        background: "rgb(255 255 255)",
        selectedCard: "#c8e7f9",
        selectedBorder: "#61bbed",
        time: "rgb(76 76 76)",
        header: "#969ba4",
        comments: "rgb(38 38 38)",
        commentsSVG: "#4f5058",
        reactionsBack: "#2b2d31",
        reactionsColor: "#b6bac1",
        reactionsBorderHover: "#58595d",
        reactionsColorHover: "#d6d8db",
        reactionsBackHover: "#303136",
      },
    },
    admin: {
      user: {
        name: "black",
        mail: "#525252",
        switch_user: "black",
      },
    },
  },
};

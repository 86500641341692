export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "BY_ASIN_LIST_SUCCESS":
    case "BY_ASIN_LIST_ERROR":
      return {
        ...state,
        ByASINResponse: action.updatePayload,
      };
    case "GET_ALL_PRODUCT_SUCCESS":
    case "GET_ALL_PRODUCT_ERROR":
      return {
        ...state,
        GetAllProductsResponse: action.updatePayload,
      };
    case "FAKE_ACTION_BY_ASIN":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};

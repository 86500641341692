import React, { Suspense, useContext } from "react";
import { Route, Switch } from "react-router-dom";
import RootLoading from "../components/loading/rootLoading";
import asyncComponent from "../core/helper/asyncComponent";
import { adminRoutersList, userRoutersList } from "./lib";
import NotFound from "../components/not-found";
import { GlobalContext } from "../commonContext";

function PageRouter(props) {
  const { authStatus } = props;
  const contextValue = useContext(GlobalContext);

  const user = contextValue.data?.user_;
  let menus = authStatus
    ? adminRoutersList
    : userRoutersList?.filter((d) =>
        !user?.u_stripe_cust_id && !localStorage.getItem("adminData")
          ? d?.path === "/my-subscriptions"
          : d
      );

  console.log(menus, user, user?.u_stripe_plan_id, "menus");
  return (
    <Suspense fallback={<RootLoading />}>
      <Switch>
        {menus?.map((d, i) => {
          return (
            d && <Route exact key={i} path={d?.path} component={d?.name} />
          );
        })}
        <Route path="*" render={() => <NotFound />} />
      </Switch>
    </Suspense>
  );
}

export default PageRouter;

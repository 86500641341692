import { ArrowLeftOutlined, createFromIconfontCN } from "@ant-design/icons";
import { Modal, Menu, Tabs } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GlobalContext } from "../../../commonContext";
import { ModulesList, imageURL } from "../../../config";
import Wrapper, { WrapperNew } from "./style";
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_8d5l8fzk5b87iudi.js",
});
export default function Sidebar(props) {
  const {
    logout,
    // user,
    collapsed,
    setHideMenus,
    userType,
    hideMenus,
    backToAdmin,
    isAdmin,
    setCollapsed,
  } = props;
  const [modal, contextHolder] = Modal.useModal();
  const history = useHistory();
  const location = useLocation();
  const contextValue = useContext(GlobalContext);

  const user = contextValue.data?.user_;
  console.log(user?.advetising === 0 || user?.sp_credential === 0, "user");
  const [current, setCurrent] = useState("dashboard");
  const [subMenuOpen, setSubMenuOpen] = useState("");

  useEffect(() => {
    const splitLoca = location.pathname.split("/");
    if (splitLoca[1]) {
      if (splitLoca.length > 2) {
        setCurrent(splitLoca[2]);
      } else {
        setCurrent(splitLoca[1]);
      }
    } else {
      setCurrent("dashboard");
    }
    if (
      ModulesList({ type: "url" })[location.pathname]?.path?.split("/")?.[1]
    ) {
      setCurrent(
        ModulesList({ type: "url" })[location.pathname]?.path?.split("/")?.[1]
      );
    }
    if (window.innerWidth <= 690) {
      setHideMenus(!hideMenus);
    }
    if (userType) {
      if (location?.pathname === "/" && !location?.search) {
        history.push("/manage-seller-users");
      }
    }
  }, [location]);

  const defaultSubMenuSelected = () => {
    if (collapsed) {
      return [];
    }
    let subMenuPath = window.location.pathname.split("/");
    if (subMenuPath.length === 3) {
      return [subMenuPath?.[1]];
    }
    return [];
  };

  const checkMenu = () => {
    const menuList = ModulesList({
      type: "sidebar",
      mode: userType ? "admin" : "common",
    });
    if (defaultSubMenuSelected()?.length == 0) {
      return [current];
    }
    if (
      menuList?.filter((d) => d?.key === defaultSubMenuSelected()?.[0])
        ?.length == 0
    ) {
      return [""];
    }
    return [current];
  };

  const getMenusList = ModulesList({
    type: "sidebar",
    mode: userType ? "admin" : "common",
  })
    ?.filter((r) =>
      !user?.u_stripe_cust_id && !localStorage.getItem("adminData")
        ? userType
          ? r
          : r?.key === "my-subscriptions"
        : r
    )
    ?.filter((d) => {
      if (localStorage.getItem("adminData")) {
        return d;
      }
      return d?.key !== "application-logs";
    });

  const findMenus = {
    1: "dashboard",
    2: "sales-analytics",
    3: "profit-&-loss-analytics",
    4: "landed-costs",
    5: "inventory",
  };

  const service = (contextValue?.data?.subscriptions || null)?.service?.map(
    (f) => findMenus?.[f?.id]
  );

  return (
    <>
      <WrapperNew
        style={{
          height: "100%",
          backgroundColor: "#FFF",
          width: collapsed ? "105px" : "289px",
          minWidth: collapsed ? "105px" : "289px",
          zIndex: window.innerWidth >= 992 ? "999" : "1000",
          position:
            690 > window.innerWidth || hideMenus ? "absolute" : "relative",
          transition: "width 0.4s, 0.4s",
          // background: "#f9efed",
          overflowY: "auto",
          overflowX: "hidden",
          transform: `translateX(${hideMenus ? "-116" : "0"}px)`,
          animation: ".5s linear",
          boxShadow: "0 .125rem .375rem 0 rgba(161, 172, 184, .12)",
        }}
        className="sidebar_asinwiser"
      >
        <div
          style={{
            padding: "50px 0px 0px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center" /* border: '1px solid red', */,
            // marginLeft: 9,
            // borderBottom: "1px solid rgb(0 0 0 / 10%)",
            background: "#FFF",
          }}
        >
          <span
            style={{
              color: "rgba(137, 137, 137, 0.79)",
              position: "relative",
              top: "-1px",
            }}
          >
            <img
              src={
                collapsed
                  ? "https://static.wixstatic.com/media/319eb0_0493ca08a06248f8b75c184aec31c115%7Emv2.png/v1/fill/w_192%2Ch_192%2Clg_1%2Cusm_0.66_1.00_0.01/319eb0_0493ca08a06248f8b75c184aec31c115%7Emv2.png"
                  : "https://static.wixstatic.com/media/319eb0_6f1634edb5a244928545f86e310a07f1~mv2.jpg/v1/fill/w_482,h_92,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/ZonKeepers-logo-RGB_edited.jpg"
              }
              className="shimmer"
              style={{ width: collapsed ? 30 : 170 }}
            />
          </span>
        </div>

        <div
          style={{
            height: !collapsed ? "calc(100% - 150px)" : "calc(100% - 165px)",
            minHeight: "250px",
            overflow: "auto",
            // transition: "width 0.4s, 0.4s",
            animation: ".5s linear",
            minWidth: collapsed ? "105px" : "289px",
            maxWidth: collapsed ? "105px" : "289px",
            display: "flex",
            justifyContent: "center",
            background: "#FFF",
            // pointerEvents:
            //   user?.advetising === 0 || user?.sp_credential === 0
            //     ? "none"
            //     : "auto",
          }}
          id="kt_aside_menu_wrapper"
        >
          <Menu
            selectedKeys={checkMenu()}
            mode="inline"
            className="mt-20 pt-1"
            // disabled={user?.advetising === 0 || user?.sp_credential === 0}
            onClick={(e) => {
              console.log(
                user?.advetising,
                user?.sp_credential,
                "user?.advetising"
              );

              if (user?.advetising === 0 || user?.sp_credential === 0) {
                console.log("asd");
                modal.error({
                  title: "Please add SP API credentials",
                  content: (
                    <div>
                      You will now be redirected to link amazon seller account
                      page so that you can authorize <b>zonkeepers</b> to access
                      the resources in your Seller Central account.
                    </div>
                  ),
                });
                return;
              }
              if (e?.key === "help-&-support") {
                window.open("https://support.analytics.zonkeepers.com.au/");
                return;
              }
              history.replace("/" + e?.keyPath?.reverse().join("/"));
              setCurrent(e?.key);
              if (hideMenus) {
                setHideMenus(false);
              }
            }}
            inlineCollapsed={collapsed}
            items={
              contextValue?.data?.user_?.u_type !== 1 &&
              !localStorage.getItem("adminData")
                ? getMenusList?.filter((d) => service?.includes(d?.key))
                : getMenusList
            }
            onOpenChange={(e) => {
              setSubMenuOpen(e?.[1]);
            }}
            defaultOpenKeys={defaultSubMenuSelected()}
          />
        </div>

        <div
          style={{
            height: "60px",
            transition: "width 0.4s, 0.4s",
            animation: ".5s linear",

            justifyItems: "center",
          }}
          className={`d-${
            collapsed ? "grid" : "flex"
          }  align-items-center justify-content-around`}
        >
          {window.innerWidth >= 992 && (
            <div
              className="actionMenu"
              style={{ marginTop: collapsed ? "10px" : "" }}
              onClick={() => setCollapsed()}
            >
              <ArrowLeftOutlined
                style={{
                  transition: "width 0.4s, 0.4s",
                  animation: ".5s linear",
                  transform: `rotate(${collapsed ? "180" : "0"}deg)`,
                }}
              />
            </div>
          )}
        </div>
        {contextHolder}
      </WrapperNew>
    </>
  );
}

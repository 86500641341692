import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MySubscriptions from "../../modules/pages/my-subscriptions";
import {
  StripePlanListAction,
  fakeActionMySubscriptions,
} from "../../redux/modules/my-subscriptions/index.action";

const mapStateToProps = (state) => ({
  StripePlanListResponse: state.MySubscriptions.StripePlanListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      StripePlanListAction,
      fakeActionMySubscriptions,
    },
    dispatch
  );

const MySubscriptions_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(MySubscriptions);

export default MySubscriptions_;

import { Form, Input, Select, Modal, message, Spin, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
// import { Spinner } from "react-bootstrap";

import { AddNewPlanWrapper } from "../style";
import { MakeApiCall } from "../../../../../apis";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 16,
    },
    sm: {
      span: 16,
    },
  },
};
const withoutStartSpace =
  /^["a-zA-Z0-9.\-!@#,:;"'-<>?~`$%^&*[|(\])+=/{\\}_/"]([a-zA-Z0-9.\-!@#,:;"'-<>?~`$%^&*[|(\])+=/{\\}_/\s])*$/;
export default function (props) {
  const {
    show,
    onHide,
    serviceList,
    serviceLoading,
    fakeActionPrice = () => {},
    CreatePlansListAction = () => {},
    getPlans,
    UpdatePlanAction = () => {},
    editObj,
  } = props;
  const [form] = Form.useForm();
  const [servicesList, setServicesList] = useState([]);

  const [loading, setLoading] = useState(false);

  const [selectedData, setSelectedData] = useState({
    service_ids: [],
  });
  const GetServicesListRes = [
    {
      id: 1,
      title: "Product Research",
      slug: "product-research",
      created_at: 1674560578,
      updated_at: 1674560578,
      status: 1,
    },
    {
      id: 2,
      title: "Competitor Research",
      slug: "competitor-research",
      created_at: 1674560632,
      updated_at: 1674560632,
      status: 1,
    },
    {
      id: 3,
      title: "Inventory",
      slug: "inventory",
      created_at: 1674627866,
      updated_at: 1674631637,
      status: 1,
    },
    {
      id: 4,
      title: "Profitability Calculator",
      slug: "profitability-calculator",
      created_at: 1674628192,
      updated_at: 1674631673,
      status: 1,
    },
    {
      id: 5,
      title: "Wholesale Supplier Directory",
      slug: "wholesale-supplier-directory",
      created_at: 1674648934,
      updated_at: 1674648934,
      status: 1,
    },
    {
      id: 6,
      title: "Repricing",
      slug: "repricing",
      created_at: 1675080743,
      updated_at: 1675080743,
      status: 1,
    },
    {
      id: 8,
      title: "Sales Analytics",
      slug: "sales-analytics",
      created_at: 1680004905,
      updated_at: 1680004905,
      status: 1,
    },
    {
      id: 10,
      title: "Listing Optimizer",
      slug: "listing-optimizer",
      created_at: 1680082102,
      updated_at: 1680082102,
      status: 1,
    },
    {
      id: 11,
      title: "Bulk Profitability Calculator",
      slug: "bulk-profitability-calculator",
      created_at: 1680082102,
      updated_at: 1680082102,
      status: 1,
    },
  ];

  const { Option } = Select;
  useEffect(() => {
    if (Object.keys(editObj)?.length !== 0) {
      setServicesList(editObj?.services?.map((d) => parseInt(d?.service_id)));
    }
  }, [editObj]);

  useEffect(() => {
    return () => {};
  }, []);

  const onCreate = (values, type) => {
    if (selectedData?.service_ids?.length === 0) {
      message.destroy();
      return message.warning("please select any Services");
    }
    setLoading(true);
    if (type === "add") {
      CreatePlansListAction({ ...values, ...selectedData });
    } else if (type === "edit") {
      delete values?.trial_days;
      UpdatePlanAction(editObj?.id, { ...values, ...selectedData });
    }
  };

  const planTypeSelect = (
    <Form.Item name="type" style={{ width: "90px" }}>
      <Select
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        options={[
          {
            label: "Monthly",
            value: "month",
          },
          {
            label: "Yearly",
            value: "year",
          },
        ]}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
      />
    </Form.Item>
  );

  const PlanConfig = async (data, status) => {
    message.destroy();
    message.loading("Loading...", 0);

    try {
      const response = await MakeApiCall(
        `plan${!status ? `/${editObj?.id}` : ``}`,
        status ? "post" : "put",
        data,
        true
      );

      if (response?.status) {
        getPlans();
        onHide();
        message.destroy();

        message.success(response?.message);
      } else {
        message.destroy();
        message.error(response?.error || response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error("something went wrong");
    }
  };

  return (
    <Modal
      open={show}
      width={700}
      title={
        Object.keys(editObj)?.length === 0 ? `Add New Plan` : editObj?.plan_name
      }
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            message.destroy();
            if (servicesList?.length === 0)
              return message.warning("Please Select Service First");
            PlanConfig(
              { ...values, service_ids: servicesList },
              Object?.keys(editObj || {})?.length === 0
            );
          })
          .catch((info) => {});
      }}
      onCancel={() => {
        onHide();
        form.resetFields();
      }}
      centered
    >
      <AddNewPlanWrapper className="fadeInRight">
        <div className="row g-5">
          <div className="col-md-6">
            <Form
              {...formItemLayout}
              layout="vertical"
              form={form}
              initialValues={
                Object.keys(editObj)?.length === 0
                  ? { type: "month", trial_days: 30 }
                  : editObj
              }
              name="register"
            >
              <Form.Item
                name="plan_name"
                label="Plan Name"
                rules={[
                  {
                    required: true,
                    message: "Plan Name is required",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      const withoutSpace = withoutStartSpace.test(value);
                      if (!withoutSpace) {
                        return Promise.reject("remove without starting Space");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input
                  autoComplete="off"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="plan_description"
                label="Plan Description"
                rules={[
                  {
                    required: true,
                    message: "Plan Description is required",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      const withoutSpace = withoutStartSpace.test(value);
                      if (!withoutSpace) {
                        return Promise.reject("remove without starting Space");
                      }

                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input
                  autoComplete="off"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="plan_subscription_cost"
                label="Plan Subscription Cost"
                rules={[
                  {
                    required: true,
                    message: "Plan Subscription Cost is required",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      const validation = /^[0-9\.\-\/]+$/.test(value);
                      if (!validation) {
                        return Promise.reject("is not valid.");
                      }
                      const withoutSpace = withoutStartSpace.test(value);
                      if (!withoutSpace) {
                        return Promise.reject("remove without starting Space");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input
                  addonBefore={"$"}
                  addonAfter={planTypeSelect}
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Form.Item
                name="no_orders"
                label="No. Of Orders"
                rules={[
                  {
                    required: true,
                    message: "No. Of Orders is required",
                  },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject();
                      }
                      const validation = /^[0-9]+$/.test(value);
                      if (!validation) {
                        return Promise.reject("is not valid.");
                      }
                      const withoutSpace = withoutStartSpace.test(value);
                      if (!withoutSpace) {
                        return Promise.reject("remove without starting Space");
                      }
                      return Promise.resolve();
                    },
                  }),
                ]}
                hasFeedback
              >
                <Input size="large" style={{ width: "100%" }} />
              </Form.Item>
              {Object.keys(editObj)?.length === 0 && (
                <Form.Item
                  name="trial_days"
                  label="Trial Days"
                  rules={[
                    {
                      required: true,
                      message: "Trial Days is required",
                    },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }
                        const validation = /^[0-9\.\-\/]+$/.test(value);
                        if (!validation) {
                          return Promise.reject("is not valid.");
                        }
                        const withoutSpace = withoutStartSpace.test(value);
                        if (!withoutSpace) {
                          return Promise.reject(
                            "remove without starting Space"
                          );
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                  hasFeedback
                >
                  <Input size="large" style={{ width: "100%" }} />
                </Form.Item>
              )}
            </Form>
          </div>
          <div className="col-md-5 ms-auto ">
            <label htmlFor className="fw-bolder mb-4">
              Select Services
            </label>
            <div
              className
              style={{
                height: "410px",
                overflow: "auto",
                position: "relative",
              }}
            >
              <Checkbox.Group
                onChange={(e) => {
                  setServicesList(e);
                }}
                value={servicesList}
              >
                <div className="row gap-4">
                  {serviceList?.map((d, i) => (
                    <div className="col-12">
                      <Checkbox style={{ width: "100%" }} value={d?.id} key={i}>
                        {d?.title}
                      </Checkbox>
                    </div>
                  ))}
                </div>
              </Checkbox.Group>
            </div>
          </div>
        </div>
      </AddNewPlanWrapper>
    </Modal>
  );
}

import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;

  width: 100%;
  margin: 0 auto;
  .ant-input-affix-wrapper {
    height: 55px;
  }
  .container-login100 {
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    flex-wrap: wrap;
  }
  .ant-btn-lg {
    height: 55px !important;
  }
  .sign-label {
    color: #000;
  }
  .sign-up-label {
    color: #000;
  }
  .ant-input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important;
    /* Add any additional styling you want for the auto-fill text */
  }
  input:-webkit-autofill,
  input.ant-input:focus {
    box-shadow: #fff 0px 0px 0px 50px inset !important;
  }
  .wrap-login100 {
    width: 600px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    right: 0px;
    top: 0px;
    backdrop-filter: blur(10px);
    bottom: 0px;
    z-index: 99;
    background: #fff;
    border-radius: 20px;
    margin: auto;
    position: absolute;
    inset: 0px;
    height: fit-content;
    overflow: hidden;
    // border: 1px dashed #009ef7;
  }
  .login100-more {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: calc(100% - 0px);
    position: relative;
    z-index: 1;
  }
  .image {
    height: 100%;

    // background-image: url(https://cdn.shopify.com/s/files/1/1889/3647/files/2021_05_Lavley_Lifestyle-18_1500x1500_crop_center.jpg);
    background-image: url(lavley_react/assets/login-bg-01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .cardAuth {
    height: auto;
    overflow: auto;
    inset: 0;
    margin: auto;
    height: fit-content;
    margin: auto;
    width: fit-content !important;
    border-radius: 30px;

    background-color: transparent !important;
  }
`;
export const NewWrapper = styled.div`
  height: 100%;
  background: #fff;
  img {
    max-width: 100%;
    height: auto;
  }
  .ant-input:-webkit-autofill {
    -webkit-text-fill-color: #000 !important;
    /* Add any additional styling you want for the auto-fill text */
  }
  input:-webkit-autofill,
  input.ant-input:focus {
    box-shadow: #ebf0f7 0px 0px 0px 50px inset !important;
  }
  /* Set up the slide animation */
  @keyframes takeoff {
    0% {
      transform: translateX(-40%);
      opacity: 0.4;
    }

    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  .slide-box {
    opacity: 0.3;
    transform: translateX(-40%);
    animation: takeoff 1.3s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  }
  @keyframes takeoff-text {
    0% {
      transform: translateX(-50%);
      opacity: 0.4;
    }
    80% {
      transform: translateX(0%);
      opacity: 1;
    }
    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }
  .logo-animation {
    opacity: 0.3;
    transform: translateX(-50%);
    animation: takeoff-text 1.3s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  }

  .container,
  .row,
  .col-lg-8 {
    height: 100%;
  }
  .fxt-content-between {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  @media only screen and (max-width: 767px) {
    .fxt-none-767 {
      display: none !important;
    }
  }
  @media only screen and (max-width: 991px) {
    .fxt-none-991 {
      display: none !important;
    }
  }

  .fxt-template-animation {
    position: relative;
    z-index: 1;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .fxt-template-animation .fxt-opacity {
    opacity: 0;
  }
  .fxt-template-animation .fxt-transformY-50 {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -ms-transform: translateY(50px);
    transform: translateY(50px);
  }
  .fxt-template-animation .fxt-transformX-L-50 {
    opacity: 0;
    -webkit-transform: translateX(-50px);
    -ms-transform: translateX(-50px);
    transform: translateX(-50px);
  }
  .fxt-template-animation .fxt-transformX-R-50 {
    opacity: 0;
    -webkit-transform: translateX(50px);
    -ms-transform: translateX(50px);
    transform: translateX(50px);
  }
  .loaded.fxt-template-animation {
    opacity: 1;
  }
  .loaded.fxt-template-animation .fxt-opacity {
    opacity: 1;
    -webkit-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .loaded.fxt-template-animation .fxt-transformY-50 {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    -webkit-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .loaded.fxt-template-animation .fxt-transformX-L-50 {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    -webkit-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .loaded.fxt-template-animation .fxt-transformX-R-50 {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
    -webkit-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    -o-transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    transition: all 1.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .loaded.fxt-template-animation .fxt-transition-delay-1 {
    -webkit-transition-delay: 0.1s;
    -o-transition-delay: 0.1s;
    transition-delay: 0.1s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-2 {
    -webkit-transition-delay: 0.2s;
    -o-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-3 {
    -webkit-transition-delay: 0.3s;
    -o-transition-delay: 0.3s;
    transition-delay: 0.3s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-4 {
    -webkit-transition-delay: 0.4s;
    -o-transition-delay: 0.4s;
    transition-delay: 0.4s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-5 {
    -webkit-transition-delay: 0.5s;
    -o-transition-delay: 0.5s;
    transition-delay: 0.5s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-6 {
    -webkit-transition-delay: 0.6s;
    -o-transition-delay: 0.6s;
    transition-delay: 0.6s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-7 {
    -webkit-transition-delay: 0.7s;
    -o-transition-delay: 0.7s;
    transition-delay: 0.7s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-8 {
    -webkit-transition-delay: 0.8s;
    -o-transition-delay: 0.8s;
    transition-delay: 0.8s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-9 {
    -webkit-transition-delay: 0.9s;
    -o-transition-delay: 0.9s;
    transition-delay: 0.9s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-10 {
    -webkit-transition-delay: 1s;
    -o-transition-delay: 1s;
    transition-delay: 1s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-11 {
    -webkit-transition-delay: 1.1s;
    -o-transition-delay: 1.1s;
    transition-delay: 1.1s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-12 {
    -webkit-transition-delay: 1.2s;
    -o-transition-delay: 1.2s;
    transition-delay: 1.2s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-13 {
    -webkit-transition-delay: 1.3s;
    -o-transition-delay: 1.3s;
    transition-delay: 1.3s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-14 {
    -webkit-transition-delay: 1.4s;
    -o-transition-delay: 1.4s;
    transition-delay: 1.4s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-15 {
    -webkit-transition-delay: 1.5s;
    -o-transition-delay: 1.5s;
    transition-delay: 1.5s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-16 {
    -webkit-transition-delay: 1.6s;
    -o-transition-delay: 1.6s;
    transition-delay: 1.6s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-17 {
    -webkit-transition-delay: 1.7s;
    -o-transition-delay: 1.7s;
    transition-delay: 1.7s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-18 {
    -webkit-transition-delay: 1.8s;
    -o-transition-delay: 1.8s;
    transition-delay: 1.8s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-19 {
    -webkit-transition-delay: 1.9s;
    -o-transition-delay: 1.9s;
    transition-delay: 1.9s;
  }
  .loaded.fxt-template-animation .fxt-transition-delay-20 {
    -webkit-transition-delay: 2s;
    -o-transition-delay: 2s;
    transition-delay: 2s;
  }

  .fxt-template-layout34 {
    position: relative;
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center top;
  }
  .fxt-template-layout34 .fxt-column-wrap {
    position: relative;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
  @media only screen and (max-width: 991px) {
    .fxt-template-layout34 .fxt-column-wrap {
      padding-top: 10vh;
      padding-bottom: 10vh;
      min-height: auto;
    }
  }
  @media only screen and (max-width: 767px) {
    .fxt-template-layout34 .fxt-column-wrap {
      padding-top: 8vh;
      padding-bottom: 8vh;
    }
  }
  @media only screen and (max-width: 575px) {
    .fxt-template-layout34 .fxt-column-wrap {
      padding-top: 6vh;
      padding-bottom: 6vh;
    }
  }
  .fxt-template-layout34 .fxt-shape {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .fxt-template-layout34 .fxt-animated-img {
    position: absolute;
    z-index: -1;
    top: 50%;
    right: 50px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .fxt-template-layout34 .fxt-logo {
    margin-bottom: 50px;
    display: block;
    max-width: 35vw;
  }
  .fxt-template-layout34 .fxt-main-title {
    max-width: 280px;
    width: 100%;
    font-size: 40px;
    font-weight: 700;
    color: #000000;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 991px) {
    .fxt-template-layout34 .fxt-main-title {
      font-size: 36px;
    }
  }
  @media only screen and (max-width: 767px) {
    .fxt-template-layout34 .fxt-main-title {
      font-size: 32px;
    }
  }
  @media only screen and (max-width: 575px) {
    .fxt-template-layout34 .fxt-main-title {
      font-size: 28px;
    }
  }
  .fxt-template-layout34 .fxt-switcher-description1 {
    color: #363636;
    font-size: 20px;
    max-width: 260px;
    width: 100%;
    margin-bottom: 40px;
  }
  .fxt-template-layout34 .fxt-switcher-description1 .fxt-switcher-text {
    display: inline-block;
    color: #f27441;
    font-size: 18px;
    font-weight: 600;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .fxt-template-layout34 .fxt-switcher-description1 .fxt-switcher-text:hover {
    color: #e04b25;
    text-decoration: underline;
  }
  .fxt-template-layout34 .fxt-switcher-description2 .fxt-switcher-text {
    color: #b1b1b2;
    font-size: 14px;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .fxt-template-layout34 .fxt-switcher-description2 .fxt-switcher-text:hover {
    color: #e04b25;
    text-decoration: underline;
  }
  .fxt-template-layout34 .fxt-switcher-description3 {
    text-align: center;
    font-size: 16px;
    color: #646464;
    margin-bottom: 10px;
  }
  .fxt-template-layout34 .fxt-switcher-description3 .fxt-switcher-text {
    color: #4460f1;
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .fxt-template-layout34 .fxt-switcher-description3 .fxt-switcher-text:hover {
    color: #0f2ab2;
    text-decoration: underline;
  }
  .fxt-template-layout34 .fxt-qr-code {
    display: inline-block;
    max-width: 35vw;
  }
  .fxt-template-layout34 .fxt-qr-code img {
    -webkit-box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #dfdfdf;
  }
  @media only screen and (max-width: 575px) {
    .fxt-template-layout34 .fxt-qr-code img {
      padding: 5px;
    }
  }
  @keyframes topToCenter {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .fxt-form {
    opacity: 0; /* Start off as invisible */
    transform: translateX(-100%); /* Start off-screen to the top */
    animation: topToCenter 1.3s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  }

  .fxt-template-layout34 .fxt-form {
    margin-top: 10px;
  }
  .fxt-template-layout34 .fxt-form .fxt-label {
    color: #14133b;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .fxt-template-layout34 .fxt-form .form-group {
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
  }
  .fxt-template-layout34 .fxt-form .form-group .field-icon {
    position: absolute;
    z-index: 1;
    right: 26px;
    bottom: 24px;
    font-size: 14px;
    color: #a1a1a1;
  }
  .fxt-template-layout34 .fxt-form .form-group .field-icon:before {
    padding: 17px 10px;
  }
  .fxt-template-layout34 .fxt-form .form-control {
    border-radius: 10px;
    background-color: #ebf0f6;
    min-height: 60px;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ebf0f6;
    padding: 10px 20px;
    color: #111;
  }
  @media only screen and (max-width: 767px) {
    .fxt-template-layout34 .fxt-form .form-control {
      min-height: 50px;
    }
  }
  .fxt-template-layout34 .fxt-form input::-webkit-input-placeholder {
    color: #858588 !important;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
  .fxt-template-layout34 .fxt-form input::-moz-placeholder {
    color: #858588 !important;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
  .fxt-template-layout34 .fxt-form input:-moz-placeholder {
    color: #858588 !important;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
  .fxt-template-layout34 .fxt-form input:-ms-input-placeholder {
    color: #858588 !important;
    font-size: 14px !important;
    font-weight: 300 !important;
  }
  .fxt-template-layout34 .fxt-form .fxt-checkbox-box {
    margin-bottom: 25px;
  }
  .ant-input-affix-wrapper,
  .ant-input {
    background-color: #ebf0f6;
  }
  .fxt-template-layout34 .fxt-form .fxt-checkbox-box label {
    color: #14133b;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 500;
    position: relative;
  }
  .fxt-template-layout34 .fxt-form .fxt-checkbox-box label:before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    top: 5px;
    left: 0;
    right: 0;
    border: 1px solid;
    border-color: #dcdcdc;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  }
  .fxt-template-layout34 .fxt-form .fxt-checkbox-box label:after {
    position: absolute;
    font-size: 10px;
    color: #555555;
  }
  .fxt-template-layout34 .fxt-form .fxt-checkbox-box input[type="checkbox"] {
    display: none;
  }
  .fxt-template-layout34
    .fxt-form
    .fxt-checkbox-box
    input[type="checkbox"]:checked
    + label::after {
    font-family: "Font Awesome 5 Free";
    content: "\f00c";
    font-weight: 900;
    color: #ffffff;
    left: 0;
    right: 0;
    top: 5px;
    width: 16px;
    text-align: center;
  }
  .fxt-template-layout34
    .fxt-form
    .fxt-checkbox-box
    input[type="checkbox"]:checked
    + label::before {
    background-color: #4460f1;
    border-color: #4460f1;
  }
  .fxt-template-layout34 .fxt-form .fxt-otp-logo {
    margin-bottom: 30px;
    display: block;
  }
  .fxt-template-layout34 .fxt-form .fxt-otp-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  .fxt-template-layout34 .fxt-form .fxt-otp-row .fxt-otp-col {
    min-height: 50px;
    padding: 5px;
    text-align: center;
    margin-right: 15px;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
  }
  .fxt-template-layout34 .fxt-form .fxt-otp-row .fxt-otp-col:last-child {
    margin-right: 0;
  }
  .fxt-template-layout34 .fxt-form .fxt-otp-btn {
    margin-bottom: 20px;
  }
  .fxt-template-layout34 .terms-link {
    display: inline-block;
    color: #4460f1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .fxt-template-layout34 .terms-link:hover {
    color: #1a34b8;
    text-decoration: underline;
  }
  .fxt-template-layout34 .fxt-btn-fill {
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    display: inline-block;
    font-size: 18px;
    font-weight: 500;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    border: 0;
    color: #fff;
    border-radius: 10px;
    background-color: rgb(242 116 65);
    padding: 12px 36px;
    width: 100%;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .fxt-template-layout34 .fxt-btn-fill:hover {
    background-color: rgb(224 75 37);
  }
  .fxt-template-layout34 .fxt-btn-fill:focus {
    outline: none;
  }
  @media only screen and (max-width: 767px) {
    .fxt-template-layout34 .fxt-btn-fill {
      font-size: 16px;
      padding: 11px 30px;
    }
  }
  .fxt-template-layout34 .fxt-style-line {
    overflow: hidden;
    text-align: center;
    margin-bottom: 20px;
  }
  .fxt-template-layout34 .fxt-style-line span {
    text-align: center;
    font-size: 15px;
    color: #acacac;
    display: inline-block;
    position: relative;
    padding: 0 25px;
    z-index: 1;
  }
  .fxt-template-layout34 .fxt-style-line span:before {
    display: inline-block;
    content: "";
    height: 2px;
    width: 100%;
    background-color: #cfcfcf;
    left: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
  }
  .fxt-template-layout34 .fxt-style-line span:after {
    display: inline-block;
    content: "";
    height: 2px;
    width: 100%;
    background-color: #cfcfcf;
    right: 100%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    z-index: 1;
  }
  .fxt-template-layout34 ul.fxt-socials {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-right: -5px;
    margin-left: -5px;
  }
  .fxt-template-layout34 ul.fxt-socials li {
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
  }
  .fxt-template-layout34 ul.fxt-socials li a {
    border-radius: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 20px;
    height: 60px;
    width: 80px;
    border: 1px solid;
    border-color: #cfcfcf;
    background-color: #fefefe;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  @media only screen and (max-width: 767px) {
    .fxt-template-layout34 ul.fxt-socials li a {
      font-size: 18px;
      height: 50px;
      width: 60px;
    }
  }
  .fxt-template-layout34 ul.fxt-socials li a:hover {
    background-color: #ffffff;
    border-color: #ffffff;
    -webkit-box-shadow: 0 0 62px 0 rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 62px 0 rgba(0, 0, 0, 0.1);
  }
  .fxt-template-layout34 ul.fxt-socials li.fxt-google a {
    color: #cc3333;
  }
  .fxt-template-layout34 ul.fxt-socials li.fxt-apple a {
    color: #132133;
  }
  .fxt-template-layout34 ul.fxt-socials li.fxt-facebook a {
    color: #3b5998;
  }
`;
export default Wrapper;

import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import asyncComponent from "./core/helper/asyncComponent";
import RootLoading from "./components/loading/rootLoading";
import Login_ from "./containers/auth";
import CreateUser_ from "./containers/auth/create";
import Pages from "./containers";
import forgotPassword from "./modules/pages/auth/lib/forgot-password";
import resetPassword from "./modules/pages/auth/lib/reset-password";

// const Login_ = React.lazy(() => import(""));
// const CreateUser_ = React.lazy(() => import("./containers/auth/create"));
// const Pages = React.lazy(() => import("./containers"));
function AppRoute(props) {
  return (
    <Router history={props.history}>
      <Suspense fallback={<RootLoading />}>
        <Switch>
          <Route exact path="/login" component={Login_} />
          <Route exact path="/forgot-password" component={forgotPassword} />
          <Route exact path="/reset-password" component={resetPassword} />
          <Route exact path="/signup" component={CreateUser_} />

          <Route path="/" component={Pages}></Route>
          <Redirect to="/" />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default AppRoute;

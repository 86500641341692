export const nameObject = {
  "/": {
    name: "Dashboard",
  },
  "/my-subscriptions": {
    name: "My Subscriptions",
  },
  "/p&l-breakdown": {
    name: "P & L Breakdown",
  },
  "/sales-analytics": {
    name: "Sales Analytics",
  },
  "/manage-subscriptions": {
    name: "Manage Subscriptions",
  },
  "/setting/app-configurations": {
    name: "Setting",
    parent: "App Configurations",
  },
  "/setting/admin-scheduler": {
    name: "Setting",
    parent: "Admin Scheduler",
  },
  "/inventory": {
    name: "Inventory",
  },
  "/help-&-support": {
    name: "Help & Support",
  },
  "/profit-&-loss-analytics": {
    name: "Profit & Loss Analytics",
  },
  "/landed-costs": {
    name: "Landed Costs",
  },
  "/manage-seller-users": {
    name: "Manage Seller Users",
  },
  "/sales-analytics/by-sku": {
    name: "By SKU",
    parent: "Sales Analytics",
  },
  "/sales-analytics/by-variation-asin": {
    name: "By Variation ASIN",
    parent: "Sales Analytics",
  },
  "/sales-analytics/by-brand": {
    name: "By Brand",
    parent: "Sales Analytics",
  },
  "/advertising-reports/product-ads-report": {
    name: "Product Ads Report",
    parent: "Advertising Reports",
  },
  "/finance-api": {
    name: "Finance API",
  },
  "/mom-&-yoy-comparison": {
    name: "Mom & Yoy Comparison",
  },
  "/mapping": {
    name: "Mapping",
  },
  "/manage-inventory": {
    name: "Manage Inventory",
  },
  "/reserved-inventory": {
    name: "Reserved Inventory",
  },
  "/setting/profile": {
    name: "Profile",
    parent: "Setting",
  },
  "/setting/sp-api": {
    name: "SP API",
    parent: "Setting",
  },
  "/setting/currency-conversion": {
    name: "Currency Conversion",
    parent: "Setting",
  },
  "/setting/amazon-credentials": {
    name: "Amazon Credentials",
    parent: "Setting",
  },
  "/application-logs/central-log": {
    name: "Central Log",
    parent: "Application Logs",
  },
  "/application-logs/system-events-log": {
    name: "System Events Log",
    parent: "Application Logs",
  },
};

export const DashboardAdTableFilter = [
  {
    label: "Ad Type",
    value: "ad_type",
  },
  {
    label: "Product Type",
    value: "product_type",
  },
  {
    label: "Brand",
    value: "brand",
  },
  {
    label: "Variation",
    value: "variation",
  },
  {
    label: "Marketplace",
    value: "marketplace",
  },
  {
    label: "Product",
    value: "product",
  },
];

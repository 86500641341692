import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Goals from "../../modules/pages/goals";
import {
  salesChannelListAction,
  fakeActionDashboard,
} from "../../redux/modules/dashboard/index.action";
import {
  GetCategoryGoalsListAction,
  UpdateCategoryGoalsAction,
  fakeActionFinanceData,
} from "../../redux/modules/goals/index.action";

const mapStateToProps = (state) => ({
  SalesChannelListResponse: state.Dashboard.SalesChannelListResponse,
  GetCategoryGoalsListResponse: state.Goals.GetCategoryGoalsListResponse,
  UpdateCategoryGoalsResponse: state.Goals.UpdateCategoryGoalsResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fakeActionDashboard,
      salesChannelListAction,

      GetCategoryGoalsListAction,
      UpdateCategoryGoalsAction,
      fakeActionFinanceData,
    },
    dispatch
  );

const Goals_ = connect(mapStateToProps, mapDispatchToProps)(Goals);

export default Goals_;

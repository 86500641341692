import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
  const path = `login`;
  const responseType = "LOGIN";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const signUpUserAction = (data) => {
  const path = `signup`;
  const responseType = "SIGN_UP";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAuth = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AUTH", state: data });
};

import axiosCall from "../../configurations/network-services/axiosCall";

export const amazonCredentialsListAction = (data) => {
  const path = `advertising-credentials?page=${data?.page || 1}&pageSize=${
    data?.perPage || 20
  }`;
  const responseType = "AMAZON_CREDENTIALS_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const amazonCredentialsSkipPageAction = (id) => {
  const path = `cred-status/${id}`;
  const responseType = "AMAZON_CREDENTIALS_SKIP_PAGE";
  return axiosCall("post", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const RenerateRefreshAdTokenAction = (data) => {
  const path = `generate-refresh-ad-token`;
  const responseType = "GENERATE_REFRESH_AD_TOKEN";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetMarketplaceListAction = (data) => {
  const path = `get-marketplace-list`;
  const responseType = "GET_MARKETPLACE_LIST_AD";
  return axiosCall("get", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const SaveAdProfileAction = (data) => {
  const path = `save-ad-profile`;
  const responseType = "SAVE_AD_PROFILE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAmazonCredentials = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AMAZON_CREDENTIALS", state: data });
};

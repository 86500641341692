import React, { useEffect } from "react";
import GlobalCommonContextProvider from "./commonContext";
import ThemeValidation from "./themeValidation";
import { ConfigProvider } from "antd";

export default function App() {
  console.log("init");
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `'Roboto', sans-serif`,
        },
      }}
    >
      <GlobalCommonContextProvider>
        <ThemeValidation />
      </GlobalCommonContextProvider>
    </ConfigProvider>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Form, Modal, message, Input, Upload } from "antd";
import { EndPoint, imageURL } from "../../../../../config";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 7,
    },
  },
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function (props) {
  const {
    show,
    close,
    data,
    signUpUserAction,
    fakeActionAuth,
    getList,
    updateUserAction,
    fakeActionUser,
  } = props;

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState(
    Object.keys(data)?.length !== 0 && data?.u_photo
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: `${imageURL}${data?.u_photo}`,
          },
        ]
      : []
  );

  const SignUpUserResponse = useSelector(
    (state) => state.Auth.SignUpUserResponse || {}
  );
  const UpdateUserResponse = useSelector(
    (state) => state.User.UpdateUserResponse || {}
  );

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (UpdateUserResponse?.status === true) {
      message.destroy();
      message.success(UpdateUserResponse?.message);
      getList();
      close();
      fakeActionUser("UpdateUserResponse");
    } else if (UpdateUserResponse?.status === false) {
      message.destroy();
      message.error(UpdateUserResponse?.message);
      fakeActionUser("UpdateUserResponse");
    }
  }, [UpdateUserResponse]);

  useEffect(() => {
    if (SignUpUserResponse?.status === true) {
      message.destroy();
      message.success(SignUpUserResponse?.message);
      getList();
      close();
      fakeActionAuth("SignUpUserResponse");
    } else if (SignUpUserResponse?.status === false) {
      message.destroy();

      message.error(
        Object?.values(SignUpUserResponse?.error)?.[0]?.[0] ||
          SignUpUserResponse?.message
      );
      fakeActionAuth("SignUpUserResponse");
    }
  }, [SignUpUserResponse]);
  const onCreate = (values) => {
    message.destroy();
    message.loading("Loading...", 0);
    const withPic = { ...values, u_photo: fileList?.[0]?.originFileObj };
    const withOutPic = { ...values };
    const addObj = {
      u_name: values?.u_name,
      u_email: values?.u_email,
      u_password: values?.password,
      u_confirm_password: values?.confirm,
      rds_credential_id: 1,
      server_credential_id: 1,
      u_contact_no: values?.u_contact_no,
    };
    if (fileList?.length !== 0 || fileList?.[0]?.url) {
      if (Object.keys(data)?.length === 0) {
        delete withPic.confirm;
        signUpUserAction({ ...addObj });
      } else {
        updateUserAction(data?.u_id, { ...withPic });
      }
    } else {
      if (Object.keys(data)?.length === 0) {
        signUpUserAction({ ...addObj });
      } else {
        updateUserAction(data?.u_id, { ...withOutPic });
      }
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };
  const phoneRegex = /^\d{10}$/;
  return (
    <Modal
      title={`${Object.keys(data)?.length === 0 ? "Add" : "Edit"} User`}
      open={show}
      width={650}
      onCancel={close}
      footer={[
        <Button size="large" type="ghost" key="back" onClick={close}>
          Close
        </Button>,
        <Button
          size="large"
          key="submit"
          type="dashed"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {});
          }}
        >
          {`${Object.keys(data)?.length === 0 ? "Create" : "Update"} User`}
        </Button>,
      ]}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="add_user"
        scrollToFirstError
        layout="vertical"
        style={{
          height: "510px",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px 20px",
        }}
        initialValues={
          Object.keys(data)?.length === 0
            ? {}
            : {
                u_name: data.u_name,
                u_email: data.u_email,
                u_address: data.u_address,
                u_contact_no: data.u_contact_no,
              }
        }
      >
        {Object.keys(data)?.length !== 0 && (
          <div className="row align-items-center">
            <Form.Item valuePropName="fileList" label="Avatar">
              <div
                className="image-input image-input-outline"
                data-kt-image-input="true"
              >
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  accept=".png, .jpg, .jpeg"
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length >= 1 ? null : uploadButton}
                </Upload>
              </div>
              <div className="form-text">
                Allowed file types: png, jpg, jpeg.
              </div>
            </Form.Item>
          </div>
        )}
        <div className="row   align-items-center">
          <Form.Item
            name="u_name"
            label="User Name"
            rules={[
              {
                required: true,
                message: "Please input your User Name!",
              },
            ]}
          >
            <Input size="large" placeholder="User Name" />
          </Form.Item>
        </div>
        <div className="row   align-items-center">
          <Form.Item
            name="u_email"
            label="E-mail"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input size="large" placeholder="E-mail" />
          </Form.Item>
        </div>
        {Object.keys(data)?.length !== 0 && (
          <div className="row   align-items-center">
            <Form.Item name="u_address" label="Address">
              <Input.TextArea size="large" placeholder="Address" />
            </Form.Item>
          </div>
        )}
        <div className="row   align-items-center">
          <Form.Item
            name="u_contact_no"
            label="Contact no."
            rules={[
              {
                required: true,
                message: "Please input your Contact no.!",
              },
              {
                pattern: phoneRegex,
                message: "Please enter a valid phone number!",
              },
            ]}
          >
            <Input size="large" placeholder="Contact no." />
          </Form.Item>
        </div>
        {Object.keys(data)?.length === 0 && (
          <>
            <div className="row   align-items-center">
              <Form.Item
                name="password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Password is required",
                  },
                  {
                    min: 8,
                    message: "Password must be at least 8 characters",
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  placeholder="Password"
                  autocomplete="new-password"
                />
              </Form.Item>
            </div>
            <div className="row   align-items-center">
              <Form.Item
                name="confirm"
                label="Confirm Password"
                dependencies={["password"]}
                rules={[
                  {
                    required: true,
                    message: "Confirm password is required",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  placeholder="Confirm Password"
                  autocomplete="off"
                  size="large"
                />
              </Form.Item>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

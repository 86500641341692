import { Modal, Spin, Tag } from "antd";
import ReactApexChart from "react-apexcharts";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { DateFormat } from "../../../../config";

const ZoomChart = ({
  show,
  options,
  series,
  onClose,
  sign,
  graphData,
  zoomGraph,
  labels,
  filters,
}) => {
  const sign_ =
    zoomGraph?.label === "Net Margin" || zoomGraph?.label === "ROI"
      ? "%"
      : sign;

  const [loading, setLoading] = useState(true);
  const [graphData_, setGraphData_] = useState({
    label: [],
    series: [],
  });

  const updateGraph = () => {
    setGraphData_({
      label: graphData?.[labels?.chartLabels],
      series: series(zoomGraph),
    });
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    if (show) {
      updateGraph();
    } else {
      setLoading(false);
    }
    return () => {
      setLoading(false);
    };
  }, [show]);

  const op = {
    series: graphData_?.series,
    chart: {
      fontFamily: "inherit",
      type: "line",

      toolbar: { show: false },
      zoom: {
        enabled: true,
      },
      dropShadow: {
        enabled: true,
        top: 12,
        left: 0,
        bottom: 0,
        right: 0,
        blur: 2,
        color: "rgba(132, 145, 183, 0.4)",
        opacity: 0.45,
      },
    },

    legend: { show: true },
    dataLabels: { enabled: false },

    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 1,
        opacityTo: 0.2,
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: [1, 3],
      colors: ["#ffb206", "#E9613B"],
    },
    xaxis: {
      labels: {
        rotate: -45,
        rotateAlways: true,
      },
      tickAmount: 10,
      tickPlacement: "between",
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          return (
            (sign_ !== "%" ? sign_ : "") +
            parseFloat(value).toLocaleString() +
            (sign_ === "%" ? sign_ : "")
          );
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return (
            (sign_ !== "%" ? sign_ : "") +
            value.toFixed(2) +
            (sign_ === "%" ? sign_ : "")
          ); // Formats each label to have 2 decimal places
        },
      },
    },
    labels: graphData_?.label?.map((d) =>
      moment(d, "YYYY-MM-DD").format(DateFormat)
    ),

    colors: ["#ffb206", "#E9613B"],
    grid: {
      show: true,
    },
  };

  return (
    <Modal
      centered
      width={1050}
      title={
        <div className="d-flex align-items-center justify-content-between">
          <span>{zoomGraph?.label || "Zoom Graph"}</span>
          <Tag className="me-10" color="orange" bordered={false}>
            {`${moment(filters?.prev_start_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            )} -> ${moment(filters?.prev_end_date, "YYYY-MM-DD").format(
              "DD-MM-YYYY"
            )}`}
          </Tag>
        </div>
      }
      visible={show}
      onOk={onClose}
      onCancel={onClose}
      footer={[]}
    >
      {loading ? (
        <div className="d-flex align-items-center h-300px justify-content-center">
          <Spin />
        </div>
      ) : (
        <div
          style={{
            zoom: "110%",
          }}
        >
          <ReactApexChart
            options={op}
            series={op?.series
              ?.map((d) => ({
                ...d,
                type: "area",
                name: d?.name === "Date" ? zoomGraph?.label : d?.name,
              }))
              ?.filter((d) => d?.name !== "Comparison Date")}
            type={"area"}
            height={330}
          />
        </div>
      )}
    </Modal>
  );
};

export default ZoomChart;

import React from "react";
import { Wrapper } from "./style";
import { Form, Input, message } from "antd";

const listObj = [
  {
    title: "App Type",
    name: "app_type",
  },
  {
    title: "App Developer Id",
    name: "app_developer_id",
  },
  {
    title: "Lwa Client Id",
    name: "lwa_client_id",
  },
  {
    title: "Lwa Client Secret",
    name: "lwa_client_secret",
  },
  {
    title: "Lwa Return Url",
    name: "lwa_return_url",
  },
  {
    title: "Lwa Arn",
    name: "lwa_arn",
  },
  {
    title: "Aws Access Key",
    name: "aws_access_key",
  },
  {
    title: "Aws Secret Key",
    name: "aws_secret_key",
  },
  {
    title: "Aws Iam Access Key",
    name: "aws_iam_access_key",
  },
  {
    title: "Aws Iam Secret Key",
    name: "aws_iam_secret_key",
  },
  {
    title: "Aws Region",
    name: "aws_region",
  },
  {
    title: "Open AI Key",
    name: "open_ai_key",
  },
];

const AppConfig = () => {
  const [form] = Form.useForm();
  return (
    <Wrapper
      className="fadeInRight content d-flex flex-column flex-column-fluid"
      id="kt_content"
    >
      <div className="container-fluid" id="kt_content_container">
        <div className="card mt-5 fadeInBottom">
          <div className="card-body">
            <Form
              name="app_config"
              layout="vertical"
              autoComplete="off"
              form={form}
            >
              <div className="row">
                {listObj?.map((d, i) => {
                  return (
                    <div
                      className={`col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-12`}
                    >
                      <Form.Item
                        key={i}
                        label={d.title}
                        name={d.name}
                        rules={[
                          {
                            required: true,
                            message: `Please input your ${d.title}!`,
                          },
                        ]}
                        hasFeedback
                      >
                        <Input size="large" placeholder={`Enter ${d.title}`} />
                      </Form.Item>
                    </div>
                  );
                })}
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9 border-top border-top-dashed">
                <button
                  // disabled={onChangeFiled}
                  onClick={() => {
                    message.destroy();
                    message.success("Reset Change");
                    // form.setFieldsValue({ ...obj });
                  }}
                  class="btn btn-light-danger me-2"
                >
                  Discard
                </button>
                <button
                  type="submit"
                  htmlType="submit"
                  className="btn btn-primary"
                  // disabled={onChangeFiled}
                >
                  {1 === 2 ? (
                    <span>
                      Update
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  ) : (
                    <span>Update</span>
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default AppConfig;

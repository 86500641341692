import { Drawer } from "antd";
import React from "react";
import { numberformat } from "../../../../config";

const MoreData = ({ show, onHide, data, sign }) => {
  return (
    <Drawer
      title="Product Details"
      placement="right"
      onClose={onHide}
      open={show}
      width={450}
    >
      <>
        <a className="card border-hover-primary ">
          <div className="card-header border-0 pt-9 justify-content-center gap-3">
            <div className="card-title m-0">
              <div className="symbol symbol-200px w-100 bg-light">
                <img src={data?.image} alt="image" className="p-3" />
              </div>
            </div>

            <div className="card-toolbar d-flex">
              <span className="badge fs-7  badge-light-primary fw-bold me-auto px-4 py-3">
                ASIN: {data?.asin || "-"}
              </span>
              <span className="badge fs-7 ms-3 badge-light-info fw-bold me-auto px-4 py-3">
                SKU: {data?.sku || "-"}
              </span>
            </div>
          </div>

          <div className="card-body p-5">
            <div className="fs-5 fw-normal text-gray-800">
              {data?.title || "-"}
            </div>
          </div>
        </a>

        <div className="mt-10 d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
          <div className="fw-bolder">Orders</div>
          <div className="fw-bolder fs-6 text-info">
            {numberformat(data?.order_count)}
          </div>
        </div>

        <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
          <div className="fw-bolder">Units</div>
          <div className="fw-bolder fs-6 text-info">
            {numberformat(data?.order_quantity)}
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
          <div className="fw-bolder">Sales</div>
          <div className="fw-bolder fs-6 text-info">
            {sign}
            {numberformat(data?.order_sales || 0)}
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
          <div className="fw-bolder">Net Margin</div>
          <div className="fw-bolder fs-6 text-info">
            {numberformat(data?.net_margin || 0)}%
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
          <div className="fw-bolder">Net Profit</div>
          <div className="fw-bolder fs-6 text-info">
            {sign}
            {numberformat(data?.net_profit)}
          </div>
        </div>

        <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
          <div className="fw-bolder">PPC Orders</div>
          <div className="fw-bolder fs-6 text-info">
            {numberformat(data?.ad_order)}
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 fs-7 border-bottom border-bottom-dashed border-gray-300 pb-4 mb-5">
          <div className="fw-bolder">PPC Cost</div>
          <div className="fw-bolder fs-6 text-info">
            {sign}
            {numberformat(data?.ad_cost)}
          </div>
        </div>
      </>
    </Drawer>
  );
};

export default MoreData;

import { Button, Form, Input, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { GlobalContext } from "../../../../commonContext";
import { API_URL, AppName } from "../../../../config";
import Wrapper from "../style";

export default function (props) {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const contextValue = useContext(GlobalContext);

  useEffect(() => {
    if (localStorage.getItem("mode") === "light") {
      localStorage.setItem("mode", "light");
    }

    return () => {};
  }, []);
  const onFinish = async (values) => {
    try {
      // Make the API call
      const response = await fetch(`${API_URL}forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      });

      if (response.ok) {
        message.success("Password reset instructions sent to your email.");
      } else {
        const errorData = await response.json();
        message.error(errorData.message || "Something went wrong");
      }
    } catch (error) {
      console.error("Error occurred while calling the API:", error);
      message.error("Something went wrong");
    }
  };

  if (localStorage.getItem("token") || redirect) {
    contextValue?.updateCommonGlobalVal({
      user_: JSON.parse(localStorage.getItem("user")),
    });
    // localStorage.setItem("mode", "light");

    return <Redirect to={redirect} />;
  }

  return (
    <Wrapper id="login_" className="">
      <div className="container-login100">
        <div
          style={{
            background: "rgb(223 74 35 / 18%)",
            width: "100%",
            height: "100%",
            zIndex: 9,
          }}
        ></div>
        <div
          className="login100-more"
          style={{
            position: "absolute",
            inset: 0,
            backgroundImage: `url("https://static.wixstatic.com/media/319eb0_b4f2c46928aa425aaa5aa514171690dc~mv2.jpg/v1/fill/w_1910,h_801,al_c,q_85,enc_auto/319eb0_b4f2c46928aa425aaa5aa514171690dc~mv2.jpg`,
            // backgroundRepeat: "repeat",
            // backgroundSize: "100%",
          }}
        />
        <div className="authFadeInBottom wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
          <div
            className=" bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-20 cardAuth"
            id="div2 "
            style={{ height: "auto", overflow: "auto" }}
          >
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-450px">
              <div className="d-flex flex-center flex-column-fluid pb-10 pb-lg-15">
                <div className="form w-100">
                  <div className="text-center mb-10">
                    <img
                      className="h-55px shimmer"
                      src={
                        "https://static.wixstatic.com/media/319eb0_2887bc999c644d4c9ed17099c2a9ba4d~mv2.png/v1/fill/w_594,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ZonKeepers-logo-white%20sub.png"
                      }
                      alt
                    />
                  </div>
                  <div className="text-center mb-10">
                    <h1 className="sign-label fw-bolder mb-3">
                      Welcome to {AppName} Analytics
                    </h1>
                    <h4
                      style={{ color: "#717171" }}
                      className=" fw-bolder mb-3"
                    >
                      Already have an account? <Link to="/login">Sign in</Link>
                    </h4>
                  </div>
                  <Form
                    name="forgot-password-form"
                    onFinish={onFinish}
                    layout="vertical"
                  >
                    <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: "email",
                          message: "Please enter a valid email address!",
                        },
                        {
                          required: true,
                          message: "Please enter your email!",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>

                    <Form.Item>
                      <Button
                        style={{ width: "100%" }}
                        type="primary"
                        size="large"
                        htmlType="submit"
                      >
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

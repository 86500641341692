export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_MARKETPLACE_PARTICIPATION_SUCCESS":
    case "GET_MARKETPLACE_PARTICIPATION_ERROR":
      return {
        ...state,
        GetMarketplaceParticipationResponse: action.updatePayload,
      };
    case "GET_SP_API_LIST_SUCCESS":
    case "GET_SP_API_LIST_ERROR":
      return {
        ...state,
        GetSPAPIListResponse: action.updatePayload,
      };
    case "STORE_SP_OTHER_MARKET_SUCCESS":
    case "STORE_SP_OTHER_MARKET_ERROR":
      return {
        ...state,
        StoreSPOtherMarket: action.updatePayload,
      };
    case "GENERATE_REFRESH_SP_TOKEN_SUCCESS":
    case "GENERATE_REFRESH_SP_TOKEN_ERROR":
      return {
        ...state,
        GenerateRefreshSPTokenResponse: action.updatePayload,
      };
    case "FAKE_ACTION_MARKET_PLACE":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};

import React, { useState, createContext } from "react";

// export const GlobalSpinnerContext = createContext();
export const GlobalContext = createContext();
const GlobalCommonContextProvider = (props) => {
  const [commonGlobalVal, setCommonGlobalVal] = useState({
    window_: window,
    user_: JSON.parse(localStorage.getItem("user")),
    subscriptions: JSON.parse(localStorage.getItem("subscriptions")),
  });

  return (
    <GlobalContext.Provider
      value={{
        data: commonGlobalVal,
        toggle: localStorage.getItem("mode") || "dark",
        updateCommonGlobalVal: (rest) => {
          setCommonGlobalVal({ ...commonGlobalVal, ...rest });
        },
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalCommonContextProvider;

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import SPAPI from "../../modules/pages/setting/sp-api";

import {
  GetMarketplaceParticipationAction,
  GetSPAPIListAction,
  GenerateRefreshSPTokenAction,
  StoreSPOtherMarketplaceAction,
  fakeActionMarketPlace,
} from "../../redux/modules/setting/index.action";

const mapStateToProps = (state) => ({
  GetMarketplaceParticipationResponse:
    state?.Setting?.GetMarketplaceParticipationResponse,
  GetSPAPIListResponse: state?.Setting?.GetSPAPIListResponse,
  GenerateRefreshSPTokenResponse:
    state?.Setting?.GenerateRefreshSPTokenResponse,
  StoreSPOtherMarket: state?.Setting?.StoreSPOtherMarket,
  GenerateRefreshSPTokenResponse:
    state?.Setting?.GenerateRefreshSPTokenResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetMarketplaceParticipationAction,
      GetSPAPIListAction,
      GenerateRefreshSPTokenAction,
      StoreSPOtherMarketplaceAction,
      fakeActionMarketPlace,
    },
    dispatch
  );

const SPAPI_ = connect(mapStateToProps, mapDispatchToProps)(SPAPI);

export default SPAPI_;

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CreateUser from "../../modules/pages/auth/lib/create";
import {
  signUpUserAction,
  loginAction,
  fakeActionAuth,
} from "../../redux/modules/auth/index.action";

const mapStateToProps = (state) => ({
  SignUpUserResponse: state.Auth.SignUpUserResponse,
  LoginResponse: state.Auth.LoginResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUpUserAction,
      loginAction,
      fakeActionAuth,
    },
    dispatch
  );

const CreateUser_ = connect(mapStateToProps, mapDispatchToProps)(CreateUser);

export default CreateUser_;

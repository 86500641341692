import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  DatePicker,
  Input,
  Select,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import { MakeApiCall } from "../../../apis";
import { DateFormat, DefaultPerPage } from "../../../config";
import { TableLoading } from "../../../components/table-animation";
import dayjs from "dayjs";
import ASINTable from "../../../components/table";
import NoData from "../../../components/no-data";
import Pagination from "../../../components/pagination";
import { render } from "nprogress";
import { TableDrawer } from "../p&l-breakdown";
import Icons from "../../../components/icons";

const Inventory = () => {
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSign, setSelectedSign] = useState("USD");
  const [sign, setSign] = useState("$");
  const [currencyList, setCurrencyList] = useState([]);
  const [currencyLoading, setCurrencyLoading] = useState(true);
  const [inventoryCount, setInventoryCount] = useState({});

  const [searchText, setSearchText] = useState("");
  const [statusSelected, setStatusSelected] = useState("");

  const [listSortFilters, setListSortFilters] = useState({
    key: "",
    type: "",
  });

  const [columnsConfig, setColumnsConfig] = useState([]);
  const [configSubmitLoading, setConfigSubmitLoading] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const handleDownload = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl.split("/").pop();
    document.body.appendChild(anchor); // Append to the document
    anchor.click();
    document.body.removeChild(anchor); // Clean up
    message.destroy();
  };
  const getAllListCurrency = async () => {
    setCurrencyLoading(true);
    const response = await MakeApiCall(`list-currency-data`, "get", null, true);

    if (response?.status) {
      setCurrencyList(
        response?.data?.map((d) => ({ label: d, value: d })) || []
      );
      setCurrencyLoading(false);
    } else {
      setCurrencyLoading(false);
      setCurrencyList([]);
    }
  };
  const GetList = async (data) => {
    if (data?.export) {
      message.destroy();
      message.loading("Loading...", 0);
    } else {
      setLoading(true);
    }
    const response = await MakeApiCall(
      `fba-manage-inventory?region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&pageSize=${data?.pageSize || pageSize || 10}&page=${
        data?.page || page || 1
      }&filter_value=${data?.filter_value || searchText || ""}&export=${
        data?.export || ""
      }&daterange=${data?.start_date || ""}+-+${
        data?.end_date || ""
      }&currency_code=${data?.currency_code || ""}&sort=${
        data?.sort || ""
      }&available_stock=${
        data?.available_stock === 0 ? 0 : data?.available_stock || ""
      }`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (data?.export) {
        return handleDownload(response?.data?.file);
      }

      setList(response?.data?.records || []);
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setLoading(false);
      setSign(response?.data?.currency || "");
      setInventoryCount(response?.data?.inventory_count || "");
    } else {
      setList([]);
      setTotalPage(0);
      setLoading(false);
    }
  };
  const MarketplaceList = async (data) => {
    const response = await MakeApiCall(
      `get-user-marketplace-list`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        const salesList = response?.data?.reduce((acc, e) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region_name);

          if (
            e.region_name === "us-east-1" &&
            e.sales_channel === "Amazon.com"
          ) {
            setMarketplaceSelected({
              marketplace: e?.name,
              marketplace_id: e?.id,
              region: e?.region_name,
              sales_channel: e?.sales_channel,
            });
            setLoading(true);
            GetList({
              region: e?.region_name,
              sales_channel: e?.sales_channel,
              sort: listSortFilters?.key,
              ...filters,
              currency_code: selectedSign,
              available_stock: statusSelected,
            });
          }

          if (regionIndex === -1) {
            acc.push({
              label: e.region_name,
              options: [
                // {
                //   label: "All",
                //   value: "all" + e.region_label,
                //   region: e.region,
                //   marketplace_id: "all",
                //   marketplace: "all",
                //   sales_channel: "all",
                // },
                {
                  label: e.sales_channel,
                  value: e.id,
                  region: e.region_name,
                  marketplace_id: e?.id,
                  marketplace: e?.name,
                  sales_channel: e?.sales_channel,
                },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: e.sales_channel,
              value: e.id,
              marketplace_id: e?.id,
              region: e.region_name,
              marketplace: e?.name,
              sales_channel: e?.sales_channel,
            });
          }
          return acc;
        }, []);

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList(uniqueList);
        setMarketplaceLoading(false);
      } else {
        setLoading(false);
        setMarketplaceLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const GetConfigList = async (data) => {
    const response = await MakeApiCall(
      `get-module-config?module=inventory`,
      "get",
      null,
      true
    );

    if (response?.status) {
      try {
        setColumnsConfig(JSON.parse(response?.data?.records?.config || "[]"));
      } catch (error) {
        setColumnsConfig([]);
      }
    } else {
      setColumnsConfig([]);
    }
  };
  // FBA in transit Quantity
  const UpdateConfigList = async (data) => {
    setConfigSubmitLoading(true);
    const response = await MakeApiCall(
      `update-module-config`,
      "post",
      {
        module: "inventory",
        config: data,
      },
      true
    );

    if (response?.status) {
      setConfigSubmitLoading(false);
      try {
        setColumnsConfig(JSON.parse(response?.data?.config || "[]"));
      } catch (error) {}
      setDrawerVisible(false);
    } else {
      setConfigSubmitLoading(false);

      GetConfigList();
    }
  };

  useEffect(() => {
    GetConfigList();
    MarketplaceList();
    getAllListCurrency();
    return () => {};
  }, []);
  const listExtraProps = (type) => {
    return {
      defaultSortOrder:
        type === listSortFilters?.key?.replace("-", "")
          ? listSortFilters.type
          : [],
      sorter: () => {},
    };
  };
  const columns = [
    {
      title: "Title",
      key: "product_name",
      dataIndex: "product_name",
      hidden: false,
      ...listExtraProps("product_name"),
      render: (_, e) => {
        return (
          <a
            className="text-dark font-weight-bolder text-hover-primary fs12 sku-click"
            href="javascript:void(0)"
            title="Id Rather Be Golfing - Funny Golf Socks Novelty Christmas Gift Stocking Stuffer For Men, Women and Teens"
            data-pjax={0}
            data-sku="golfsock"
          >
            <div className="d-flex align-items-center">
              <div className="title_asin_dis">
                <div className="d-flex justify-content-start flex-column me-4">
                  <span
                    className="dashboardTitle font-weight-bolder text-hover-primary fs12 "
                    style={{ width: 450, fontWeight: 700, letterSpacing: 1 }}
                  >
                    <pre
                      style={{
                        width: "450px",
                        whiteSpace: "pre-wrap",
                        overflowWrap: "anywhere",
                        marginBottom: 0,
                        fontFamily: "Roboto",
                        fontSize: "14px",
                      }}
                    >
                      {_}
                    </pre>
                  </span>
                  <div className="d-flex flex-wrap align-items-center">
                    <span className="text-gray-700 fw-400 d-block fs12 mt-0">
                      <span className="text-gray-800 fw-boldest">SKU:</span>{" "}
                      <Tag color="blue">{e?.sku || "-"}</Tag>
                    </span>
                    <span className="text-gray-700 fw-400 d-block fs12 mt-0">
                      <span className="text-gray-800 fw-boldest">ASIN:</span>{" "}
                      <Tag color="cyan">{e?.asin || "-"}</Tag>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a>
        );
      },
    },
    // {
    //   title: "ASIN",
    //   dataIndex: "asin",
    //   key: "asin",
    //   hidden: false,
    //   ...listExtraProps("asin"),
    //   render: (e) => {
    //     return <Tag color="blue">{e}</Tag>;
    //   },
    // },
    // {
    //   title: "SKU",
    //   dataIndex: "sku",
    //   key: "sku",
    //   hidden: false,
    //   ...listExtraProps("sku"),
    //   render: (e) => {
    //     return <Tag color="geekblue">{e}</Tag>;
    //   },
    // },
    // {
    //   title: "Title",
    //   width: 450,
    //   dataIndex: "product_name",
    //   key: "product_name",
    //   hidden: false,
    //   ...listExtraProps("product_name"),
    //   render: (e) => {
    //     return (
    //       <pre
    //         style={{
    //           width: "450px",
    //           whiteSpace: "pre-wrap",
    //           overflowWrap: "anywhere",
    //           marginBottom: 0,
    //           fontFamily: "Roboto",
    //           fontSize: "14px",
    //         }}
    //       >
    //         {e}
    //       </pre>
    //     );
    //   },
    // },
    {
      title: "Price",
      dataIndex: "your_price",
      key: "your_price",
      hidden: false,
      ...listExtraProps("your_price"),
      render: (e) => {
        return (
          <span>
            {sign}
            {e}
          </span>
        );
      },
    },

    {
      title: "Total Quantity",
      dataIndex: "afn_total_quantity",
      key: "afn_total_quantity",
      hidden: false,
      ...listExtraProps("afn_total_quantity"),
    },
    {
      title: "MFN Fulfillable Quantity",
      dataIndex: "mfn_fulfillable_quantity",
      key: "mfn_fulfillable_quantity",
      hidden: false,
      ...listExtraProps("mfn_fulfillable_quantity"),
    },
    {
      title: "AFN Exists",
      dataIndex: "afn_listing_exists",
      key: "afn_listing_exists",
      hidden: false,
      ...listExtraProps("afn_listing_exists"),
    },

    {
      title: "AFN Warehouse Quantity",
      dataIndex: "afn_warehouse_quantity",
      key: "afn_warehouse_quantity",
      hidden: false,
      ...listExtraProps("afn_warehouse_quantity"),
    },
    {
      title: "AFN Fulfillable Quantity",
      dataIndex: "afn_fulfillable_quantity",
      key: "afn_fulfillable_quantity",
      hidden: false,
      ...listExtraProps("afn_fulfillable_quantity"),
    },
    {
      title: "FBA in transit Quantity",
      dataIndex: "afn_fulfillable_quantity",
      key: "afn_fulfillable_quantity",
      ...listExtraProps("afn_fulfillable_quantity"),
      hidden: false,
      render: (e) => {
        return 0;
      },
    },
    {
      title: "AFN Unsellable Quantity",
      dataIndex: "afn_unsellable_quantity",
      key: "afn_unsellable_quantity",
      hidden: false,
      ...listExtraProps("afn_unsellable_quantity"),
    },
    {
      title: "AFN Reserved Quantity",
      dataIndex: "afn_reserved_quantity",
      key: "afn_reserved_quantity",
      hidden: false,
      ...listExtraProps("afn_reserved_quantity"),
    },
    {
      title: "AFN Total Quantity",
      dataIndex: "afn_total_quantity",
      key: "afn_total_quantity",
      hidden: false,
      ...listExtraProps("afn_total_quantity"),
    },
    {
      title: "AFN Inbound Working Quantity",
      dataIndex: "afn_inbound_working_quantity",
      key: "afn_inbound_working_quantity",
      hidden: false,
      ...listExtraProps("afn_inbound_working_quantity"),
    },
    {
      title: "AFN Inbound Shipped Quantity",
      dataIndex: "afn_inbound_shipped_quantity",
      key: "afn_inbound_shipped_quantity",
      hidden: false,
      ...listExtraProps("afn_inbound_shipped_quantity"),
    },
    {
      title: "AFN Inbound Receiving Quantity",
      dataIndex: "afn_inbound_receiving_quantity",
      key: "afn_inbound_receiving_quantity",
      hidden: false,
      ...listExtraProps("afn_inbound_receiving_quantity"),
    },
    {
      title: "AFN Researching Quantity",
      dataIndex: "afn_researching_quantity",
      key: "afn_researching_quantity",
      hidden: false,
      ...listExtraProps("afn_researching_quantity"),
    },
    {
      title: "AFN Reserved Future Supply",
      dataIndex: "afn_reserved_future_supply",
      key: "afn_reserved_future_supply",
      hidden: false,
      ...listExtraProps("afn_reserved_future_supply"),
    },
    {
      title: "AFN Future Supply Buyable",
      dataIndex: "afn_future_supply_buyable",
      key: "afn_future_supply_buyable",
      hidden: false,
      ...listExtraProps("afn_future_supply_buyable"),
    },
    {
      title: "Status",
      dataIndex: "afn_fulfillable_quantity",
      key: "afn_fulfillable_quantity",
      hidden: false,
      ...listExtraProps("afn_fulfillable_quantity"),
      render: (e) => {
        return (
          <Tag bordered={false} color={parseInt(e) > 0 ? "green" : "red"}>
            {parseInt(e) > 0 ? "In Stock" : "Out Of Stock"}
          </Tag>
        );
      },
    },
  ];

  const handleOnChangeColumn = (_, __, sorter) => {
    const sort =
      sorter?.order === "ascend"
        ? sorter?.column?.dataIndex
        : sorter?.order === "descend"
        ? `-${sorter?.column?.dataIndex}`
        : "";

    setListSortFilters({
      key: sort,
      type: sorter?.order || "",
    });
    GetList({
      currency_code: selectedSign,
      page: 1,
      sort: sort,
      perPage: DefaultPerPage,
      available_stock: statusSelected,
      ...filters,
    });
  };

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);
    GetList({
      page: e,
      perPage: pageSize,
      sort: listSortFilters?.key,
      available_stock: statusSelected,
      ...filters,
      currency_code: selectedSign,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    GetList({
      currency_code: selectedSign,
      sort: listSortFilters?.key,
      available_stock: statusSelected,
      page: 1,
      perPage: e,
      ...filters,
    });
  };
  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "Last Week",
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },

    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    {
      label: "Custom Range",
      value: [0, 0],
    },
  ];

  const convertedObject = columnsConfig.reduce((acc, obj) => {
    acc[obj.title] = obj.status;
    return acc;
  }, {});

  return (
    <Wrapper className="fadeInRight px-4 mt-5" id="kt_content">
      <div className="header-fix app-container d-flex flex-stack container-fluid mt-5 mb-5">
        <div className="page-title d-flex flex-wrap me-3 flex-column justify-content-center">
          <Input
            size="large"
            style={{ width: "200px" }}
            placeholder="Search..."
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            onPressEnter={() => {
              setList([]);

              setLoading(true);
              setPage(1);
              GetList({
                currency_code: selectedSign,
                sort: listSortFilters?.key,
                available_stock: statusSelected,
                page: 1,
                ...filters,
              });
            }}
          />
        </div>

        <div className="d-flex align-items-center gap-2 gap-lg-3 fix-over">
          <Button
            onClick={() => {
              GetList({
                ...filters,
                currency_code: selectedSign,
                sort: listSortFilters?.key,
                available_stock: statusSelected,
                export: "all",
              });
            }}
            size="large"
            type="primary"
          >
            Export
          </Button>
          <Select
            className="w-175px"
            options={[
              { label: "All", value: "" },
              { label: "In Stock", value: 1 },
              { label: "Out Of Stock ", value: 0 },
            ]}
            placeholder="Select Status"
            size="large"
            value={statusSelected}
            allowClear
            onChange={(e) => {
              setStatusSelected(e);
              setLoading(true);
              GetList({
                region: marketplaceSelected?.region_name,
                sales_channel: marketplaceSelected?.sales_channel,
                sort: listSortFilters?.key,
                ...filters,
                currency_code: selectedSign,
                available_stock: e,
              });
            }}
          />
          <DatePicker.RangePicker
            format={DateFormat}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                ...filters,
                start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              };
              GetList({
                ...obj,
                currency_code: selectedSign,
                sort: listSortFilters?.key,
                available_stock: statusSelected,
              });
              setFilters(obj);
            }}
            presets={rangePresets}
            size="large"
            getPopupContainer={(target) => target.parentNode}
          />
          <Select
            placeholder="Select Currency"
            className="w-100px"
            options={currencyList}
            loading={currencyLoading}
            size="large"
            getPopupContainer={(target) => target.parentNode}
            value={selectedSign}
            onChange={(e) => {
              GetList({
                ...filters,
                currency_code: e,
                sort: listSortFilters?.key,
                available_stock: statusSelected,
              });
              setSelectedSign(e);
            }}
          />
          <Select
            placeholder="Select Marketplace"
            style={{ width: "200px" }}
            size="large"
            loading={marketplaceLoading}
            value={marketplaceSelected?.marketplace_id}
            getPopupContainer={(target) => target.parentNode}
            onChange={(e, _) => {
              const obj = {
                marketplace: _?.marketplace,
                marketplace_id: _?.marketplace_id,
                region: _?.region,
                sales_channel: _?.sales_channel,
              };
              setMarketplaceSelected(obj);
              setLoading(true);
              GetList({
                ...obj,
                sort: listSortFilters?.key,
                ...filters,
                currency_code: selectedSign,
                available_stock: statusSelected,
              });
            }}
            options={marketplaceList}
          />
          <span onClick={() => setDrawerVisible(true)}>
            <Icons type="setting" />
          </span>
        </div>
      </div>

      <div className="container-fluid" id="kt_content_container">
        <div className="row d-none g-5 g-xl-8">
          <div className="col-xl-3">
            <div className="d-flex align-items-center bg-light-danger rounded p-5 mb-7">
              <span
                class="svg-icon svg-icon-danger text-danger svg-icon-2qx"
                style={{ marginRight: "10px" }}
              >
                <svg
                  width="22"
                  style={{ transform: "scale(1)" }}
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M19.5997 3.52344H2.39639C2.09618 3.53047 1.8003 3.59658 1.52565 3.718C1.25101 3.83941 1.00298 4.01375 0.79573 4.23106C0.588484 4.44837 0.426087 4.70438 0.317815 4.98447C0.209544 5.26456 0.157521 5.56324 0.164719 5.86344C0.157521 6.16364 0.209544 6.46232 0.317815 6.74241C0.426087 7.0225 0.588484 7.27851 0.79573 7.49581C1.00298 7.71312 1.25101 7.88746 1.52565 8.00888C1.8003 8.1303 2.09618 8.19641 2.39639 8.20344H19.5997C19.8999 8.19641 20.1958 8.1303 20.4704 8.00888C20.7451 7.88746 20.9931 7.71312 21.2004 7.49581C21.4076 7.27851 21.57 7.0225 21.6783 6.74241C21.7866 6.46232 21.8386 6.16364 21.8314 5.86344C21.8386 5.56324 21.7866 5.26456 21.6783 4.98447C21.57 4.70438 21.4076 4.44837 21.2004 4.23106C20.9931 4.01375 20.7451 3.83941 20.4704 3.718C20.1958 3.59658 19.8999 3.53047 19.5997 3.52344Z"
                    fill="currentColor"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M2.39453 8.20361L4.01953 18.3111C4.15644 19.145 4.58173 19.9043 5.22121 20.4567C5.8607 21.009 6.6738 21.3194 7.5187 21.3336H14.5712C15.4215 21.3202 16.2395 21.006 16.8801 20.4468C17.5207 19.8875 17.9424 19.1193 18.0704 18.2786L19.5979 8.20361H2.39453ZM9.28453 16.3178C9.28453 16.5333 9.19893 16.7399 9.04656 16.8923C8.89418 17.0447 8.68752 17.1303 8.47203 17.1303C8.25654 17.1303 8.04988 17.0447 7.89751 16.8923C7.74513 16.7399 7.65953 16.5333 7.65953 16.3178V12.4069C7.65953 12.1915 7.74513 11.9848 7.89751 11.8324C8.04988 11.68 8.25654 11.5944 8.47203 11.5944C8.68752 11.5944 8.89418 11.68 9.04656 11.8324C9.19893 11.9848 9.28453 12.1915 9.28453 12.4069V16.3178ZM14.322 16.3178C14.322 16.5333 14.2364 16.7399 14.0841 16.8923C13.9317 17.0447 13.725 17.1303 13.5095 17.1303C13.294 17.1303 13.0874 17.0447 12.935 16.8923C12.7826 16.7399 12.697 16.5333 12.697 16.3178V12.4069C12.697 12.1915 12.7826 11.9848 12.935 11.8324C13.0874 11.68 13.294 11.5944 13.5095 11.5944C13.725 11.5944 13.9317 11.68 14.0841 11.8324C14.2364 11.9848 14.322 12.1915 14.322 12.4069V16.3178Z"
                    fill="currentColor"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M17.3895 4.87755C17.2529 4.87776 17.1185 4.84303 16.999 4.77667C16.8796 4.71031 16.7791 4.61452 16.707 4.49839L14.5945 1.24839C14.488 1.07063 14.4544 0.858502 14.5009 0.656521C14.5473 0.45454 14.6702 0.2784 14.8437 0.165055C15.0215 0.0626479 15.2311 0.0303209 15.4315 0.0744071C15.6319 0.118493 15.8086 0.235816 15.927 0.403388L18.0395 3.70755C18.1434 3.88599 18.1755 4.09728 18.1292 4.2985C18.0829 4.49972 17.9618 4.67577 17.7904 4.79089C17.6659 4.85225 17.5282 4.88202 17.3895 4.87755Z"
                    fill="currentColor"
                    fill-opacity="0.8"
                  />
                  <path
                    d="M4.49988 4.8885C4.34679 4.8928 4.19591 4.85131 4.06655 4.76933C3.89514 4.65422 3.77399 4.47817 3.72771 4.27694C3.68143 4.07572 3.71349 3.86443 3.81738 3.686L5.98405 0.435999C6.09739 0.262485 6.27353 0.13961 6.47551 0.0931545C6.6775 0.0466989 6.88962 0.0802727 7.06738 0.186832C7.23676 0.303623 7.35627 0.479597 7.40239 0.680101C7.4485 0.880606 7.41788 1.09111 7.31655 1.27017L5.20405 4.52017C5.12881 4.63747 5.0243 4.73313 4.90082 4.79773C4.77733 4.86232 4.63914 4.8936 4.49988 4.8885Z"
                    fill="currentColor"
                    fill-opacity="0.8"
                  />
                </svg>
              </span>
              {/*begin::Title*/}
              <div className="flex-grow-1 me-2">
                <a
                  href="#"
                  className="fw-bold text-gray-800 text-hover-primary fs-6"
                >
                  Inventory In stock
                </a>
              </div>
              {/*end::Title*/}
              {/*begin::Lable*/}
              <span className="fw-bold text-danger py-1">
                {inventoryCount?.stock_inventory || 0}
              </span>
              {/*end::Lable*/}
            </div>
          </div>
          <div className="col-xl-3">
            <div
              className="d-flex align-items-center  rounded p-5 "
              style={{ background: "#eaeeff" }}
            >
              <span className="svg-icon-2qx" style={{ marginRight: "10px" }}>
                <svg
                  width={24}
                  style={{ transform: "scale(1)" }}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.3"
                    d="M21.25 18.525L13.05 21.825C12.35 22.125 11.65 22.125 10.95 21.825L2.75 18.525C1.75 18.125 1.75 16.725 2.75 16.325L4.04999 15.825L10.25 18.325C10.85 18.525 11.45 18.625 12.05 18.625C12.65 18.625 13.25 18.525 13.85 18.325L20.05 15.825L21.35 16.325C22.35 16.725 22.35 18.125 21.25 18.525ZM13.05 16.425L21.25 13.125C22.25 12.725 22.25 11.325 21.25 10.925L13.05 7.62502C12.35 7.32502 11.65 7.32502 10.95 7.62502L2.75 10.925C1.75 11.325 1.75 12.725 2.75 13.125L10.95 16.425C11.65 16.725 12.45 16.725 13.05 16.425Z"
                    fill="#0d1a3f"
                  />
                  <path
                    d="M11.05 11.025L2.84998 7.725C1.84998 7.325 1.84998 5.925 2.84998 5.525L11.05 2.225C11.75 1.925 12.45 1.925 13.15 2.225L21.35 5.525C22.35 5.925 22.35 7.325 21.35 7.725L13.05 11.025C12.45 11.325 11.65 11.325 11.05 11.025Z"
                    fill="#0d1a3f"
                  />
                </svg>
              </span>
              {/*begin::Title*/}
              <div className="flex-grow-1 me-2">
                <a
                  href="#"
                  className="fw-bold text-gray-800 text-hover-primary fs-6"
                >
                  Inventory Out of stock
                </a>
              </div>
              {/*end::Title*/}
              {/*begin::Lable*/}
              <span className="fw-bold text-info py-1">
                {inventoryCount?.out_of_stock_inventory || 0}
              </span>
              {/*end::Lable*/}
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-responsive">
              {loading ? (
                <TableLoading
                  id="test-table"
                  columns={[1, 2, 3, 4, 5]}
                  checkBoxVal={true}
                  actions={[1, 2]}
                />
              ) : list?.length !== 0 ? (
                <ASINTable
                  dataSource={list}
                  loading={loading}
                  onChange={handleOnChangeColumn}
                  columns={columns?.filter((d) => convertedObject?.[d?.title])}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                />
              ) : (
                <NoData />
              )}
            </div>
            <Pagination
              loading={loading || list?.length === 0}
              pageSize={pageSize}
              page={page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            />
          </div>
        </div>
      </div>
      <TableDrawer
        columns={columnsConfig}
        setColumns={setColumnsConfig}
        UpdateConfigList={UpdateConfigList}
        configSubmitLoading={configSubmitLoading}
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        GetConfigList={GetConfigList}
      />
    </Wrapper>
  );
};

export default Inventory;

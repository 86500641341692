import axios from "axios";
import { API_URL } from "../config";

const getToken = () => localStorage.getItem("token");
// Common function for making API calls
export const MakeApiCall = async (
  url,
  method = "get",
  data = null,
  withBearer = false
) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    if (withBearer) {
      // Assuming you have a way to retrieve the authentication token
      if (getToken()) {
        headers["Authorization"] = `Bearer ${getToken()}`;
      }
    }
    let url_ = API_URL + url;
    const response = await axios({
      method,
      url: url_,
      data: data ? data : null,
      headers,
    });

    return response.data;
  } catch (error) {
    if (error.response?.status === 401) {
      localStorage.clear();
      window.location.assign("/login");
      return;
    }
    // Handle errors here (e.g., log them or throw a custom error)
    throw { message: error?.message };
  }
};

import styled from "styled-components";

export const Wrapper = styled.div``;
export const AddNewPlanWrapper = styled.div`
  .ant-input-affix-wrapper {
    height: 58px;
  }
  .ant-input-group-addon {
    .ant-form-item {
      width: 90px;
      margin-bottom: 0;
    }
  }
`;

import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Empty,
  Input,
  Progress,
  Segmented,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import React, { useEffect } from "react";
import Icons from "../../../components/icons";
import dayjs from "dayjs";

import { useState } from "react";
import { Wrapper } from "../dashboard/style";
import { MakeApiCall } from "../../../apis";
import MoreData from "./lib/more-data";
import { DateFormat, numberformat } from "../../../config";
const { Option } = Select;
const Dashboard = () => {
  const [typeView, setTypeView] = useState("Grid");

  const [filters, setFilters] = useState({
    start_date: dayjs().add(-30, "d").format("YYYY-MM-DD"),
    end_date: dayjs().format("YYYY-MM-DD"),
  });
  const [tilesLoading, setTilesLoading] = useState(true);
  const [productFilter, setProductFilter] = useState([]);
  const [tilesData, setTilesData] = useState({});
  const [sign, setSign] = useState("");

  const [searchText, setSearchText] = useState("");

  const [topProductData, setTopProductData] = useState([]);
  const [topProductLoading, setTopProductLoading] = useState(true);
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [moreData, setMoreData] = useState(false);
  const [moreDataSelected, setMoreDataSelected] = useState({});

  const [selectedSign, setSelectedSign] = useState("USD");

  const [currencyList, setCurrencyList] = useState([]);

  const [currencyLoading, setCurrencyLoading] = useState(true);

  const DashboardTiles = async (data) => {
    setTilesLoading(true);
    const response = await MakeApiCall(
      `dashboard-tiles-data?daterange=${
        data?.start_date || filters?.start_date || ""
      }+-+${data?.end_date || filters?.end_date || ""}&region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&filter_value=${data?.filter_value || searchText || ""}&currency_code=${
        data?.currency_code || "USD"
      }&asin=${data?.asin?.join(",") || ""}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      setSign(response?.data?.currency || "");
      setTilesData(response?.data?.tilesData || {});
      setTilesLoading(false);
    } else {
      setTilesLoading(false);
      setTilesData({});
    }
  };
  const getAllListCurrency = async (data) => {
    setCurrencyLoading(true);
    const response = await MakeApiCall(`list-currency-data`, "get", null, true);

    if (response?.status) {
      setCurrencyList(
        response?.data?.map((d) => ({ label: d, value: d })) || []
      );
      setCurrencyLoading(false);
    } else {
      setCurrencyLoading(false);
      setCurrencyList([]);
    }
  };
  const handleDownload = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl.split("/").pop();
    document.body.appendChild(anchor); // Append to the document
    anchor.click();
    document.body.removeChild(anchor); // Clean up
    message.destroy();
  };
  const DashboardTopProduct = async (data) => {
    if (data?.export) {
      message.destroy();
      message.loading("Loading...", 0);
    } else {
      setTopProductLoading(true);
    }
    const response = await MakeApiCall(
      `dashboard-top-products?daterange=${
        data?.start_date || filters?.start_date || ""
      }+-+${data?.end_date || filters?.end_date || ""}&region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&filter_value=${data?.filter_value || searchText || ""}&export=${
        data?.export || ""
      }&currency_code=${data?.currency_code || "USD"}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (data?.export) {
        return handleDownload(response?.data?.file);
      }

      setSign(response?.data?.currency || "");
      setTopProductData(response?.data?.topProductData || []);
      setTopProductLoading(false);
    } else {
      setTopProductLoading(false);
      setTopProductData([]);
    }
  };
  const MarketplaceList = async (data) => {
    const response = await MakeApiCall(
      `get-user-marketplace-list`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        const salesList = response?.data?.reduce((acc, e) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region_name);

          if (
            e.region_name === "us-east-1" &&
            e.sales_channel === "Amazon.com"
          ) {
            setMarketplaceSelected({
              marketplace: e?.name,
              marketplace_id: e?.id,
              region: e?.region_name,
              sales_channel: e?.sales_channel,
            });
            DashboardTopProduct({
              region: e?.region_name,
              sales_channel: e?.sales_channel,
              currency_code: selectedSign,
            });
            DashboardTiles({
              region: e?.region_name,
              sales_channel: e?.sales_channel,
              currency_code: selectedSign,
              asin: productFilter,
            });
          }

          if (regionIndex === -1) {
            acc.push({
              label: e.region_name,
              options: [
                // {
                //   label: "All",
                //   value: "all" + e.region_label,
                //   region: e.region,
                //   marketplace_id: "all",
                //   marketplace: "all",
                //   sales_channel: "all",
                // },
                {
                  label: e.sales_channel,
                  value: e.id,
                  region: e.region,
                  marketplace_id: e?.id,
                  marketplace: e?.name,
                  sales_channel: e?.sales_channel,
                },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: e.sales_channel,
              value: e.id,
              marketplace_id: e?.id,
              region: e.region,
              marketplace: e?.name,
              sales_channel: e?.sales_channel,
            });
          }
          return acc;
        }, []);

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList(uniqueList);
        setMarketplaceLoading(false);
      } else {
        setTopProductLoading(false);
        setTilesLoading(false);
        setMarketplaceLoading(false);
      }
    } else {
    }
  };

  const mountAction = async () => {
    await MarketplaceList();
    await getAllListCurrency();
  };
  useEffect(() => {
    mountAction();
    return () => {};
  }, []);

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs(), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().subtract(1, "day"), dayjs().subtract(1, "day")],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "Last Week",
      value: [
        dayjs().subtract(1, "week").startOf("week"),
        dayjs().subtract(1, "week").endOf("week"),
      ],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },

    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    {
      label: "Custom Range",
      value: [0, 0],
    },
  ];

  const calculatePercentage = (status, value) => {
    return numberformat(value || 0);
  };

  const customFormat = (percent, value) =>
    `${calculatePercentage(percent, value)}%`;

  const columns = [
    {
      title: "Product",

      width: 550,
      render: (d) => {
        return (
          <div className="d-flex align-items-center bg-light p-2 rounded">
            <div className="symbol symbol-60px overflow-hidden me-3">
              <img src={d?.image || "https://via.placeholder.com/100"} alt />
            </div>
            {/*end::Avatar*/}
            {/*begin::User details*/}
            <div className="d-flex flex-column">
              <a
                href
                className="text-gray-800 fs-6 text-hover-info mb-1 fw-bolder text-one min-w-200px"
              >
                {d?.title || "-"}
              </a>
              <span>
                <b>SKU:</b> {d?.sku || "-"}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      sorter: (a, b) => a.asin.localeCompare(b.asin),
      render: (d) => {
        return <span>{d || "-"}</span>;
      },
    },
    {
      title: "Ad Cost",
      dataIndex: "ad_cost",
      key: "ad_cost",
      sorter: (a, b) => parseFloat(a.ad_cost) - parseFloat(b.ad_cost),
      render: (d) => {
        return (
          <span>
            {sign}
            {d || "0"}
          </span>
        );
      },
    },
    {
      title: "Ad Order",
      dataIndex: "ad_order",
      key: "ad_order",
      sorter: (a, b) => parseFloat(a.ad_order) - parseFloat(b.ad_order),
      render: (d) => {
        return <span>{d || "0"}</span>;
      },
    },
    {
      title: "Ad Sales",
      dataIndex: "ad_sales",
      key: "ad_sales",
      sorter: (a, b) => parseFloat(a.ad_sales) - parseFloat(b.ad_sales),
      render: (d) => {
        return <span>{d || "0"}</span>;
      },
    },
    {
      title: "Net Margin",
      dataIndex: "net_margin",
      key: "net_margin",
      sorter: (a, b) => parseFloat(a.net_margin) - parseFloat(b.net_margin),
      render: (d) => {
        return <span>{d || "0"}%</span>;
      },
    },
    {
      title: "Net Profit",
      dataIndex: "net_profit",
      key: "net_profit",
      sorter: (a, b) => parseFloat(a.net_profit) - parseFloat(b.net_profit),
      render: (d) => {
        return (
          <span>
            {sign}
            {d || "0"}
          </span>
        );
      },
    },
    {
      title: "Order Count",
      dataIndex: "order_count",
      key: "order_count",
      sorter: (a, b) => parseFloat(a.order_count) - parseFloat(b.order_count),
      render: (d) => {
        return <span>{d || "0"}</span>;
      },
    },
    {
      title: "Order Quantity",
      dataIndex: "order_quantity",
      key: "order_quantity",
      sorter: (a, b) =>
        parseFloat(a.order_quantity) - parseFloat(b.order_quantity),
      render: (d) => {
        return <span>{d || "0"}</span>;
      },
    },
    {
      title: "Order Sales",
      dataIndex: "order_sales",
      key: "order_sales",
      sorter: (a, b) => parseFloat(a.order_sales) - parseFloat(b.order_sales),
      render: (d) => {
        return (
          <span>
            {sign}
            {d || "0"}
          </span>
        );
      },
    },
  ];

  return (
    <Wrapper className="px-4 mt-5">
      <div className="app-container header-fix d-flex flex-stack justify-content-end">
        <div className="d-flex align-items-center gap-2 gap-lg-3 fix-over flex-wrap">
          <Tooltip
            title={
              isOpenDropdown ? (
                "Working on"
              ) : (
                <div>
                  {topProductData?.filter((r) =>
                    productFilter?.includes(r?.asin)
                  )?.length === 0
                    ? "No Products Selected"
                    : topProductData
                        ?.filter((r) => productFilter?.includes(r?.asin))
                        ?.map((d, _, row) => (
                          <div
                            style={{
                              borderBottom:
                                row?.length === _ + 1
                                  ? "none"
                                  : "1px solid grey",
                              marginBottom:
                                row?.length === _ + 1 ? "0px" : "10px",
                              marginTop: "10px",
                              paddingBottom: "10px",
                            }}
                            className="d-flex"
                          >
                            <img
                              style={{
                                width: "33px",
                                marginRight: "10px",
                                borderRadius: "7px",
                              }}
                              src={d?.image}
                            />
                            <div className="ellipsis">{d?.title}</div>
                          </div>
                        ))}
                </div>
              )
            }
            placement="bottom"
          >
            <Select
              placeholder="Select Product"
              className="w-250px"
              mode="multiple"
              dropdownStyle={{
                minWidth: "500px",
              }}
              maxTagCount={"responsive"}
              getPopupContainer={(target) => target.parentNode}
              size="large"
              onChange={(e) => {
                DashboardTiles({
                  ...marketplaceSelected,
                  ...filters,
                  currency_code: selectedSign,
                  asin: e,
                });
                setProductFilter(e);
              }}
              value={productFilter}
              onDropdownVisibleChange={(e) => setIsOpenDropdown(e)}
            >
              {topProductData?.map((d, i) => (
                <Option key={i} value={d?.asin}>
                  <img
                    style={{
                      width: "33px",
                      marginRight: "10px",
                      borderRadius: "7px",
                    }}
                    src={d?.image}
                  />
                  <b>{d?.title}</b>
                </Option>
              ))}
            </Select>
          </Tooltip>
          <DatePicker.RangePicker
            presets={rangePresets}
            getPopupContainer={(target) => target.parentNode}
            value={
              filters?.start_date && filters?.end_date
                ? [
                    dayjs(filters?.start_date, "YYYY-MM-DD"),
                    dayjs(filters?.end_date, "YYYY-MM-DD"),
                  ]
                : []
            }
            onChange={(e) => {
              const obj = {
                start_date: e?.[0] ? dayjs(e?.[0]).format("YYYY-MM-DD") : null,
                end_date: e?.[1] ? dayjs(e?.[1]).format("YYYY-MM-DD") : null,
              };
              DashboardTopProduct({
                ...obj,
                ...marketplaceSelected,
                currency_code: selectedSign,
              });
              DashboardTiles({
                ...obj,
                ...marketplaceSelected,
                currency_code: selectedSign,
                asin: productFilter,
              });
              setFilters(obj);
            }}
            format={DateFormat}
            size="large"
          />

          <Select
            placeholder="Select Currency"
            className="w-100px"
            options={currencyList}
            loading={currencyLoading}
            getPopupContainer={(target) => target.parentNode}
            size="large"
            value={selectedSign}
            onChange={(e) => {
              DashboardTopProduct({
                ...marketplaceSelected,
                ...filters,
                currency_code: e,
              });
              DashboardTiles({
                ...marketplaceSelected,
                ...filters,
                asin: productFilter,
                currency_code: e,
              });
              setSelectedSign(e);
            }}
          />
          <Select
            placeholder="Select Marketplace"
            style={{ width: "200px" }}
            getPopupContainer={(target) => target.parentNode}
            size="large"
            loading={marketplaceLoading}
            value={marketplaceSelected?.marketplace_id}
            onChange={(e, _) => {
              const obj = {
                marketplace: _?.marketplace,
                marketplace_id: _?.marketplace_id,
                region: _?.region,
                sales_channel: _?.sales_channel,
              };
              setMarketplaceSelected(obj);
              DashboardTopProduct({
                ...obj,
                ...filters,
                currency_code: selectedSign,
              });
              DashboardTiles({
                ...obj,
                ...filters,
                asin: productFilter,
                currency_code: selectedSign,
              });
            }}
            options={marketplaceList}
          />
          {console.log(marketplaceList, "marketplaceList")}
        </div>
      </div>
      <div
        className="row"
        style={{
          padding: "15px 0px",
          filter: tilesLoading ? "blur(5px)" : "blur(0px)",
        }}
      >
        {[
          {
            label: "Total Orders",
            value: "order",
            color: "#081437",
          },
          {
            label: "Units",
            value: "units",
            color: "#f06432",
          },
          {
            label: "Sales",
            value: "sales",
            color: "#51cd89",
          },
          {
            label: "Expenses",
            value: "expenses",
            color: "#f1416c",
          },
        ]?.map((d, i) => (
          <div
            className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 mb-lg-3 mb-sm-3 mb-md-3"
            key={i}
          >
            <div className="card-dashboard">
              <div className="card-details">
                <div>
                  <span style={{ fontWeight: "500", fontSize: "large" }}>
                    {d?.label}
                  </span>
                  <div>
                    <b style={{ fontSize: "24px" }}>
                      {d?.value === "units" || d?.value === "order" ? "" : sign}

                      {numberformat(tilesData?.[d?.value])}
                    </b>
                  </div>
                </div>
                <div className={`icons${i + 1}`}>
                  <Icons type={d?.value} />
                </div>
              </div>
              <div className="card-graph" style={{ marginTop: "10px" }}>
                <div style={{ color: "#8f8f8f" }}>
                  Comparison to Previous Period
                </div>

                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    width: "90%",
                  }}
                >
                  <Progress
                    percent={tilesData?.[d?.value + "_percentile"] || 0}
                    strokeColor={{
                      from: d?.color,
                      to: d?.color,
                    }}
                    status="active"
                    format={(e) =>
                      customFormat(e, tilesData?.[d?.value + "_percentile"])
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        className="row gy-5 g-xl-5"
        style={{ filter: topProductLoading ? "blur(5px)" : "blur(0px)" }}
      >
        {/*begin::Col*/}
        <div className="col-xxl-12">
          {/*begin::Tables Widget 9*/}
          <div className="card card-xxl-stretch mb-5 mb-xl-8">
            {/*begin::Header*/}
            <div className="card-header pt-5 border-bottom-0">
              {/*begin::Title*/}
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold text-dark">
                  Top Selling Products
                </span>
                <span className="text-gray-400 pt-2 fw-semibold fs-6">
                  Products by Units Sold
                </span>
              </h3>
              {/*end::Title*/}
              {/*begin::Toolbar*/}
              <div className="card-toolbar">
                <Segmented
                  onChange={(e) => setTypeView(e)}
                  value={typeView}
                  options={[
                    {
                      value: "List",
                      icon: <BarsOutlined />,
                    },
                    {
                      value: "Grid",
                      icon: <AppstoreOutlined />,
                    },
                  ]}
                />
                <Button
                  onClick={() => {
                    DashboardTopProduct({
                      ...marketplaceSelected,
                      ...filters,
                      currency_code: selectedSign,
                      export: "all",
                    });
                  }}
                  className="ms-3"
                  type="primary"
                >
                  Export
                </Button>
              </div>
              {/*end::Toolbar*/}
            </div>
            {/*end::Header*/}
            {/*begin::Body*/}
            <div className="card-body py-3">
              <div className="tab-content px-0">
                {topProductData?.length === 0 ? (
                  <Empty />
                ) : typeView === "List" ? (
                  <div className>
                    {/*begin::Table container*/}
                    <div className="table-responsive">
                      {/*begin::Table*/}
                      <Table
                        dataSource={
                          productFilter?.length === 0
                            ? topProductData
                            : topProductData?.filter((d) =>
                                productFilter?.includes(d?.asin)
                              )
                        }
                        columns={columns}
                        scroll={{ x: "max-content" }}
                      />

                      {/*end::Table*/}
                    </div>
                    {/*end::Table container*/}
                  </div>
                ) : (
                  <div className>
                    <div className="row mb-10 mt-0 g-7 gx-7">
                      {(productFilter?.length === 0
                        ? topProductData
                        : topProductData?.filter((d) =>
                            productFilter?.includes(d?.asin)
                          )
                      )?.map((d, i) => (
                        <div key={i} className="col-xl-4 col-lg-6 col-md-6">
                          <div className="card overlay overflow-hidden bdr-box">
                            <div className="card-body p-0">
                              <div className="overlay-wrapper  min-h-125px p-5">
                                {/*begin::Ribbon*/}
                                <div className="ribbon ribbon-triangle ribbon-top-start border-info">
                                  <div className="ribbon-label ">{i + 1}</div>
                                </div>
                                {/*end::Ribbon*/}
                                <div className="text-start d-flex">
                                  <div className="symbol symbol-100px overflow-hidden me-3">
                                    <img
                                      src={
                                        d?.image ||
                                        "https://via.placeholder.com/100"
                                      }
                                      className=" bg-light"
                                      alt
                                      onError={(e) =>
                                        (e.target.src =
                                          "https://via.placeholder.com/100")
                                      }
                                    />
                                  </div>
                                  <div className="d-flex flex-column">
                                    <a
                                      href
                                      className="text-gray-800 fs-6 text-hover-info mb-3 fw-bolder text-two min-w-200px"
                                    >
                                      {d?.title || "-"}
                                    </a>
                                    <span className="mb-1 fs-7">
                                      <b>SKU:</b> {d?.sku || "-"}
                                    </span>
                                    <span className="fs-7">
                                      <b>ASIN:</b> {d?.asin || "-"}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="overlay-layer align-items-center bg-light bg-opacity-25  p-5 pe-0 d-flex"
                                style={{ Opacity: 1, zIndex: 9 }}
                              >
                                <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setMoreDataSelected(d);
                                    setMoreData(true);
                                  }}
                                >
                                  View Details
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/*end::Tab pane*/}
              </div>
            </div>
            {/*begin::Body*/}
          </div>
          {/*end::Tables Widget 9*/}
        </div>
        {/*end::Col*/}
      </div>
      <MoreData
        show={moreData}
        data={moreDataSelected}
        sign={sign}
        onHide={() => {
          setMoreData(false);
          setMoreDataSelected({});
        }}
      />
    </Wrapper>
  );
};

export default Dashboard;

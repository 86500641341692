export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "AMAZON_CREDENTIALS_LIST_SUCCESS":
    case "AMAZON_CREDENTIALS_LIST_ERROR":
      return {
        ...state,
        AmazonCredentialsListResponse: action.updatePayload,
      };
    case "AMAZON_CREDENTIALS_SKIP_PAGE_SUCCESS":
    case "AMAZON_CREDENTIALS_SKIP_PAGE_ERROR":
      return {
        ...state,
        AmazonCredentialsSkipPageResponse: action.updatePayload,
      };
    case "GENERATE_REFRESH_AD_TOKEN_SUCCESS":
    case "GENERATE_REFRESH_AD_TOKEN_ERROR":
      return {
        ...state,
        GenerateRefreshAdTokenResponse: action.updatePayload,
      };
    case "GET_MARKETPLACE_LIST_AD_SUCCESS":
    case "GET_MARKETPLACE_LIST_AD_ERROR":
      return {
        ...state,
        GetMarketplaceListResponse: action.updatePayload,
      };
    case "SAVE_AD_PROFILE_SUCCESS":
    case "SAVE_AD_PROFILE_ERROR":
      return {
        ...state,
        SaveAdProfileResponse: action.updatePayload,
      };

    case "FAKE_ACTION_AMAZON_CREDENTIALS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};

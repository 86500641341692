import { Button, Form, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { AppName, adminMenus, testArr } from "../../../config";
import Wrapper, { NewWrapper } from "./style";
import { useContext } from "react";
import { GlobalContext } from "../../../commonContext";

export default function (props) {
  const { loginAction, fakeActionAuth } = props;

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const LoginResponse = useSelector((state) => state.Auth.LoginResponse || {});
  const contextValue = useContext(GlobalContext);

  useEffect(() => {
    if (localStorage.getItem("mode") === "light") {
      localStorage.setItem("mode", "light");
    }

    return () => {};
  }, []);

  const getRedirectPath = (data) => {
    const { user_data, advetising, sp_credential, service } = data;
    const { credential_skip, u_type } = user_data;
    const advetising_ =
      u_type === 1 ? 1 : credential_skip === 1 ? 1 : advetising;
    const sp_credential_ = u_type === 1 ? 1 : sp_credential;
    if (u_type === 1) {
      return "/manage-seller-users";
    } else if (service?.length === 0) {
      return "/my-subscriptions";
    } else if (sp_credential_ === 0) {
      return "/setting/sp-api";
    } else if (sp_credential_ === 1 && advetising_ === 0) {
      return "/setting/amazon-credentials";
    } else if (
      !service.some((service) => service?.slug === "/profit-&-loss-analytics")
    ) {
      return service[0]?.slug;
    }

    return "/profit-&-loss-analytics";
  };

  useEffect(() => {
    if (LoginResponse?.status === true) {
      const { advetising, sp_credential } = LoginResponse?.data;
      const userType = LoginResponse?.data?.user_data?.u_type;
      const advetising_ =
        userType === 1
          ? 1
          : LoginResponse?.data?.user_data?.credential_skip === 1
          ? 1
          : advetising;
      const sp_credential_ = userType === 1 ? 1 : sp_credential;
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...LoginResponse?.data?.user_data,
          advetising: advetising_,
          sp_credential: sp_credential_,
        })
      );
      localStorage.setItem("token", LoginResponse?.data?.auth_token);
      localStorage.setItem(
        "subscriptions",
        JSON.stringify({
          ...LoginResponse?.data?.subscriptions,
          service: LoginResponse?.data?.service,
        })
      );
      localStorage.setItem("userType", userType);
      localStorage.setItem(
        "menusList",
        JSON.stringify(userType == 1 ? adminMenus : testArr)
      );
      localStorage.setItem("mode", "light");
      contextValue?.updateCommonGlobalVal({
        user_: {
          ...LoginResponse?.data?.user_data,
          advetising: advetising_,
          sp_credential: sp_credential_,
        },
        subscriptions: {
          ...LoginResponse?.data?.subscriptions,
          service: LoginResponse?.data?.service,
        },
      });
      setLoading(false);
      message.destroy();
      message.success(LoginResponse?.message);
      console.log(
        getRedirectPath(LoginResponse?.data),
        "getRedirectPath(LoginResponse?.data)"
      );
      setRedirect(getRedirectPath(LoginResponse?.data));
      // setRedirect(

      //   `${
      //     userType == 1
      //       ? "/manage-seller-users"
      //       : sp_credential === 0
      //       ? "/setting/sp-api"
      //       : advetising === 0 &&
      //         LoginResponse?.data?.user_data?.credential_skip === 0
      //       ? "/setting/amazon-credentials"
      //       : LoginResponse?.data?.service?.length === 0
      //       ? "/my-subscriptions"
      //       : LoginResponse?.data?.service?.filter(
      //           (d) => d?.slug === "/profit-&-loss-analytics"
      //         )?.length === 0
      //       ? LoginResponse?.data?.service?.[0]?.slug
      //       : "/profit-&-loss-analytics"
      //   }`
      // );
      fakeActionAuth("LoginResponse");
    } else if (LoginResponse?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(LoginResponse?.message || "Something Went Wrong");
      fakeActionAuth("LoginResponse");
    }
  }, [LoginResponse]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      localStorage.setItem("mode", "light");
    }
  });

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      const { token, user, userType, plan, menusList } = e?.storageArea;
      if (token && user && userType && plan && menusList) {
        localStorage.setItem("mode", "light");
        // setRedirect(true);
      }
    });

    return () => {
      window.removeEventListener("storage", (e) => {});
    };
  }, []);

  const isLogin = (value) => {
    setLoading(true);
    loginAction(value);
  };

  const eventLogin = (e) => {
    if (e.key === "Enter" && !loading) {
      const userId1 = document.getElementById("email");
      const password2 = document.getElementById("password");

      if (userId1 !== null && password2 !== null) {
        if (userId1.value !== "" && password2.value !== "") {
          const eventLoading = {
            email: userId1.value,
            password: password2.value,
          };
          isLogin(eventLoading);
        }
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keyup", eventLogin);
    return () => window.removeEventListener("keyup", eventLogin);
  }, []);

  if (localStorage.getItem("token") || redirect) {
    // contextValue?.updateCommonGlobalVal({
    //   user_: JSON.parse(localStorage.getItem("user")),
    // });
    // localStorage.setItem("mode", "light");

    return <Redirect to={redirect} />;
  }

  if (1 === 1) {
    return (
      <NewWrapper>
        <section
          className="fxt-template-animation fxt-template-layout34 loaded"
          style={{ backgroundImage: 'url("/assets/bg1.png")' }}
        >
          <div className="fxt-shape">
            <div className="fxt-transformX-L-50  fxt-transition-delay-1">
              <img src="/assets/shape1.png" alt="Shape" />
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-8">
                <div className="fxt-column-wrap justify-content-between">
                  <div className="fxt-animated-img">
                    <div className="fxt-transformX-L-50 fxt-transition-delay-10">
                      <img
                        className="slide-box"
                        src="/assets/auth-bg.png"
                        alt="Animated Image"
                      />
                    </div>
                  </div>
                  <div className="fxt-transformX-L-50 fxt-transition-delay-3">
                    <a className="fxt-logo logo-animation">
                      <img
                        width={300}
                        className="slide-box"
                        src="/assets/full-logo-dark.webp"
                        alt="Logo"
                      />
                    </a>
                  </div>
                  <div className="fxt-transformX-L-50 logo-animation fxt-transition-delay-5">
                    <div className="fxt-middle-content">
                      <h1 className="fxt-main-title">
                        Welcome to ZonKeepers Analytics
                      </h1>
                      <div className="fxt-switcher-description1">
                        If you don’t have an account You can
                        <Link to="/signup" className="fxt-switcher-text ms-2">
                          Sign Up
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="fxt-column-wrap justify-content-center">
                  <div className="fxt-form">
                    <div method="POST">
                      <Form
                        form={form}
                        name="login"
                        scrollToFirstError
                        layout="vertical"
                        autoComplete="new-password"
                      >
                        <Form.Item
                          name="email"
                          label="E-mail"
                          rules={[
                            {
                              type: "email",
                              message: "The input is not valid E-mail!",
                            },
                            {
                              required: true,
                              message: "Please input your E-mail!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input
                            size="large"
                            autoFocus
                            placeholder="E-mail"
                            id="email"
                            autoComplete="new-password"
                          />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          label="Password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your password!",
                            },
                          ]}
                          hasFeedback
                        >
                          <Input.Password
                            id="password"
                            size="large"
                            autoComplete="new-password"
                            placeholder="Password"
                          />
                        </Form.Item>
                      </Form>
                      <div className="form-group">
                        <div className="fxt-switcher-description2 text-right">
                          <Link
                            to="/forgot-password"
                            className="fxt-switcher-text"
                          >
                            Recovery Password
                          </Link>
                        </div>
                      </div>
                      <div className="form-group">
                        <Button
                          loading={loading}
                          onClick={() => {
                            form
                              .validateFields()
                              .then((values) => {
                                isLogin(values);
                              })
                              .catch((info) => {});
                          }}
                          size="large"
                          type="primary"
                          className="w-100"
                        >
                          Sign In
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </NewWrapper>
    );
  }

  return (
    <Wrapper id="login_" className="">
      <div className="container-login100">
        <div
          style={{
            background: "rgb(223 74 35 / 18%)",
            width: "100%",
            height: "100%",
            zIndex: 9,
          }}
        ></div>
        <div
          className="login100-more"
          style={{
            position: "absolute",
            inset: 0,
            backgroundImage: `url("https://static.wixstatic.com/media/319eb0_b4f2c46928aa425aaa5aa514171690dc~mv2.jpg/v1/fill/w_1910,h_801,al_c,q_85,enc_auto/319eb0_b4f2c46928aa425aaa5aa514171690dc~mv2.jpg`,
            // backgroundRepeat: "repeat",
            // backgroundSize: "100%",
          }}
        />
        <div className="authFadeInBottom wrap-login100 p-l-50 p-r-50 p-t-72 p-b-50">
          <div
            className=" bg-body d-flex flex-column flex-center rounded-4 w-md-600px p-20 cardAuth"
            id="div2 "
            style={{ height: "auto", overflow: "auto" }}
          >
            <div className="d-flex flex-center flex-column align-items-stretch h-lg-100 w-md-450px">
              <div className="d-flex flex-center flex-column-fluid pb-10 pb-lg-15">
                <form className="form w-100">
                  <div className="text-center mb-10">
                    <img
                      className="h-55px shimmer"
                      src={
                        "https://static.wixstatic.com/media/319eb0_2887bc999c644d4c9ed17099c2a9ba4d~mv2.png/v1/fill/w_594,h_104,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/ZonKeepers-logo-white%20sub.png"
                      }
                      alt
                    />
                  </div>
                  <div className="text-center mb-10">
                    <h1 className="sign-label fw-bolder mb-3">
                      Welcome to {AppName} Analytics
                    </h1>
                    <h4
                      style={{ color: "#717171" }}
                      className=" fw-bolder mb-3"
                    >
                      New Here? <Link to="/signup">Create an Account</Link>
                    </h4>
                  </div>
                  <Form
                    form={form}
                    name="login"
                    scrollToFirstError
                    layout="vertical"
                    autoComplete="new-password"
                  >
                    <Form.Item
                      name="email"
                      label="E-mail"
                      rules={[
                        {
                          type: "email",
                          message: "The input is not valid E-mail!",
                        },
                        {
                          required: true,
                          message: "Please input your E-mail!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        size="large"
                        autoFocus
                        placeholder="E-mail"
                        id="email"
                        autoComplete="new-password"
                      />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please input your password!",
                        },
                      ]}
                      hasFeedback
                    >
                      <Input.Password
                        id="password"
                        size="large"
                        autoComplete="new-password"
                        placeholder="Password"
                      />
                    </Form.Item>
                  </Form>
                  <Link to="/forgot-password">Forgot Password</Link>
                  <div className="d-grid mb-10 mt-5">
                    <Button
                      loading={loading}
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            isLogin(values);
                          })
                          .catch((info) => {});
                      }}
                      size="large"
                      type="primary"
                    >
                      Sign In
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

import { CaretRightOutlined, DotChartOutlined } from "@ant-design/icons";
import { Collapse, Select, Skeleton, Table } from "antd";
import React, { useContext } from "react";
import { GlobalContext } from "../../../../commonContext";
import { Chart } from "./chart";

const data = [
  {
    key: "1",
    account: "Units Sold",
    goal: 100000,
    yearToDate: 75000,
  },
  {
    key: "2",
    account: "Gross Revenue",
    goal: 150000,
    yearToDate: 120000,
  },
  {
    key: "3",
    account: "Net Profit",
    goal: 80000,
    yearToDate: 60000,
  },
  {
    key: "4",
    account: "Net Margin",
    goal: 100000,
    yearToDate: 75000,
  },
];

const HighLabelGoals = (props) => {
  const {
    setListLoading,
    filter,
    GetCategoryGoalsListAction,
    setFilter,
    salesChannelLoading,
    listLoading,
    list,
    salesChannelList,
  } = props;
  const contextVar = useContext(GlobalContext);
  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  const currentYear = new Date().getFullYear();
  const columns = [
    {
      title: "Account",
      dataIndex: "account",
      key: "account",
      render: (text) => {
        return capitalizeFirstLetter(text?.replace("_", " "));
      },
    },
    {
      title: "Goal",
      dataIndex: "goal",
      key: "goal",
      render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "Year-To-Date",
      dataIndex: "year_to_date",
      key: "year_to_date",
      render: (text) => `$${text.toLocaleString()}`,
    },
    {
      title: "% Achieved",
      render: (text) => {
        return <span>{text?.achieved || 0}%</span>;
      },
    },
  ];
  return (
    <div className="card mt-5 mb-5">
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 270} />
        )}
        expandIconPosition={"end"}
        defaultActiveKey={["1"]}
      >
        <Collapse.Panel
          header={<span className="title_h">High Level Goals</span>}
          key="1"
          extra={
            <div
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <Select
                placeholder="Select Year"
                size="large"
                options={Array.from({ length: 3 }, (_, index) => {
                  return {
                    label: currentYear - index,
                    value: currentYear - index,
                  };
                })}
                className="min-w-200px me-3"
                onChange={(e) => {
                  setFilter({ ...filter, filter_year: e });
                  setListLoading(true);
                  GetCategoryGoalsListAction({
                    ...filter,
                    filter_year: e,
                  });
                }}
                value={filter?.filter_year || null}
              />

              <Select
                loading={salesChannelLoading}
                options={salesChannelList}
                className=" ant_common_input ml-2 "
                style={{ width: "200px", marginRight: "15px" }}
                size="large"
                value={filter?.marketplace_id || null}
                maxTagCount="responsive"
                onClick={(e) => e.preventDefault()}
                onChange={(e, d) => {
                  if (!e) return;
                  const filter_ = {
                    ...filter,
                    marketplace_id: e,
                    region: d?.region,
                    sales_channel: d?.label,
                  };
                  setListLoading(true);
                  GetCategoryGoalsListAction(filter_);
                  setFilter(filter_);
                }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placeholder="Select Marketplace"
                id="asin_dashboard_filter_marketplace"
              />
            </div>
          }
        >
          <div className="card-body">
            <div className="row">
              <div className="col-7">
                <Table
                  dataSource={list}
                  columns={columns}
                  pagination={false}
                  loading={listLoading}
                  scroll={{ x: "max-content" }}
                />
              </div>
              {!listLoading && <Chart list={list} />}
              {listLoading && (
                <div
                  className="col-5 d-flex align-items-center justify-content-center"
                  style={{ height: "275px" }}
                >
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </div>
              )}
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default HighLabelGoals;

import {
  CloudDownloadOutlined,
  DownloadOutlined,
  HistoryOutlined,
} from "@ant-design/icons";
import {
  Button,
  Dropdown,
  Image,
  Input,
  Menu,
  Select,
  theme,
  Space,
  Tag,
  message,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { MakeApiCall } from "../../../apis";
import Pagination from "../../../components/pagination";
import ASINTable from "../../../components/table";
import { TableLoading } from "../../../components/table-animation";
import { DefaultPerPage } from "../../../config";
import COGSModal from "./lib/history";
import { Wrapper } from "./style";
import NoData from "../../../components/no-data";

const { useToken } = theme;
const COGSMapping = () => {
  const [modalVisible, setModalVisible] = useState(false);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [selectedRow, setSelectedRow] = useState({});

  // Sample COGS data
  const cogsHistory = [
    {
      sku: "ABC123",
      inventory: 100,
      unitsSold: 20,
      cogs: 200,
      date: "2023-01-01",
    },
    // Add more data as needed
  ];

  const handleCloseModal = () => {
    setModalVisible(false);
    setSelectedRow({});
  };

  const [searchText, setSearchText] = useState("");

  const [marketplaceList, setMarketplaceList] = useState([]);
  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceSelected, setMarketplaceSelected] = useState({
    marketplace: null,
    marketplace_id: null,
    region: null,
    sales_channel: null,
  });

  const [selectedSign, setSelectedSign] = useState("USD");
  const [sign, setSign] = useState("$");

  const [currencyList, setCurrencyList] = useState([]);
  const [currencyLoading, setCurrencyLoading] = useState(true);

  const [tableList, setTableList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [openFileUpload, setOpenFileUpload] = useState(false);

  const [updateList, setUpdateList] = useState(false);
  const [file, setFile] = useState([]);

  const handleDownload = (fileUrl) => {
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileUrl.split("/").pop();
    document.body.appendChild(anchor); // Append to the document
    anchor.click();
    document.body.removeChild(anchor); // Clean up
    message.destroy();
  };

  const MappingData = async (data) => {
    if (data?.export) {
      message.destroy();
      message.loading("Loading...", 0);
    } else {
      setLoading(true);
    }

    const response = await MakeApiCall(
      `mapping-data?region=${
        data?.region || marketplaceSelected?.region || ""
      }&sales_channel=${
        data?.sales_channel || marketplaceSelected?.sales_channel || ""
      }&pageSize=${data?.pageSize || pageSize || 10}&page=${
        data?.page || page || 1
      }&filter_value=${data?.filter_value || searchText || ""}&export=${
        data?.export || ""
      }&currency_code=${data?.currency_code || selectedSign || "USD"}`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (data?.export) {
        return handleDownload(response?.data?.file);
      }
      setSign(response?.data?.currency || "");
      setTableList(response?.data?.records || []);
      setLoading(false);

      setTotalPage(response?.data?.pagination?.totalCount || 0);
    } else {
      setTableList([]);
      setLoading(false);
    }
  };
  const MappingImport = async (data) => {
    var formData = new FormData();

    // Append data to the FormData object
    formData.append("UploadedProductDataFile[importFile]", data?.uploadedFile);
    formData.append("region", data?.region);
    formData.append("sales_channel", data?.sales_channel);
    const response = await MakeApiCall(
      `import-mapping`,
      "post",
      formData,
      true
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message || "");
      setOpenFileUpload(false);
      setFile();
      MappingData();
    } else {
      message.destroy();
      message.error(response?.message || "");
    }
  };
  const UpdateCOGS = async (data) => {
    const response = await MakeApiCall(`add-cogs`, "post", data, true);

    if (response?.status) {
      message.destroy();
      setUpdateList(!updateList);
      message.success(response?.message || "");
      MappingData();
    } else {
      message.destroy();
      message.error(response?.message || "");
    }
  };

  const getAllListCurrency = async (data) => {
    setCurrencyLoading(true);
    const response = await MakeApiCall(`list-currency-data`, "get", null, true);

    if (response?.status) {
      setCurrencyList(
        response?.data?.map((d) => ({ label: d, value: d })) || []
      );
      setCurrencyLoading(false);
    } else {
      setCurrencyLoading(false);
      setCurrencyList([]);
    }
  };
  const MarketplaceList = async (data) => {
    const response = await MakeApiCall(
      `get-user-marketplace-list`,
      "get",
      null,
      true
    );

    if (response?.status) {
      if (response?.data?.length > 0) {
        const salesList = response?.data?.reduce((acc, e) => {
          const regionIndex = acc.findIndex((r) => r.label === e.region_name);

          if (
            e.region_name === "us-east-1" &&
            e.sales_channel === "Amazon.com"
          ) {
            setMarketplaceSelected({
              marketplace: e?.name,
              marketplace_id: e?.id,
              region: e?.region_name,
              sales_channel: e?.sales_channel,
            });
            MappingData({
              region: e?.region_name,
              sales_channel: e?.sales_channel,
            });
          }

          if (regionIndex === -1) {
            acc.push({
              label: e.region_name,
              options: [
                // {
                //   label: "All",
                //   value: "all" + e.region_label,
                //   region: e.region,
                //   marketplace_id: "all",
                //   marketplace: "all",
                //   sales_channel: "all",
                // },
                {
                  label: e.sales_channel,
                  value: e.id,
                  region: e.region_name,
                  marketplace_id: e?.id,
                  marketplace: e?.name,
                  sales_channel: e?.sales_channel,
                },
              ],
            });
          } else {
            acc[regionIndex].options.push({
              label: e.sales_channel,
              value: e.id,
              marketplace_id: e?.id,
              region: e.region_name,
              marketplace: e?.name,
              sales_channel: e?.sales_channel,
            });
          }
          return acc;
        }, []);

        const uniqueList = salesList.reduce((acc, r) => {
          if (!acc.find((e) => e.label === r.label)) {
            acc.push(r);
          }
          return acc;
        }, []);

        setMarketplaceList(uniqueList);
        setMarketplaceLoading(false);
      } else {
        setMarketplaceLoading(false);
      }
    } else {
    }
  };

  useEffect(() => {
    MarketplaceList();
    getAllListCurrency();
    return () => {};
  }, []);

  const onPageNo = (e) => {
    setLoading(true);
    setTableList([]);
    MappingData({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    MappingData({
      page: 1,
      perPage: e,
    });
  };

  const { token } = useToken();
  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };

  const menuStyle = {
    boxShadow: "none",
  };

  const props_ = {
    onRemove: (file_) => {
      setFile([]);
    },
    beforeUpload: (file) => {
      setFile([file]);
      return false;
    },
    fileList: file,
    accept: ".xlsx",
  };

  const columns = [
    {
      title: "ASIN",
      dataIndex: "asin1",
      key: "asin1",
      render: (e) => {
        return <Tag color="blue-inverse">{e}</Tag>;
      },
    },
    {
      title: "SKU",
      dataIndex: "seller_sku",
      key: "seller_sku",
    },
    {
      title: "Product Name",
      width: 450,
      dataIndex: "item_name",
      key: "item_name",
    },
    {
      title: "Product Image",
      dataIndex: "product_image",
      key: "product_image",
      render: (text) => <Image width={50} src={text} />,
    },
    {
      title: "Landed Cost",
      dataIndex: "cogs",
      key: "cogs",
      render: (e) => {
        return (
          <Tag color="green-inverse">
            {sign}
            {e}
          </Tag>
        );
      },
    },

    {
      title: "Landed Cost History",

      render: (row) => (
        <Space size="middle">
          <HistoryOutlined
            onClick={() => {
              setModalVisible(true);
              setSelectedRow(row);
            }}
          />
        </Space>
      ),
    },
  ];
  const [columnsList, setColumnsList] = useState(columns);
  useEffect(() => {
    setColumnsList(columns);
  }, [sign]);
  return (
    <Wrapper className="px-4 mt-5">
      <div className="header-fix app-container d-flex flex-stack container-fluid mt-5">
        <div className="page-title d-flex flex-wrap me-3 flex-column justify-content-center">
          <Input
            style={{ width: "250px" }}
            placeholder="Product Search Option here"
            size="large"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            onPressEnter={() => {
              setPage(1);
              MappingData({
                page: 1,
              });
            }}
          />
        </div>
        <div className="d-flex align-items-center flex-wrap gap-2 gap-lg-3 fix-over">
          <>
            <Dropdown
              menu={{
                items: [],
              }}
              trigger={["click"]}
              placement="bottomRight"
              getPopupContainer={(target) => target.parentNode}
              open={openFileUpload}
              onOpenChange={(e) => {
                setOpenFileUpload(e);
              }}
              dropdownRender={(menu) => (
                <div style={contentStyle}>
                  {React.cloneElement(menu, {
                    style: menuStyle,
                  })}
                  <div className="px-7 py-5">
                    <div className="fs-5 text-dark fw-bold">
                      Bulk Landed Cost Upload
                    </div>
                  </div>
                  <div className="separator border-gray-200" />
                  <div className="px-7 py-5">
                    {/*begin::Input group*/}
                    <div className="mb-5">
                      <a href="/mapping.xlsx" className="fw-bold text-primary">
                        <i className="bi bi-download text-primary" /> Download
                        Template
                      </a>
                    </div>

                    <div className="mb-10">
                      <Upload {...props_}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                      </Upload>
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        type="reset"
                        className="btn btn-sm btn-light btn-active-light-dark me-2"
                        onClick={() => {
                          setFile([]);
                        }}
                      >
                        Reset
                      </button>
                      <button
                        type="submit"
                        className="btn btn-sm btn-dark"
                        onClick={() => {
                          if (file?.length === 0) {
                            message.destroy();
                            message.warning("Please select file");
                            return;
                          }
                          const obj = {
                            uploadedFile: file?.[0] || null,
                            region: marketplaceSelected?.region,
                            sales_channel: marketplaceSelected?.sales_channel,
                          };
                          message.destroy();
                          message.loading("Loading...", 0);
                          MappingImport(obj);
                        }}
                      >
                        Apply
                      </button>
                    </div>
                    {/*end::Actions*/}
                  </div>
                </div>
              )}
            >
              <Button
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  setOpenFileUpload(!openFileUpload);
                }}
                type="dashed"
              >
                Import
              </Button>
            </Dropdown>
            <Button
              onClick={() => MappingData({ export: "all" })}
              size="large"
              type="primary"
            >
              Export
            </Button>
            <Select
              placeholder="Select Currency"
              className="w-100px"
              options={currencyList}
              loading={currencyLoading}
              size="large"
              getPopupContainer={(target) => target.parentNode}
              value={selectedSign}
              onChange={(e) => {
                MappingData({
                  currency_code: e,
                });
                setSelectedSign(e);
              }}
            />
            <Select
              placeholder="Select Marketplace"
              style={{ width: "200px" }}
              size="large"
              loading={marketplaceLoading}
              getPopupContainer={(target) => target.parentNode}
              value={marketplaceSelected?.marketplace_id}
              onChange={(e, _) => {
                const obj = {
                  marketplace: _?.marketplace,
                  marketplace_id: _?.marketplace_id,
                  region: _?.region,
                  sales_channel: _?.sales_channel,
                };
                setMarketplaceSelected(obj);
                MappingData({
                  ...obj,
                });
              }}
              options={marketplaceList}
            />
          </>
        </div>
      </div>
      <div className="card mt-5">
        <div className="card-body">
          <div className="table-responsive">
            {loading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : tableList?.length !== 0 ? (
              <ASINTable
                dataSource={tableList}
                columns={columnsList}
                loading={loading}
                scroll={{ x: "max-content" }}
                pagination={false}
              />
            ) : (
              <NoData />
            )}
          </div>
          <Pagination
            loading={loading || tableList?.length === 0}
            pageSize={pageSize}
            page={page}
            totalPage={totalPage}
            onPerPage={onPerPage}
            onPageNo={onPageNo}
          />
        </div>
      </div>
      {cogsHistory && (
        <COGSModal
          cogsHistory={cogsHistory}
          visible={modalVisible}
          marketplaceSelected={marketplaceSelected}
          selectedRow={selectedRow}
          selectedSign={selectedSign}
          onClose={handleCloseModal}
          UpdateCOGS={UpdateCOGS}
          updateList={updateList}
        />
      )}
    </Wrapper>
  );
};

export default COGSMapping;

import styled from "styled-components";

export const Wrapper = styled.div`
  svg {
    rect,
    path {
      fill: #e6542a;
    }
  }
`;

import styled from "styled-components";

const Wrapper = styled.div`
  height: 100%;
  .wrapperPage {
    // border-radius: 25px 0px 0px 25px;
    background: #f5f5f9;

    // box-shadow: inset 20px 0px 20px -10px #61bbed33;
  }
`;

export default Wrapper;

import { Badge, Collapse } from "antd";
import { SalesBreakdownWrapperView } from "../style";
import { numberformat } from "../../../../config";
const { Panel } = Collapse;

function SalesBreakdown(sign, salesBreakData, periodData) {
  const getPrValue = (key, param) => {
    const obj = {
      salesBreakData: salesBreakData,
      periodData: periodData,
    };

    return (
      <span
        style={{
          color:
            (obj?.[key]?.[param]?.percentage_change || 0) === 0
              ? "#000000a3"
              : (obj?.[key]?.[param]?.percentage_change || 0) > 0
              ? "green"
              : "red",
        }}
      >{`${obj?.[key]?.[param]?.percentage_change || 0}%`}</span>
    );
  };
  return (
    <SalesBreakdownWrapperView>
      <Collapse
        accordion
        defaultActiveKey={["1"]}
        className="dashboard-collapse"
      >
        {/* Gross Revenue */}
        <div className="single_div">
          <div className="mx-5">
            <div className="row ">
              <div className="col-3">
                <label className="panel_text highLight">
                  <Badge
                    className="unit-badge"
                    status="processing"
                    text=""
                    style={{ marginRight: "10px" }}
                  />
                  <b
                    style={{
                      color: "rgb(169 121 244)",
                      position: "relative",
                      top: "1px",
                    }}
                  >
                    Units
                  </b>
                </label>
              </div>

              <div className="col-9">
                <div className="row">
                  <div className="col text-end">
                    <label className="panel_text highLight">
                      <b style={{ fontWeight: "bolder", color: "#000" }}>
                        {`${numberformat(
                          salesBreakData?.sales_quantity?.current_value
                        )}`}
                      </b>
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text highLight">
                      <b>
                        {`${numberformat(
                          periodData?.sales_quantity?.period_value || 0
                        )}`}
                      </b>
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text highLight">
                      <b>{getPrValue("salesBreakData", "sales_quantity")}</b>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="separator separator-dashed " />
        <div className="single_div">
          <div className="mx-5">
            <div className="row ">
              <div className="col-3">
                <label className="panel_text highLight">
                  <Badge
                    className="purpal-badge"
                    status="processing"
                    text=""
                    style={{ marginRight: "10px" }}
                  />
                  <b
                    style={{
                      color: "#42afca",
                      position: "relative",
                      top: "1px",
                    }}
                  >
                    Gross Revenue
                  </b>
                </label>
              </div>

              <div className="col-9">
                <div className="row">
                  <div className="col text-end">
                    <label className="panel_text highLight">
                      <b style={{ fontWeight: "bolder", color: "#000" }}>
                        {`${sign}${numberformat(
                          salesBreakData?.gross_sales?.current_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </b>
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text highLight">
                      <b>
                        {`${sign}${numberformat(
                          periodData?.gross_sales?.period_value || 0
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </b>
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text highLight">
                      <b>{getPrValue("salesBreakData", "gross_sales")}</b>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="separator separator-dashed" />
        <Panel
          header={<></>}
          key="1"
          extra={
            <>
              <div className="row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <Badge
                      className="red-badge"
                      status="processing"
                      text=""
                      style={{ marginRight: "10px" }}
                    />
                    <b
                      style={{
                        color: "#ff5b5b",
                        position: "relative",
                        top: "1px",
                      }}
                    >
                      Expenses
                    </b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label className="panel_text highLight">
                        <b style={{ fontWeight: "bolder", color: "#000" }}>
                          {salesBreakData?.expenses?.current_value == 0 ? (
                            <>{sign}0</>
                          ) : (
                            `${sign}${numberformat(
                              salesBreakData?.expenses?.current_value
                            )}`?.replace(`${sign}-`, `-${sign}`)
                          )}
                        </b>
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text highLight">
                        <b>
                          {periodData?.expenses?.period_value == 0 ? (
                            <>{sign}0</>
                          ) : (
                            `${sign}${numberformat(
                              periodData?.expenses?.period_value
                            )}`?.replace(`${sign}-`, `-${sign}`)
                          )}
                        </b>
                      </label>
                    </div>

                    <div className="col text-end">
                      <label className="panel_text highLight">
                        <b>{getPrValue("salesBreakData", "expenses")}</b>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
        >
          <Collapse
            accordion
            className="dashboard-collapse"
            defaultActiveKey={["1"]}
          >
            <div className="separator separator-dashed " />
            <div className="single_div">
              <div className="row ">
                <div className="col-3">
                  <label className="panel_text_ highLight_">
                    <span
                      style={{
                        position: "relative",
                        top: "1px",
                      }}
                      className="Match_text"
                    >
                      COGS
                    </span>
                  </label>
                </div>

                <div className="col-9">
                  <div className="row">
                    <div className="col  text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text highLight"
                      >
                        {salesBreakData?.cogs == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            salesBreakData?.cogs?.current_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text highLight">
                        {periodData?.cogs?.period_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            periodData?.cogs?.period_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>

                    <div className="col  text-end">
                      <label className="panel_text highLight">
                        {getPrValue("salesBreakData", "cogs")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="separator separator-dashed " />
            <Panel
              header={<></>}
              key="1"
              extra={
                <>
                  <div className="row">
                    <div className="col-3">
                      <label className="panel_text highLight">
                        <span
                          className="Match_text"
                          style={{
                            position: "relative",
                            top: "1px",
                            // fontWeight:''
                          }}
                        >
                          PPC Costs
                        </span>
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="row">
                        <div className="col text-end">
                          <label className="panel_text highLight">
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {salesBreakData?.ppc_cost?.current_value == 0
                                ? `${sign}0`
                                : `${sign}${numberformat(
                                    salesBreakData?.ppc_cost?.current_value
                                  )}`?.replace(`${sign}-`, `-${sign}`)}
                            </span>
                          </label>
                        </div>
                        <div className="col text-end">
                          <label className="panel_text highLight">
                            <span>
                              {periodData?.ppc_cost?.period_value == 0
                                ? `${sign}0`
                                : `${sign}${numberformat(
                                    periodData?.ppc_cost?.period_value
                                  )}`?.replace(`${sign}-`, `-${sign}`)}
                            </span>
                          </label>
                        </div>

                        <div className="col text-end">
                          <label className="panel_text highLight">
                            <span>
                              {getPrValue("salesBreakData", "ppc_cost")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            >
              <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Sponsored Products</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.product_ppc_cost?.current_value == 0
                          ? `${sign}0`
                          : `${sign}${numberformat(
                              salesBreakData?.product_ppc_cost?.current_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.product_ppc_cost?.period_value == 0
                          ? `${sign}0`
                          : `${sign}${numberformat(
                              periodData?.product_ppc_cost?.period_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>

                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "product_ppc_cost")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Sponsored Brands</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.brand_ppc_cost?.current_value == 0
                          ? `${sign}0`
                          : `${sign}${numberformat(
                              salesBreakData?.brand_ppc_cost?.current_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.brand_ppc_cost?.period_value == 0
                          ? `${sign}0`
                          : `${sign}${numberformat(
                              periodData?.brand_ppc_cost?.period_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>

                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "brand_ppc_cost")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Sponsored Displays</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.display_ppc_cost?.current_value == 0
                          ? `${sign}0`
                          : `${sign}${numberformat(
                              salesBreakData?.display_ppc_cost?.current_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.display_ppc_cost?.period_value == 0
                          ? `${sign}0`
                          : `${sign}${numberformat(
                              periodData?.display_ppc_cost?.period_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>

                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "display_ppc_cost")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>
            <Panel
              header={<></>}
              key="2"
              extra={
                <>
                  <div className="row">
                    <div className="col-3">
                      <label className="panel_text highLight">
                        <span
                          className="Match_text"
                          style={{
                            position: "relative",
                            top: "1px",
                          }}
                        >
                          Amazon Fees
                        </span>
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="row">
                        <div className="col text-end">
                          <label className="panel_text highLight">
                            <span
                              style={{ fontWeight: "bolder", color: "#000" }}
                            >
                              {salesBreakData?.amazon_fees?.current_value ==
                              0 ? (
                                <>{sign}0</>
                              ) : (
                                `${sign}${numberformat(
                                  salesBreakData?.amazon_fees?.current_value
                                )}`?.replace(`${sign}-`, `-${sign}`)
                              )}
                            </span>
                          </label>
                        </div>
                        <div className="col text-end">
                          <label className="panel_text highLight">
                            <span>
                              {periodData?.amazon_fees?.period_value == 0 ? (
                                <>{sign}0</>
                              ) : (
                                `${sign}${numberformat(
                                  periodData?.amazon_fees?.period_value
                                )}`?.replace(`${sign}-`, `-${sign}`)
                              )}
                            </span>
                          </label>
                        </div>

                        <div className="col text-end">
                          <label className="panel_text highLight">
                            <span>
                              {getPrValue("salesBreakData", "amazon_fees")}
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            >
              <div className="separator separator-dashed " />
              {/* <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>FBA Per Order Fee</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.fba_per_order_fulfillment_fee
                          ?.current_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            salesBreakData?.fba_per_order_fulfillment_fee
                              ?.current_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.fba_per_order_fulfillment_fee
                          ?.period_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            periodData?.fba_per_order_fulfillment_fee
                              ?.period_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>

                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue(
                          salesBreakData,
                          "fba_per_order_fulfillment_fee"
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>FBA Fulfillment fess</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.fba_per_unit_fulfillment_fee
                          ?.current_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            salesBreakData?.fba_per_unit_fulfillment_fee
                              ?.current_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.fba_per_unit_fulfillment_fee
                          ?.period_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            periodData?.fba_per_unit_fulfillment_fee
                              ?.period_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue(
                          "salesBreakData",
                          "fba_per_unit_fulfillment_fee"
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>FBA Weight Based Fee</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.fba_weight_based_fee?.current_value ==
                        0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            salesBreakData?.fba_weight_based_fee?.current_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.fba_weight_based_fee?.period_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            periodData?.fba_weight_based_fee?.period_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "fba_weight_based_fee")}
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Variable Closing Fee</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {`${sign}${numberformat(
                          salesBreakData?.variable_closing_fee?.current_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {`${sign}${numberformat(
                          periodData?.variable_closing_fee?.period_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "variable_closing_fee")}
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}

              <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>FBA Referral fees</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.referral_fee?.current_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            salesBreakData?.referral_fee?.current_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.referral_fee?.period_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            periodData?.referral_fee?.period_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "referral_fee")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Fixed Closing Fee</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {sign}
                        {numberformat(
                          salesBreakData?.fixed_closing_fee?.current_value
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {sign}
                        {numberformat(
                          periodData?.fixed_closing_fee?.period_value
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "fixed_closing_fee")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Sales Tax Collection Fee</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {`${sign}${numberformat(
                          salesBreakData?.sales_tax_collection_fee
                            ?.current_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {`${sign}${numberformat(
                          periodData?.sales_tax_collection_fee?.period_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue(
                          "salesBreakData",
                          "sales_tax_collection_fee"
                        )}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed " />
              <div className="h-50px row">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Shipping Holdback Fee</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {`${sign}${numberformat(
                          salesBreakData?.shipping_holdback_fee?.current_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {`${sign}${numberformat(
                          periodData?.shipping_holdback_fee?.period_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>

                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "shipping_holdback_fee")}
                      </label>
                    </div>
                  </div>
                </div>
              </div> */}
            </Panel>
            <Panel
              header={<></>}
              key="3"
              extra={
                <>
                  <div className="row">
                    <div className="col-3">
                      <label className="panel_text highLight">
                        <span
                          className="Match_text"
                          style={{
                            position: "relative",
                            top: "1px",
                          }}
                        >
                          Total Tax
                        </span>
                      </label>
                    </div>
                    <div className="col-9">
                      <div className="row">
                        <div className="col text-end">
                          <label
                            style={{ fontWeight: "bolder", color: "#000" }}
                            className="panel_text highLight"
                          >
                            {`${sign}${numberformat(
                              salesBreakData?.total_tax?.current_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                          </label>
                        </div>
                        <div className="col text-end">
                          <label className="panel_text highLight">
                            {`${sign}${numberformat(
                              periodData?.total_tax?.period_value
                            )}`?.replace(`${sign}-`, `-${sign}`)}
                          </label>
                        </div>
                        <div className="col text-end">
                          <label className="panel_text highLight">
                            {getPrValue("salesBreakData", "total_tax")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }
            >
              <div className="separator separator-dashed " />
              <div className="row h-50px">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Tax Collected</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {`${sign}${numberformat(
                          salesBreakData?.tax_collected?.current_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {`${sign}${numberformat(
                          periodData?.tax_collected?.period_value
                        )}`?.replace(`${sign}-`, `-${sign}`)}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "tax_collected")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="separator separator-dashed " />
              <div className="row h-50px">
                <div className="col-3">
                  <label className="panel_text highLight">
                    <b>Tax Withheld</b>
                  </label>
                </div>
                <div className="col-9">
                  <div className="row">
                    <div className="col text-end">
                      <label
                        style={{ fontWeight: "bolder", color: "#000" }}
                        className="panel_text"
                      >
                        {salesBreakData?.withheld_tax?.current_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            salesBreakData?.withheld_tax?.current_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {periodData?.withheld_tax?.period_value == 0 ? (
                          <>{sign}0</>
                        ) : (
                          `${sign}${numberformat(
                            periodData?.withheld_tax?.period_value
                          )}`?.replace(`${sign}-`, `-${sign}`)
                        )}
                      </label>
                    </div>
                    <div className="col text-end">
                      <label className="panel_text">
                        {getPrValue("salesBreakData", "withheld_tax")}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </Panel>

            <div className="row h-50px align-items-center">
              <div className="col-3">
                <label className="panel_text highLight">
                  <span className="Match_text">Shipping</span>
                </label>
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col text-end">
                    <label
                      style={{ fontWeight: "bolder", color: "#000" }}
                      className="panel_text"
                    >
                      {`${sign}${numberformat(
                        salesBreakData?.order_shipping?.current_value
                      )}`?.replace(`${sign}-`, `-${sign}`)}
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text">
                      {`${sign}${numberformat(
                        periodData?.order_shipping?.period_value
                      )}`?.replace(`${sign}-`, `-${sign}`)}
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text">
                      {getPrValue("salesBreakData", "order_shipping")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="separator separator-dashed " />
            <div className="row h-50px align-items-center">
              <div className="col-3">
                <label className="panel_text highLight">
                  <span className="Match_text">Refunds</span>
                </label>
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col text-end">
                    <label
                      style={{ fontWeight: "bolder", color: "#000" }}
                      className="panel_text"
                    >
                      {salesBreakData?.refund_sales?.current_value == 0
                        ? `${sign}0`
                        : `${sign}${numberformat(
                            salesBreakData?.refund_sales?.current_value
                          )}`?.replace(`${sign}-`, `-${sign}`)}
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text">
                      {periodData?.refund_sales?.period_value == 0
                        ? `${sign}0`
                        : `${sign}${numberformat(
                            periodData?.refund_sales?.period_value
                          )}`?.replace(`${sign}-`, `-${sign}`)}
                    </label>
                  </div>

                  <div className="col text-end">
                    <label className="panel_text">
                      {getPrValue("salesBreakData", "refund_sales")}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className="separator separator-dashed " />
            <div className="row h-50px align-items-center">
              <div className="col-3">
                <label className="panel_text highLight">
                  <span className="Match_text">Promo Value</span>
                </label>
              </div>
              <div className="col-9">
                <div className="row">
                  <div className="col text-end">
                    <label
                      style={{ fontWeight: "bolder", color: "#000" }}
                      className="panel_text"
                    >
                      {`${sign}${numberformat(
                        salesBreakData?.promotional_rebates?.current_value
                      )}`?.replace(`${sign}-`, `-${sign}`)}
                    </label>
                  </div>
                  <div className="col text-end">
                    <label className="panel_text">
                      {`${sign}${numberformat(
                        periodData?.promotional_rebates?.period_value
                      )}`?.replace(`${sign}-`, `-${sign}`)}
                    </label>
                  </div>

                  <div className="col text-end">
                    <label className="panel_text">
                      {getPrValue("salesBreakData", "promotional_rebates")}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </Collapse>
        </Panel>

        <div className="row mx-2 py-2 h-50px">
          <div className="col-3">
            <label className="panel_text highLight">
              <Badge
                className="blueR-badge"
                status="processing"
                text=""
                style={{ marginRight: "10px" }}
              />
              <b
                style={{
                  color: "#945dbc",
                  position: "relative",
                  top: "1px",
                }}
              >
                Reimbursements
              </b>
            </label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col text-end">
                <label
                  style={{ fontWeight: "bolder", color: "#000" }}
                  className="panel_text"
                >
                  {`${sign}${numberformat(
                    salesBreakData?.reimbursements_total_amount?.current_value
                  )}`?.replace(`${sign}-`, `-${sign}`)}
                </label>
              </div>
              <div className="col text-end">
                <label className="panel_text">
                  {`${sign}${numberformat(
                    periodData?.reimbursements_total_amount?.period_value
                  )}`?.replace(`${sign}-`, `-${sign}`)}
                </label>
              </div>
              <div className="col text-end">
                <label className="panel_text">
                  {getPrValue("salesBreakData", "reimbursements_total_amount")}
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="separator separator-dashed " />

        <div className="row mx-2 py-2 h-50px">
          <div className="col-3">
            <label className="panel_text highLight">
              <Badge
                className="green-badge"
                status="processing"
                text=""
                style={{ marginRight: "10px" }}
              />
              <b
                style={{
                  color: "#10b981",
                  position: "relative",
                  top: "1px",
                }}
              >
                Net Profit
              </b>
            </label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col text-end">
                <label
                  style={{ fontWeight: "bolder", color: "#000" }}
                  className="panel_text"
                >
                  {`${sign}${numberformat(
                    salesBreakData?.net_profit?.current_value
                  )}`?.replace(`${sign}-`, `-${sign}`)}
                </label>
              </div>
              <div className="col text-end">
                <label className="panel_text">
                  {`${sign}${numberformat(
                    periodData?.net_profit?.period_value
                  )}`?.replace(`${sign}-`, `-${sign}`)}
                </label>
              </div>
              <div className="col text-end">
                <label className="panel_text">
                  {getPrValue("salesBreakData", "net_profit")}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="separator separator-dashed " />

        <div className="row mx-2 py-2 h-50px">
          <div className="col-3">
            <label className="panel_text highLight">
              <Badge
                className="light-green-badge"
                status="processing"
                text=""
                style={{ marginRight: "10px" }}
              />
              <b
                style={{
                  color: "#76bc5d",
                  position: "relative",
                  top: "1px",
                }}
              >
                Profit Margin
              </b>
            </label>
          </div>
          <div className="col-9">
            <div className="row">
              <div className="col text-end">
                <label
                  style={{ fontWeight: "bolder", color: "#000" }}
                  className="panel_text"
                >
                  {numberformat(salesBreakData?.net_margin?.current_value)}%
                </label>
              </div>
              <div className="col text-end">
                <label className="panel_text">
                  {numberformat(periodData?.net_margin?.period_value)}%
                </label>
              </div>

              <div className="col text-end">
                <label className="panel_text">
                  {getPrValue("salesBreakData", "net_margin")}
                </label>
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </SalesBreakdownWrapperView>
  );
}
export default SalesBreakdown;

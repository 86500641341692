export const dark = {
  colors: {
    primary: "#007bff",
    secondary: "#6c757d",
    body: "#151718",
    theme: "#1c1f27",
    tooltipBG: "#313133",
    tooltipText: "#ffffffd9",
    tableGBG: "#00534d",
    collapse: {
      bg: "#27333d",
    },
    sidebar_new: {
      background: "#313133",
    },
    header: {
      background: "#313133",
    },
    Loading_BG: {
      background: "#313133",
    },
    Dashboard_New: {
      filter_title: "#ffffffa1",
      Sales_breakdown: "#1b2025",
      top_product_color: "#FFF",
      highLight: "#ffffffb5",
      panelText: "#ffffffa3",
      DiselectTabView: "#3e43488a",
      adFooter: "#272a2a",
    },
    Sales_Analytics: {
      filter_background: "rgba(49, 49, 51, 0.59)",
      table_fixed: "rgb(49, 49, 51)",
      table_header: "#313133",
      chart_tiles_color: "#FFF",
      ant_collapse_header: "#37393d",
    },
    card: "#313133",
    bodyWrapper: "#3e4348",
    tab_Bottom: "rgba(255, 255, 255, 0.16)",
    tableGroupColumns: "#FFF",
    darkcolumnGroup: "rgb(200 231 249 / 12%)",
    gridBack: "rgb(0 0 0 / 48%)",
    tooltipColor: "#000",
    sidebar: {
      userName: "#FFFFFF",
      borderBottom: "#ffffff29",
      userMail: "#FFFFFF91",
      sidebarIcon: "#FFFFFF",
      selectedIcon: "#61bbed",
      selectedMenuText: "#FFF",
      selectedMenuBG: "#111a2c",
      selectedMenu: "#61bbed",
      selectedSubMenu: "rgb(225 225 225 / 5%)",
      selectedBorder: "1px solid rgb(97 187 237 / 29%)",
      logoutButton: "#61bbed",
      actionMenu: "#FFFFFF00",
      profileBackShadow:
        "rgb(0 0 0) 0px 15px 25px -4px, rgb(0 0 0 / 20%) 0px -3px 4px -1px inset, rgb(0 0 0 / 60%) 0px -10px 15px -1px, rgb(0 0 0 / 20%) 0px 3px 4px -1px inset, rgb(0 0 0 / 80%) 0px 0px 5px 1px inset, rgb(0 0 0 / 20%) 0px 20px 30px 0px inset",
    },
    topBar: {
      back: "rgb(21 23 24)",
    },
    wrapperBG: {
      back: "#1E1F23",
    },
    loading: {
      back: "rgb(21 23 24)",
      skeletonImg: "rgb(30 31 35)",
      skeletonImgBorder: "#151718",
    },
    dashboard: {
      title: "#FFFFFF",
      cardTitle: "#f0f1f3",
      cardSubTitle: "#FFF",
      value: "#DEE8EA",
      title: "#FDFDFD",
      titleH: "#61bbed",
      backCardSelectTitle: "cornflowerblue",
      table: {
        parentASINBG: "rgb(30 31 35)",
        parentBoxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        subTitle: "#afb2c1",
      },
      grid: {
        background: "rgb(22 24 25)",
        selectedCard: "rgb(200 231 249 / 4%)",
        selectedBorder: "#61bbed",
        time: "#f3f3f3",
        header: "#969ba4",
        comments: "#f3f3f3",
        commentsSVG: "#4f5058",
        reactionsBack: "#2b2d31",
        reactionsColor: "#b6bac1",
        reactionsBorderHover: "#58595d",
        reactionsColorHover: "#d6d8db",
        reactionsBackHover: "#303136",
      },
    },
    admin: {
      user: {
        name: "white",
        mail: "#a3a3a3",
        switch_user: "white",
      },
    },
  },
};

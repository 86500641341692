export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "MONTH_COMPARISON_LIST_SUCCESS":
      case "MONTH_COMPARISON_LIST_ERROR":
        return {
          ...state,
          MonthComparisonDataListResponse: action.updatePayload,
        };
      case "YEAR_COMPARISON_LIST_SUCCESS":
      case "YEAR_COMPARISON_LIST_ERROR":
        return {
          ...state,
          YearComparisonDataListResponse: action.updatePayload,
        };
      case "DAYS_COMPARISON_LIST_SUCCESS":
      case "DAYS_COMPARISON_LIST_ERROR":
        return {
          ...state,
          DaysComparisonDataListResponse: action.updatePayload,
        };
      case "FAKE_ACTION_MOM_YOY_COMPARISON":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  
import styled from "styled-components";

const Wrapper = styled.div`
  .title_hh {
    font-weight: bold !important;
    color: #e6542a !important;
  }
  .dashboard_ad_footer_text {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
  }
  .dashboard_ad_footer {
    background: ${(props) => props.theme.colors.Dashboard_New.adFooter};
  }
  th,
  tr {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
  }
  tr > h4 > b {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
  }
  tbody > tr,
  thead > tr {
    border-bottom-color: ${(props) =>
      props.theme.colors.sidebar.borderBottom} !important;
  }
  .card-toolbar {
    label {
      color: ${(props) =>
        props.theme.colors.Dashboard_New.filter_title} !important;
    }
  }
  .ant-table-column-sorter-full {
    color: ${(props) => props.theme.colors.Dashboard_New.filter_title};
    transform: rotate(90deg);
    cursor: pointer;
    &:hover {
      color: ${(props) => props.theme.colors.dashboard.titleH} !important;
    }
  }
`;

export default Wrapper;

import styled from "styled-components";
import { themeColor } from "../../../config";

const Wrapper = styled.div`
  margin-top: 10px;
  .ant-collapse-extra {
    width: 100%;
    position: relative;
    top: -4px;
  }
  .ant-collapse-content-box {
    padding-top: 0px !important;
  }
  .ant-collapse-expand-icon {
    position: absolute;

    left: 250px;
    margin: auto;
    width: fit-content;
  }
  .back-card {
    overflow: auto;
    height: 184px;
    padding: 22px 2rem !important;
  }
  .ant-picker-time-panel {
    display: none !important;
  }
  .ant-badge-status-dot {
    transform: scale(2);
  }
  .purpal-badge {
    .ant-badge-status-dot {
      color: #42afca;
      background-color: #42afca;
    }
  }
  .unit-badge {
    .ant-badge-status-dot {
      color: rgb(169 121 244);
      background-color: rgb(169 121 244);
    }
  }
  .blue-badge {
    .ant-badge-status-dot {
      color: #ffb15e;
      background-color: #ffb15e;
    }
  }
  .blueR-badge {
    .ant-badge-status-dot {
      color: #945dbc;
      background-color: #945dbc;
    }
  }
  .red-badge {
    .ant-badge-status-dot {
      color: #ff5b5b;
      background-color: #ff5b5b;
    }
  }
  .green-badge {
    .ant-badge-status-dot {
      color: #10b981;
      background-color: #10b981;
    }
  }
  .light-green-badge {
    .ant-badge-status-dot {
      color: #76bc5d;
      background-color: #76bc5d;
    }
  }

  .value {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
  }
  .dashboard_tab {
    background-color: ${(props) =>
      props.theme.colors.Dashboard_New.DiselectTabView} !important;
  }
  .title_h {
    font-weight: bold !important;
    color: #fff !important;
    background-color: ${(props) =>
      props.theme.colors.dashboard.titleH} !important;
  }
  .title_hh {
    font-weight: bold !important;

    color: #e6542a !important;
  }

  .removeBorder {
    border-bottom: none !important;
    padding: 5px 1.25rem;
  }
  .dashboard_filter_toolbar {
    label {
      color: ${(props) =>
        props.theme.colors.Dashboard_New.filter_title} !important;
    }
  }
  .heighLight {
    display: flex;
    justify-content: center;
    padding: 20px;
    margin: 10px;
    color: ${(props) => props.theme.colors.sidebar.selectedMenuText};
    border: 1px solid ${(props) => props.theme.colors.sidebar.selectedMenu};
    border-radius: 9px;
    background: ${(props) => props.theme.colors.sidebar.selectedMenuBG};
    svg {
      path {
        fill: ${(props) => props.theme.colors.sidebar.selectedMenu} !important;
      }
      g {
        path {
          fill: rgb(206, 232, 250) !important;
        }
      }
    }
  }
  .Match_text {
    color: ${(props) => props.theme.colors.sidebar.userName} !important;
    font-weight: 600 !important;
  }
  .card-flip-view {
    .card {
      transform: rotateY(0deg) !important;
      transform-style: preserve-3d !important;
      backface-visibility: hidden !important;
      transition: transform 0.9s cubic-bezier(0.4, 0.2, 0.2, 1) !important;
    }
    .back-card {
      transform: rotateY(180deg) !important;
      transform-style: preserve-3d !important;
      backface-visibility: hidden !important;
      transition: transform 0.9s cubic-bezier(0.4, 0.2, 0.2, 1) !important;
      width: -webkit-fill-available;
    }
    &:hover {
      .card {
        transform: rotateY(-180deg) !important;
      }
      .back-card {
        transform: rotateY(0deg) !important;
      }
    }
  }
  #sales > div {
    .title span,
    .ant-progress-bg {
      background-color: #42afca !important;
    }
    .percent {
      color: #42afca !important;
    }
  }
  #profit > div {
    .title span,
    .ant-progress-bg {
      background-color: #945ebc !important;
    }
    .percent {
      color: #945ebc !important;
    }
  }
  #units > div {
    .title span,
    .ant-progress-bg {
      background-color: #76bc5e !important;
    }
    .percent {
      color: #76bc5e !important;
    }
  }
  #refunds > div {
    .title span,
    .ant-progress-bg {
      background-color: #ffb15e !important;
    }
    .percent {
      color: #ffb15e !important;
    }
  }
  #roi > div {
    .title span,
    .ant-progress-bg {
      background-color: #585c5d !important;
    }
    .percent {
      color: #585c5d !important;
    }
  }
  #expenses > div {
    .title span,
    .ant-progress-bg {
      background-color: #ff5b5b !important;
    }
    .percent {
      color: #ff5b5b !important;
    }
  }
  .border-bottom-card {
  }

  .days_filter {
    .ant-select-selector {
      background-color: rgb(243 241 239) !important;
      border: 1px solid rgb(211 211 211) !important;
    }
  }
  .card_title_fill {
    color: ${(props) => props.theme.colors.body} !important;
  }

  #sales,
  #units,
  #refunds,
  #roi {
    .d_card {
      background-color: #d2e8fd !important;
    }
    .icon_card > div {
      background: rgb(210 232 253) !important;
      border: 1px solid rgb(255 255 255) !important;
      svg {
        path {
          fill: #0e339d !important;
        }
      }
    }
    .card_title_fill {
      color: #3f4254 !important;
      font-size: 22px;
    }
    h3 {
      .ant-statistic {
        background: #6dc798 !important;
        padding: 5px !important;
        border-radius: 12px !important;
      }
    }
    h6 {
      .ant-statistic-content {
        color: #000 !important;
        font-weight: 600 !important;
      }
    }
  }
  #units {
    .d_card {
      background-color: #fff6cd !important;
    }
    .icon_card > div {
      background: #fff6cd !important;
      svg {
        path {
          fill: #b88301 !important;
        }
      }
    }
    .card_title_fill {
      color: #3f4254 !important;
      font-size: 22px;
    }
  }
  #refunds {
    .d_card {
      background-color: #d0f2fc !important;
    }
    .icon_card > div {
      background: #d0f2fc !important;
      svg {
        path {
          fill: #0951bf !important;
        }
      }
    }
    .card_title_fill {
      color: #3f4254 !important;
      font-size: 22px;
    }
  }
  #roi {
    .d_card {
      background-color: #fee7d7 !important;
    }
    .icon_card > div {
      background: #fee7d7 !important;
      svg {
        path {
          fill: #b72238 !important;
        }
      }
    }
    .card_title_fill {
      color: #3f4254 !important;
      font-size: 22px;
    }
  }
  .d_card {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 56 28' width='56' height='28'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M56 26v2h-7.75c2.3-1.27 4.94-2 7.75-2zm-26 2a2 2 0 1 0-4 0h-4.09A25.98 25.98 0 0 0 0 16v-2c.67 0 1.34.02 2 .07V14a2 2 0 0 0-2-2v-2a4 4 0 0 1 3.98 3.6 28.09 28.09 0 0 1 2.8-3.86A8 8 0 0 0 0 6V4a9.99 9.99 0 0 1 8.17 4.23c.94-.95 1.96-1.83 3.03-2.63A13.98 13.98 0 0 0 0 0h7.75c2 1.1 3.73 2.63 5.1 4.45 1.12-.72 2.3-1.37 3.53-1.93A20.1 20.1 0 0 0 14.28 0h2.7c.45.56.88 1.14 1.29 1.74 1.3-.48 2.63-.87 4-1.15-.11-.2-.23-.4-.36-.59H26v.07a28.4 28.4 0 0 1 4 0V0h4.09l-.37.59c1.38.28 2.72.67 4.01 1.15.4-.6.84-1.18 1.3-1.74h2.69a20.1 20.1 0 0 0-2.1 2.52c1.23.56 2.41 1.2 3.54 1.93A16.08 16.08 0 0 1 48.25 0H56c-4.58 0-8.65 2.2-11.2 5.6 1.07.8 2.09 1.68 3.03 2.63A9.99 9.99 0 0 1 56 4v2a8 8 0 0 0-6.77 3.74c1.03 1.2 1.97 2.5 2.79 3.86A4 4 0 0 1 56 10v2a2 2 0 0 0-2 2.07 28.4 28.4 0 0 1 2-.07v2c-9.2 0-17.3 4.78-21.91 12H30zM7.75 28H0v-2c2.81 0 5.46.73 7.75 2zM56 20v2c-5.6 0-10.65 2.3-14.28 6h-2.7c4.04-4.89 10.15-8 16.98-8zm-39.03 8h-2.69C10.65 24.3 5.6 22 0 22v-2c6.83 0 12.94 3.11 16.97 8zm15.01-.4a28.09 28.09 0 0 1 2.8-3.86 8 8 0 0 0-13.55 0c1.03 1.2 1.97 2.5 2.79 3.86a4 4 0 0 1 7.96 0zm14.29-11.86c1.3-.48 2.63-.87 4-1.15a25.99 25.99 0 0 0-44.55 0c1.38.28 2.72.67 4.01 1.15a21.98 21.98 0 0 1 36.54 0zm-5.43 2.71c1.13-.72 2.3-1.37 3.54-1.93a19.98 19.98 0 0 0-32.76 0c1.23.56 2.41 1.2 3.54 1.93a15.98 15.98 0 0 1 25.68 0zm-4.67 3.78c.94-.95 1.96-1.83 3.03-2.63a13.98 13.98 0 0 0-22.4 0c1.07.8 2.09 1.68 3.03 2.63a9.99 9.99 0 0 1 16.34 0z'%3E%3C/path%3E%3C/svg%3E") !important;
  }

  #dropdown-container {
    position: absolute;
    margin: auto;
    right: 20px;
    top: 20px;
    z-index: 9999999;
  }
  .filter > .ant-dropdown-trigger {
    width: 40px;
    height: 40px;
    align-self: self-end;
    ${"" /* position: relative; */}
    ${"" /* top: -4px; */}
    border-radius: 10px;
    background: ${() => themeColor()};
    border: none;
    color: white;
    cursor: pointer;
    &:hover {
      background: #ffffff;
      color: #64bae2;
    }
  }
  .card-n {
    background: #f9faf5;
    padding: 10px;
    border-radius: 7px;
    .card-n-header {
      display: flex;
      justify-content: space-between;
      span {
        font-weight: bold;
        font-size: 15px;
      }
    }
    .card-n-body {
    }
  }
  .media {
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .primary {
    color: #00b5b8 !important;
  }
  .float-left {
    float: left !important;
  }
  .font-large-2 {
    font-size: 3rem !important;
  }
  .card-new {
    border-radius: 7px;
    background: #bdbfbc4f;
  }
  .card-body- {
    background: #f9faf5;
  }
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .card-body-new {
    background: #fff;
    border-radius: 7px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .media-body {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  h3 {
    font-size: 1.51rem;
  }
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5rem;
    font-weight: 400;
    line-height: 1.2;
    color: inherit;
  }
  .text-right {
    text-align: right !important;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }
  .swiper-container-android .swiper-slide,
  .swiper-wrapper {
    transform: translate3d(0px, 0, 0);
  }
  .swiper-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
  .card-box-icon {
    margin-right: 1.25rem;
  }
  .card-box-icon {
    width: 4.5rem;
    height: 4.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #eeeeee;
    border-radius: 0.625rem;
  }
  .chart-num {
    margin-top: 0.375rem;
  }
  .chart-num h2,
  .card .chart-num .h2 {
    font-size: 2.25rem;
    margin-bottom: 0;
    line-height: 1;
  }

  .d_card {
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    cursor: pointer;
    transition: 0.5s;
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.05) 0px 40px 50px;
    }
    ${"" /* height: 75px; */}
    border-radius: 10px;
    .icon_card {
      background: ${(props) => props.theme.colors.body} !important;
      width: 55px;
      height: 55px;
      border-radius: 10px;
      position: relative;
      top: -15px;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 15px;
    }
    svg {
      width: 28px !important;
      path {
        // fill: #61bbed !important;
      }
    }
  }
  .card-new-s {
    padding: 40px 20px 20px 20px !important;
  }
  .back-card {
    position: absolute;
    top: 0;
    color: #fff;
    width: 100%;
  }
  .label-card {
    cursor: pointer;
    text-align: center;
    color: ${(props) => props.theme.colors.dashboard.cardSubTitle};
    &:hover {
      color: ${(props) => props.theme.colors.dashboard.backCardSelectTitle};
    }
  }
  .selected-row {
    color: ${(props) => props.theme.colors.dashboard.backCardSelectTitle};
  }
  .title-body {
    b {
      color: ${(props) => props.theme.colors.dashboard.cardSubTitle} !important;
    }
    font-weight: bolder;
  }

  @media only screen and (max-width: 1440px) {
    .responsiveFix {
      // zoom: 79%;
    }
  }
  @media only screen and (min-width: 1700px) and (max-width: 1931px) {
    .card-flip-view {
      max-width: 100% !important;
    }
  }

  @media only screen and (min-width: 1200px) and (max-width: 1519px) {
    .card-flip-view {
      max-width: 100% !important;
    }
  }
  .card-flip-view {
    max-width: 400px !important;
  }
  .removeBG {
    background: transparent !important;
  }
  .percent {
    svg {
      position: relative;
      top: -2px;
    }
  }
  .dashboard_ad_footer_text {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
  }

  .adTableHide {
    display: none;
    opacity: 0;
    transform: translateY(-20px);
    // animation: hideLine 0.8s forwards;
  }
  @keyframes hideLine {
    0% {
      opacity: 1;
      display: grid;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      display: none;
      transform: translateY(-20px);
    }
  }
  .adTableShow {
    opacity: 0;

    animation: ShowLine 0.8s forwards;
  }
  @keyframes ShowLine {
    0% {
      opacity: 0;
      display: none;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      display: grid;
      transform: translateY(0px);
    }
  }
`;
export const CardWrapper = styled.div`
  padding: 2.4rem;
  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  // max-width: 320px;
  border-radius: 20px;
  .separator-dashed {
    margin: 8px 0px !important;
  }
  #sales > div {
    .title span {
      background-color: #42afca;
    }
    .percent {
      color: #42afca !important;
    }
  }

  #units > div {
    background-color: #76bc5e3d !important;
  }
  #refunds > div {
    background-color: #ffb15e3d !important;
  }
  #roi > div {
    background-color: #585c5d3d !important;
  }
  #profit > div {
    background-color: #ff00003d !important;
  }
  .title {
    display: flex;
    align-items: center;
  }

  .title span {
    position: relative;
    padding: 0.5rem;
    background-color: #10b981;
    border-radius: 9999px;
    width: 2.5rem;
    height: 2.5rem;
  }

  .title span svg {
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    color: #ffffff;
    // top: 11px;
    // left: 12px;
  }
  .percent {
    margin-bottom: 0 !important;
  }
  .title-text {
    margin-left: 0.5rem;
    margin-bottom: 0 !important;
    color: ${(props) => props.theme.colors.dashboard.cardTitle};
    // color: #374151;
    font-size: 18px;
  }

  .percent {
    margin-left: 0.5rem;
    color: #02972f;
    font-weight: 600;
    display: flex;
  }

  .data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    transform: translateY(0%) translateZ(40px) scale(1);
  }

  .data p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: ${(props) => props.theme.colors.dashboard.cardSubTitle};
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    text-align: left;
  }
  .ant-progress-outer {
    height: 10px !important;
    .ant-progress-bg {
      height: 10px !important;
    }
  }
  .data .range {
    position: relative;
    background-color: #e5e7eb;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
  }

  .data .range .fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #10b981;
    width: 76%;
    height: 100%;
    border-radius: 0.25rem;
  }

  #margin > div {
    .title > span {
      background-color: #10b981;
    }
    .percent {
      color: #02972f !important;
    }
  }
`;
export const SalesBreakdownWrapper = styled.div`
  .row {
    font-size: 18px;
  }
  .title {
    b {
      font-weight: 300 !important;
      color: ${(props) => props.theme.colors.dashboard.value} !important;
    }
  }
  .value {
    b {
      color: ${(props) => props.theme.colors.dashboard.value} !important;
    }
  }
`;
export const GridTableWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 10px;
  .container {
    position: relative;
    height: 200px;
    color: ${(props) => props.theme.colors.dashboard.grid.comments};
    width: 100%;
    background-color: ${(props) =>
      props.theme.colors.dashboard.grid.background};
    border-radius: 20px;
    padding: 0 !important;
    display: grid;
    cursor: pointer;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out 0s;
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    svg {
      color: #00000000;
    }
  }
  .selectedCard {
    background: ${(props) => props.theme.colors.dashboard.grid.selectedCard};
    border: 1px dashed
      ${(props) => props.theme.colors.dashboard.grid.selectedBorder};
    svg {
      color: ${(props) => props.theme.colors.dashboard.grid.selectedBorder};
    }
  }

  .time {
    color: ${(props) => props.theme.colors.dashboard.grid.time};
    margin-left: 15px;
    float: left;
    p {
      margin-bottom: 0 !important;
      width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .header {
    clear: both;
    font-size: 1rem;
    color: ${(props) => props.theme.colors.dashboard.grid.header};
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .photo {
    position: relative;
    width: 100%;
    height: 90px;
    background-repeat: no-repeat;
    // background-size: 140px;
    background-position: center top;

    margin-bottom: 15px;
    margin-top: 10px;
    margin-top: 3px;
    background-size: 90px;
    border-radius: 7px;
  }

  .comments {
    color: ${(props) => props.theme.colors.dashboard.grid.comments};
    float: left;
  }
  .comments svg {
    width: 13px;
    fill: ${(props) => props.theme.colors.dashboard.grid.commentsSVG};
    float: left;
  }
  .comments p {
    font-size: 0.9rem;
    margin-left: 7px;
    margin-top: -3px;
    float: left;
  }

  .reactions {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    float: right;
    padding: 5px 10px 3px;
    height: 20px;
    background-color: ${(props) =>
      props.theme.colors.dashboard.grid.reactionsBack};
    float: right;
    border-radius: 10px;
    color: ${(props) => props.theme.colors.dashboard.grid.reactionsColor};
    font-size: 0.85rem;
    transition: all 0.1s ease-in-out;
    border: 1px solid transparent;
  }

  .reactions:hover {
    border-color: ${(props) =>
      props.theme.colors.dashboard.grid.reactionsBorderHover};
    background-color: ${(props) =>
      props.theme.colors.dashboard.grid.reactionsBackHover};
    color: ${(props) => props.theme.colors.dashboard.grid.reactionsColorHover};
  }

  .container:hover {
    transform: translateY(-3px);
    box-shadow: 0px 10px 15px -8px rgba(0, 0, 0, 0.4);
  }
`;

export const NewDashboardCard = styled.div`
  .card_ {
    height: 200px;
    // background: #313131;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    transition: 0.2s ease-in-out;
  }

  .img {
    position: absolute;
    transition: 0.2s ease-in-out;
    z-index: 1;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    color: ${(props) => props.theme.colors.Dashboard_New.top_product_color};
    img {
      width: 134px;
      margin: 0px 15px 0px 15px;
      border-radius: 10px;
    }
  }
  .top-product-value {
    color: ${(props) => props.theme.colors.Dashboard_New.top_product_value};
  }
  .top-product-key {
    color: ${(props) =>
      props.theme.colors.Dashboard_New.top_product_key} !important;
  }
  .textBox {
    opacity: 0;
    display: flex;

    align-items: center;
    justify-content: center;
    width: 97%;
    transition: 0.2s ease-in-out;
    z-index: 2;
    height: 170px;
    overflow: auto;
  }

  .textBox > .text {
    font-weight: bold;
  }

  .textBox > .head {
    font-size: 20px;
  }

  .textBox > .price {
    font-size: 17px;
  }

  .textBox > span {
    font-size: 12px;
    color: lightgrey;
  }

  .card_:hover > .textBox {
    opacity: 1;
  }

  .card_:hover > .img {
    height: 65%;
    filter: blur(12px);
    opacity: 0.4;
    animation: anim 3s infinite;
  }

  @keyframes anim {
    0% {
      transform: translateY(0);
    }

    50% {
      transform: translateY(-20px);
    }

    100% {
      transform: translateY(0);
    }
  }

  .card_:hover {
    transform: scale(1.04) rotate(0deg);
  }
`;

export const SalesBreakdownWrapperView = styled.div`
  .ant-collapse .ant-collapse-content {
    border-top: 1px solid rgb(22 24 25 / 0%) !important;
  }

  .ant-collapse-header {
    // background: rgb(39 51 61 / 27%);
  }
  .border-danger {
    border-color: #f1416c !important;
  }
  .border-gray-300 {
    border-color: #dbdfe9 !important;
  }
  .ant-collapse-header-text {
    font-size: 15px !important;
    font-weight: 500 !important;
  }

  .ant-collapse-extra {
    font-size: 15px !important;
    font-weight: 500 !important;
  }
  .panel_text {
    font-size: 15px !important;
    font-weight: 600 !important;
    b {
      letter-spacing: 1px;
    }
  }
  .ant-collapse {
    border: none !important;
    background: none !important;
  }
  .ant-collapse-header {
    // background: #e7e9f9 !important;
    padding: 10px 16px !important;
  }
  .ant-collapse-item {
    // border: none !important;
  }
  .ant-collapse-content {
    border: none !important;
    // background: #f6f6fa !important;
  }
  .ant-collapse-content-box {
    padding: 0px 16px !important;
  }
  .ant-collapse-item-active {
    .ant-collapse-content {
      .ant-collapse-content-box {
        .ant-collapse {
          .ant-collapse-item {
            .ant-collapse-content {
              .ant-collapse-content-box {
                padding: 0px 0px !important;
                .row {
                  display: flex;
                  align-items: center;
                }
              }
            }
            .ant-collapse-header {
              padding: 0px 0px !important;
            }
          }
        }
      }
    }
  }
  .ant-collapse {
    .ant-collapse-item {
      .ant-collapse-header {
        padding: 10px 16px !important;
      }
    }
  }
  .col-6,
  .ant-collapse-header {
    display: grid !important;
    align-items: center !important;
  }
  .single_div,
  .ant-collapse-header {
    display: grid;
    align-items: center;
    padding: 5px 0px;
    border-radius: 6px;
    height: 55px !important;
    // background: #e7e9f9;
  }
  .ant-collapse-content {
    background: #ffffff00 !important;
  }
  .text-green {
    color: #39ae6d;
  }
  .fw-bolder {
    font-weight: 700 !important;
  }
  .ant-collapse-content-box {
    padding: 9px 16px;
    background: #ffffff00 !important;
    .panel_text {
      b {
        font-weight: 400 !important;
      }
    }
    .panel_text_ {
      b {
        font-weight: 800 !important;
      }
    }
  }
  .ant-collapse-header-text {
    b {
      letter-spacing: 1px !important;
    }
  }
  .panel_text {
    color: ${(props) => props.theme.colors.Dashboard_New.panelText};
  }
  .highLight {
    font-weight: 700 !important;
    font-size: 1.25rem;
  }
  .highLight_ {
    font-size: 15px !important;
    letter-spacing: 1px !important;
    font-weight: 600 !important;
    color: ${(props) => props.theme.colors.tooltipText} !important;
  }
`;
export const AdWrapper = styled.div`
  th,
  tr {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
  }
  tr > td > h4 > b {
    color: ${(props) => props.theme.colors.dashboard.value} !important;
  }
  tbody > tr,
  thead > tr {
    border-bottom-color: ${(props) =>
      props.theme.colors.sidebar.borderBottom} !important;
  }
  .card-header {
    // position: sticky;
    // top: 86px;
    // z-index: 9;
    // background: ${(props) => props.theme.colors.sidebar_new.background};
    // backdrop-filter: blur(21px);
  }
  .dashboard_ad_footer {
    background: ${(props) => props.theme.colors.Dashboard_New.adFooter};
  }
`;
export const TooltipWrapper = styled.div`
  .ant-tooltip-inner {
    min-width: 500px !important;
    max-width: 500px !important;
  }
`;
export default Wrapper;

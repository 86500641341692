import React, { useEffect, useState } from "react";
import { Form, Input, Button, Modal, Upload, message } from "antd";

import { UploadOutlined } from "@ant-design/icons";
import { MakeApiCall } from "../../../../apis";

const ImageEnd =
  "https://analytics.zonkeepers.com.au/zonkeepers_api/images/user-images/";

const BusinessInfo = ({
  isModalVisible,
  setIsModalVisible,
  id,
  contextValue,
  business_address,
  business_logo,
  business_name,
}) => {
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const getBusinessDetails = async (value) => {
    try {
      const response = await MakeApiCall(`user-view/${id}`, "get", null, true);

      if (response?.status) {
        setLoading(false);
        contextValue?.updateCommonGlobalVal({
          user_: response?.data,
        });
        localStorage.setItem("user", JSON.stringify(response?.data));
        form.resetFields();
        setIsModalVisible(false);
      } else {
        setLoading(false);
        message.error(response?.error || response?.message);
      }
    } catch (error) {
      message.error("something went wrong");
      setLoading(false);
    }
  };
  const submitBusiness = async (value) => {
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("business_name", value?.business_name);
    formDataToSend.append("business_address", value?.business_address);
    formDataToSend.append("business_logo", value?.business_logo);
    try {
      const response = await MakeApiCall(
        `update-business-details`,
        "post",
        formDataToSend,
        true
      );

      if (response?.status) {
        getBusinessDetails();
        message.success(response?.message);
      } else {
        setLoading(false);
        message.error(response?.error || response?.message);
      }
    } catch (error) {
      message.error("something went wrong");
      setLoading(false);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const initialValues = {
    business_address: business_address,
    business_logo:
      business_logo !== "undefined"
        ? [
            {
              uid: "-1",
              name: "test.png",
              status: "done",
              url: ImageEnd + business_logo,
            },
          ]
        : [],
    business_name: business_name,
  };

  useEffect(() => {
    const data = {
      business_address: business_address,
      business_logo:
        business_logo !== "undefined"
          ? [
              {
                uid: "-1",
                name: "test.png",
                status: "done",
                url: ImageEnd + business_logo,
              },
            ]
          : [],
      business_name: business_name,
    };

    form.setFieldsValue(data);
  }, [isModalVisible]);

  return (
    <Modal
      title="Business Address"
      visible={isModalVisible}
      width={700}
      onCancel={handleCancel}
      footer={[
        <Button
          loading={loading}
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                submitBusiness({
                  ...values,
                  business_logo: values?.business_logo?.originFileObj,
                });
              })
              .catch((info) => {});
          }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        name="business_form"
        className="mt-5"
        form={form}
        layout="horizontal"
        labelCol={{ span: 6 }}
        initialValues={initialValues}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="business_logo"
          label="Business Logo"
          valuePropName="file"
          getValueFromEvent={(e) => {
            if (Array.isArray(e)) {
              return e;
            }
            return e && e.file;
          }}
        >
          <Upload
            defaultFileList={
              business_logo !== "undefined"
                ? [
                    {
                      uid: "-1",
                      name: "test.png",
                      status: "done",
                      url: ImageEnd + business_logo,
                    },
                  ]
                : []
            }
            maxCount={1}
            name="logo"
            listType="picture-card"
          >
            <Button icon={<UploadOutlined />}></Button>
          </Upload>
        </Form.Item>
        <Form.Item
          name="business_name"
          label="Business Name"
          rules={[
            {
              required: true,
              message: "Please input the business name!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="business_address"
          label="Business Address"
          rules={[
            {
              required: true,
              message: "Please input the business address!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default BusinessInfo;

export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_CATEGORY_GOALS_LIST_SUCCESS":
    case "GET_CATEGORY_GOALS_LIST_ERROR":
      return {
        ...state,
        GetCategoryGoalsListResponse: action.updatePayload,
      };
    case "UPDATE_CATEGORY_GOALS_SUCCESS":
    case "UPDATE_CATEGORY_GOALS_ERROR":
      return {
        ...state,
        UpdateCategoryGoalsResponse: action.updatePayload,
      };
    case "FAKE_ACTION_GOALS":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};

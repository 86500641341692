export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "SYSTEM_EVENT_LOGS_LIST_SUCCESS":
      case "SYSTEM_EVENT_LOGS_LIST_ERROR":
        return {
          ...state,
          SystemEventLogsListResponse: action.updatePayload,
        };
      case "FAKE_ACTION_SYSTEM_EVENT":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  
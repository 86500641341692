import styled from "styled-components";

export const Wrapper = styled.div`
  .card-dashboard {
    background: #fff;
    padding: 11px 20px;
    border-radius: 12px;
    box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07);
    &:hover {
      transition: all 0.25s ease;
      transform: translateY(-4px) scale(1.02);
      box-shadow: 0 14px 24px rgba(62, 57, 107, 0.2);
    }

    .card-details {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icons1,
      .icons2,
      .icons3,
      .icons4 {
        border-radius: 10px;
        height: 60px;
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #081437;
        svg {
          transform: scale(1.4);
          path,
          rect {
            fill: #fff !important;
          }
        }
      }
    }
  }
  .icons1 {
    background: #081437 !important;
  }
  .icons2 {
    background: #f06432 !important;
  }
  .icons3 {
    background: #51cd89 !important;
  }
  .icons4 {
    background: #f1416c !important;
  }

  .bdr-box {
    box-shadow: none;
    background: var(--bs-app-header-base-bg-color);
    height: 100%;
    border: 1px dashed #c9c9c9;
  }
  .ribbon .ribbon-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0px;
    position: absolute;
    z-index: 1;
    color: #fb7438;
    top: 50%;
    right: 0;
    box-shadow: none;
    background-color: transparent;
    transform: translateX(-3px) translateY(-80%);
    font-size: 13px;
    font-weight: 700;
    width: 10px;
  }
  .ribbon.ribbon-triangle.ribbon-top-start {
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border-bottom: solid 1.5rem transparent !important;
    border-left: solid 1.5rem transparent;
    border-right: solid 1.5rem transparent !important;
    border-top: solid 1.5rem transparent;
  }
  .bdr-box .border-info {
    --bs-border-opacity: 1;
    border-color: #ffebe3 !important;
  }
  .overlay .overlay-layer {
    background-color: var(--bs-overlay-bg);
    backdrop-filter: blur(6px);
    display: block;
  }
`;

import styled from "styled-components";

const Wrapper = styled.div`
  margin-top: 10px;
  .admin_user_name_title {
    color: ${(props) => props.theme.colors.admin.user.name} !important;
  }
  .admin_user_mail_title {
    color: ${(props) => props.theme.colors.admin.user.mail} !important;
  }
  .switch_user {
    color: ${(props) => props.theme.colors.admin.user.switch_user} !important;
  }
  .card-header {
    border-bottom: none !important;
  }
  // .row {
  //   margin-left: 0px !important;
  //   margin-right: 0px !important;
  // }
`;

export default Wrapper;

import React from "react";
import { BacktoAdminWrapper } from "../style";

const BacktoAdmin = ({ onClick }) => {
  return (
    <BacktoAdminWrapper onClick={onClick}>Back to Admin</BacktoAdminWrapper>
  );
};

export default BacktoAdmin;

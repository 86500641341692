export default (state, action) => {
    if (typeof state === "undefined") {
      return [];
    }
    switch (action.type) {
      case "BY_SKU_LIST_SUCCESS":
      case "BY_SKU_LIST_ERROR":
        return {
          ...state,
          BySKUResponse: action.updatePayload,
        };
      case "SORT_COLUMNS_SUCCESS":
      case "SORT_COLUMNS_ERROR":
        return {
          ...state,
          SortColumnsResponse: action.updatePayload,
        };
      case "SEARCH_KEYWORD_SUCCESS":
      case "SEARCH_KEYWORD_ERROR":
        return {
          ...state,
          SearchKeywordResponse: action.updatePayload,
        };
      case "WEEK_COMPARISION_LIST_SUCCESS":
      case "WEEK_COMPARISION_LIST_ERROR":
        return {
          ...state,
          WeekComparisionResponse: action.updatePayload,
        };
      case "PIE_CHART_DATA_SUCCESS":
      case "PIE_CHART_DATA_ERROR":
        return {
          ...state,
          PieChartDataResponse: action.updatePayload,
        };
      case "BY_GRAPH_SUCCESS":
      case "BY_GRAPH_ERROR":
        return {
          ...state,
          ByGraphDataResponse: action.updatePayload,
        };
      case "QUARTER_COMPARISON_LIST_SUCCESS":
      case "QUARTER_COMPARISON_LIST_ERROR":
        return {
          ...state,
          QuarterComparisonResponse: action.updatePayload,
        };
      case "FAKE_ACTION_BY_SKU":
        return { ...state, [action?.state]: {} };
      default:
        return state;
    }
  };
  
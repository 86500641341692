import styled from "styled-components";
import { themeColor } from "../../../config";

const Wrapper = styled.div`
  // border-right: 1px solid ${(props) =>
    props.theme.colors.sidebar.selectedMenu} !important;
  background-color: f8cab5 !important;

  border-right: none;

  .profileStatus {
    background: #2fac4a;
    width: 15px;
    height: 15px;
    border-radius: 40px;
    border: 3px solid white;
    position: absolute;
    bottom: 3px;
    right: 3px;
  }
  .ant-tabs-nav-list {
    margin: auto;
  }
  .ant-tabs-top {
    width: 100%;
  }
  .titleProfile {
    font-weight: 900 !important;
    letter-spacing: 1px;
    font-size: 18px !important;
    color: rgb(0 0 0 / 88%) !important;
  }
  .profileMail {
    color: #00000091 ${(props) => props.theme.colors.sidebar.userMail} !important;
    font-size: 14px !important;
  }

  .user_name_title {
    color: ${(props) => props.theme.colors.sidebar.userName} !important;
  }
  .borderBottom {
    background: ${(props) =>
      props.theme.colors.sidebar.borderBottom} !important;
  }
  .ant-menu-sub.ant-menu-inline {
    background: #ffffff00 !important;
  }
  .ant-menu-item a {
    color: #090909;
  }
  .ant-menu-item svg [fill]:not(.permanent):not(g) {
    fill: #090909 !important;
  }
  .ant-menu-item,
  .ant-menu-title-content {
    border: 1px solid transparent !important;
    color: ${(props) => props.theme.colors.sidebar.userName};
    font-weight: unset;
  }
  .ant-menu-inline,
  .ant-menu-inline-collapsed {
    border-inline-end: none !important;
    background-color: #ffffff00 !important;
  }
  .backAdmin {
    color: #0091e2;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    &:hover {
      color: #026095;
    }
    svg {
      margin-right: 5px;
      path {
        fill: #0091e2;
      }
    }
  }
  #kt_aside_menu_wrapper {
    padding: 0px 10px !important;
  }

  .ant-menu-vertical {
    border-inline-end: none !important;
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item-icon {
      vertical-align: -0.325em !important;
    }
    .ant-menu-item {
      padding-inline: calc(50% - 15px) !important;
    }
  }
  .ant-menu-item,
  .ant-menu-item-selected {
    margin-inline: 8px;

    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: ${(props) => props.theme.colors.sidebar.sidebarIcon} !important;
    }
    &:hover {
      background: #00224400 !important;

      color: ${(props) =>
        props.theme.colors.sidebar.selectedMenuText} !important;
      border: 1px solid #61bbed !important;
      svg [fill]:not(.permanent):not(g) {
        transition: fill 0.3s ease;
        fill: ${(props) =>
          props.theme.colors.sidebar.selectedMenuText} !important;
      }
      .bullet {
        background-color: ${(props) =>
          props.theme.colors.sidebar.selectedMenuText} !important;
      }
      a {
        font-weight: 500 !important;
        color: ${(props) =>
          props.theme.colors.sidebar.selectedMenuText} !important;
      }
    }
  }
  .ant-menu-item-selected {
    box-shadow: #61bbed57 0px 10px 20px 0px;
    background-color: rgb(230 84 42) !important;
    border: 1px solid transparent !important;

    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: #fff !important;
    }
    .bullet {
      background-color: #fff;
    }
    a {
      font-weight: 500 !important;
      color: #fff !important;
    }
  }
  #btnLogout {
    color: #fff !important;
    background-color: rgb(230 84 42) !important;
    border: 1px dashed ${(props) => props.theme.colors.sidebar.logoutButton};
    transition: 0.6s !important;
    transition-duration: 200ms !important;
    font-weight: bold;
    .logoutId {
      color: #fff !important;
    }
  }
  #btnLogout:hover {
    background-color: #e38063 !important;
    border: 1px dashed ${() => themeColor()};
    color: #fff !important;
    .logoutId {
      color: #fff !important;
    }
  }
  .ant-menu-submenu {
    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: ${(props) => props.theme.colors.sidebar.sidebarIcon} !important;
    }
    .ant-menu-item {
      padding-left: 38px !important;
    }
    .ant-menu-submenu-title {
      &:hover {
        background: #00224400 !important;

        color: ${(props) =>
          props.theme.colors.sidebar.selectedMenuText} !important;

        svg [fill]:not(.permanent):not(g) {
          transition: fill 0.3s ease;
          fill: ${(props) =>
            props.theme.colors.sidebar.selectedMenuText} !important;
        }
        .bullet {
          background-color: ${(props) =>
            props.theme.colors.sidebar.selectedMenuText} !important;
        }
        a,
        .ant-menu-title-content {
          font-weight: 500 !important;
          color: ${(props) =>
            props.theme.colors.sidebar.selectedMenuText} !important;
        }
      }
    }
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: ${(props) =>
        props.theme.colors.sidebar.selectedMenuText} !important;

      background: ${(props) =>
        props.theme.colors.sidebar.selectedSubMenu} !important;
      font-weight: 500;
      .ant-menu-title-content {
        color: ${(props) =>
          props.theme.colors.sidebar.selectedMenuText} !important;
      }
    }
    svg [fill]:not(.permanent):not(g) {
      transition: fill 0.3s ease;
      fill: ${(props) =>
        props.theme.colors.sidebar.selectedMenuText} !important;
    }
  }
  .mr-4 {
    margin-right: 1rem;
  }
  .symbol > img {
    border-radius: 1.65rem !important;
  }

  .profileText {
    box-shadow: ${(props) => props.theme.colors.sidebar.profileBackShadow};
    background: linear-gradient(45deg, rgb(230 84 42), rgb(230 84 42 / 39%));
    width: 60px;
    height: 60px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    text-shadow: rgb(230 84 42) 2px 2px 9px;
    font-weight: 700;
    color: #fff;
  }
  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #e7562b !important;
    }
  }
  .ant-tabs-ink-bar {
    background: #61bbed !important;
  }
`;
export const WrapperNew = styled.div`
  @keyframes takeoff {
    0% {
      transform: translateX(-40%);
      opacity: 0.4;
    }

    100% {
      transform: translateX(0%);
      opacity: 1;
    }
  }

  .ant-menu-item {
    &:hover {
      .ant-menu-item-icon {
        opacity: 0.3;
        transform: translateX(-40%);
        animation: takeoff 0.8s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
      }
    }
    height: 48px !important;
    .ant-menu-title-content {
      a {
        color: #697a8d;
        transition: opacity 0.3s ease-in-out;
        font-size: 15px;
      }
    }
  }
  .ant-menu {
    svg {
      path,
      rect {
        fill: #697a8d;
      }
    }
    padding: 0px 15px;
  }
  .ant-menu-submenu {
    .ant-menu-title-content {
      color: #697a8d;
      transition: opacity 0.3s ease-in-out;
      font-size: 15px;
    }
  }
  .ant-menu-item-selected {
    a {
      color: rgb(230 84 42) !important;
      font-weight: 500;
    }
    .ant-menu-item-icon {
      svg {
        path,
        rect {
          fill: rgb(230 84 42) !important;
        }
      }
    }
  }
  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      height: 48px !important;
      background: #e6542a0f !important;
      .ant-menu-title-content {
        color: rgb(230 84 42) !important;
        font-weight: 500;
      }
      .ant-menu-item-icon {
        svg {
          path,
          rect {
            fill: rgb(230 84 42);
          }
        }
      }
      .ant-menu-item-icon {
        svg {
          position: relative;
          top: 2px;
        }
      }
    }
  }
  .ant-menu-item-selected::after {
    content: "";
    position: absolute;
    right: 0;
    width: 0.25rem;
    height: 48px;
    border-radius: 0.375rem 0px 0px 0.375rem;
    background: rgb(230, 84, 42);
  }
  .ant-menu-submenu-title {
    height: 43px !important;
  }
  .ant-menu-inline-collapsed {
    .ant-menu-item-selected::after {
      content: "";
      position: absolute;
      right: 0;
      width: 0 !important;
    }
    .ant-menu-vertical {
      border-inline-end: none !important;
    }
    .ant-menu-item-icon {
      position: relative !important;
      left: -3px !important;
      vertical-align: -0.7em !important;
    }
  }
`;
export default Wrapper;

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import AdSummary from "../../modules/pages/ad-summary";
import {
  dashboardAction,
  productImageAction,
  TilesDataAction,
  salesChannelListAction,
  AdvertisingSummeryAction,
  fakeActionDashboard,
} from "../../redux/modules/dashboard/index.action";
import {
  amazonCredentialsListAction,
  fakeActionAmazonCredentials,
} from "../../redux/modules/amazon-credentials/index.action";
import {
  ByGraphAction,
  fakeActionBySku,
} from "../../redux/modules/sales-analytics/by-sku/index.action";

const mapStateToProps = (state) => ({
  DashboardListResponse: state.Dashboard.DashboardListResponse,
  ProductImageListResponse: state.Dashboard.ProductImageListResponse,
  TilesDataResponse: state.Dashboard.TilesDataResponse,
  SalesChannelListResponse: state.Dashboard.SalesChannelListResponse,
  AdvertisingSummeryDataResponse:
    state.Dashboard.AdvertisingSummeryDataResponse,

  ByGraphDataResponse: state.BySKU.ByGraphDataResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      dashboardAction,
      productImageAction,
      AdvertisingSummeryAction,
      fakeActionDashboard,
      TilesDataAction,
      amazonCredentialsListAction,
      fakeActionAmazonCredentials,
      salesChannelListAction,

      ByGraphAction,
      fakeActionBySku,
    },
    dispatch
  );

const AdSummary_ = connect(mapStateToProps, mapDispatchToProps)(AdSummary);

export default AdSummary_;
